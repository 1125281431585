import { createAction, props, Action } from '@ngrx/store';
import {
  SetArrayForSelectInEffect,
  SetDataForSelectInEffect,
  SetDataInterface,
  StateStructure
} from '../models/forecaster.model';
import { ChangeScenarioData, FinalizeEntityData } from '../scenarios-module/scenarios.model';

export const InitState = createAction('INIT_STATE', props<{payload: boolean}>());
export const ChangeScenarioAfterActualization = createAction('CHANGE_SCENARIO_AFTER_ACTUALIZATION', props<{ payload: string }>());
export const SetGeneralData = createAction('SET_GENERAL_DATA', props<Object>());
export const SetState = createAction('SET_STATE', props<{ payload: StateStructure | any }>());

export const ChangePage = createAction('CHANGE_PAGE', props<{ page: string }>());
export const ChangePageForecast = createAction('CHANGE_PAGE_FORECAST', props<{ page: string }>());
export const ChangeMainSelector = createAction('Change main selector', props<{ payload: SetArrayForSelectInEffect }>());
export const ChangeArraySelector = createAction('CHANGE_ARRAY_SELECTOR', props<{ payload: SetArrayForSelectInEffect }>());
export const ChangeArraySelectors = createAction('CHANGE_ARRAY_SELECTORS', props<{ payload: SetDataForSelectInEffect[] }>());
export const ChangeMainSelectorFilters = createAction('Change main selector filters', props<{ payload: SetArrayForSelectInEffect }>());
export const SetArraySelectors = createAction('Set_array_selectors', props<{ payload: SetArrayForSelectInEffect[] }>());
export const ChangeNoReqSelector =
  createAction('CHANGE_NO_REQ_SELECTOR', props<{ payload: SetDataForSelectInEffect | SetDataForSelectInEffect[] }>());
export const ChangeAllReqSelector =
  createAction('CHANGE_ALL_REQ_SELECTOR', props<{ payload: SetDataForSelectInEffect }>());
export const ChangeNoEffectSelector =
  createAction('CHANGE_NO_EFF_SELECTOR', props<{ payload: SetDataForSelectInEffect }>());
export const ShowSpinner = createAction('[Spinner] Show');
export const HideSpinner = createAction('[Spinner] Hide');
export const ClearState = createAction('Clear state before login');
export const ChangeAllReqArraySelector = createAction('CHANGE_ALL_REQ_ARRAY_SELECTOR', props<{ payload: SetArrayForSelectInEffect }>());

//  SIMULATOR

export const ChangeTimeScale =
  createAction('[Simulator] Time Scale changed', props<{ payload: SetDataForSelectInEffect }>());
export const ChangeTimePoints =
  createAction('[Simulator] Time Points changed', props<{ payload: SetDataForSelectInEffect[] }>());
export const SetDataPoint = createAction('SET_DATA_POINT', props<{ payload: SetDataInterface }>());
export const UpdateAllData = createAction('UPDATE_ALL_DATA');
export const ResetAllData = createAction('RESET_ALL_DATA');
export const RefreshTable = createAction('REFRESH_TABLE');
export const UpdateForecastPage = createAction('UPDATE_FORECAST_PAGE');
export const UpdateTotalsForecastPage = createAction('UPDATE_TOTALS_FORECAST_PAGE');
export const UploadZipActualization = createAction('UPLOAD_ZIP_ACTUALIZATION', props<any>());

//  SCENARIOS

export const ChangeScenario = createAction('CHANGE_SCENARIO', props<{ payload: ChangeScenarioData }>());
export const FinalizeEntity  = createAction('FINALIZE_ENTITY ', props<{ payload: FinalizeEntityData }>());
export const SetNewScenarioSelector = createAction('[Simulator] Saved As scenario is set selected');

//  USERS
// TODO remove this
export const ChangeChbMyScopeFilter = createAction('[Users] Apply my scope filter', props<{ payload: SetDataForSelectInEffect }>());


//  APP LEVEL ACTIONS
export class ActionTypes {
  static LOGOUT = "[App] logout";
}

export class Logout implements Action {
  readonly type = ActionTypes.LOGOUT;
}


// export const UPDATE_MULTI_SELECTOR = 'UPDATE_MULTI_SELECTOR';
// export const UpdateMultiSelector = createAction(UPDATE_MULTI_SELECTOR, props<{ payload: MultiSelectorUpdateObject }>());

// export const CHANGE_MULTI_SELECTOR = 'CHANGE_MULTI_SELECTOR';
// export const ChangeMultiSelector = createAction(CHANGE_MULTI_SELECTOR, props<{ payload: MultiSelectorSetter, is_apply: boolean }>());

// export const DELETE_SCENARIO = 'DELETE_SCENARIO';
// export const DeleteScenario = createAction(DELETE_SCENARIO, props<{ id: string }>());

// export const SET_NEW_SCENARIO_SELECTOR_OLD = 'SET_NEW_SCENARIO_SELECTOR_OLD';
// export const SetNewScenarioSelectorOld = createAction(SET_NEW_SCENARIO_SELECTOR_OLD);

// export const SAVE_AS_SCENARIO = 'SAVE_AS_SCENARIO';
// export const SaveAsScenario = createAction(SAVE_AS_SCENARIO, props<{ payload: Object }>());

// export const SAVE_SCENARIO = 'SAVE_SCENARIO';
// export const SaveScenario = createAction(SAVE_SCENARIO, props<{ payload: Object }>());

// export const UPDATE_SCENARIO_LIST = 'UPDATE_SCENARIO_LIST';
// export const UpdateScenarioList = createAction(UPDATE_SCENARIO_LIST);

// export const UPDATE_SCENARIO = 'UPDATE_SCENARIO';
// export const UpdateScenario = createAction(UPDATE_SCENARIO, props<Object>());

// export const GET_ASSORTMENT = 'GET_ASSORTMENT';
// export const GetAssortment = createAction(GET_ASSORTMENT); //  NO REDUCER???


// export const APPLY_MANAGE_LIKE_ITEMS = 'APPLY_MANAGE_LIKE_ITEMS';
// export const ApplyManageLikeItems = createAction(APPLY_MANAGE_LIKE_ITEMS);


// export const GET_DATA_FOR_FAILED_SET = 'GET_DATA_FOR_FAILED_SET';
// export const GetDataForFailedSet = createAction(GET_DATA_FOR_FAILED_SET);