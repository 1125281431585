import { Injectable } from '@angular/core';
import { LocalStorageService } from 'angular-web-storage';
import { BehaviorSubject } from 'rxjs';

const KEY_SIMULATOR = 'selected_simulator_tab_index';
const KEY_SCENARIOS = 'selected_scenarios_tab_index';


@Injectable({
    providedIn: 'root'
  })
export class SimulatorService {
  discardData = new BehaviorSubject<any>(false);
  discardDataForTable = new BehaviorSubject<any>(false);

  constructor(
    private localStorage: LocalStorageService,
  ) {
    this.discardData.subscribe((res) => {
      if (res) {
        this.discardDataForTable.next(res);
      }
    })
  }

  private simulatorTabIndex: number = 0;
  private scenariosTabIndex: number = 0;

  getSimulatorTabIndex() {
      // Read the value from the storage
      this.simulatorTabIndex = this.localStorage.get(KEY_SIMULATOR);

      return this.simulatorTabIndex;
  } 

  getScenariosTabIndex() {
    // Read the value from the storage
    this.scenariosTabIndex = this.localStorage.get(KEY_SCENARIOS);

    return this.scenariosTabIndex;
} 

  setSimulatorTabIndex(value: number) {
      this.simulatorTabIndex = value;
  
      // Save selected index
      this.localStorage.set(KEY_SIMULATOR, value);
  }

  setScenariosTabIndex(value: number) {
    this.scenariosTabIndex = value;

    // Save selected index
    this.localStorage.set(KEY_SCENARIOS, value);
}
}