import { createAction, props } from '@ngrx/store';
import { AllUsersModel, UserInfo, UserParametersObj, UserScopeObj } from './users.model';


export const getUsers = createAction('[ChangePage] Get users', props<{ all_users: AllUsersModel }>());

export const createUser = createAction('Create User', props<{ user: UserParametersObj }>());
export const deleteUser = createAction('Delete User', props<{ user_id: string }>());
export const updateUserParams = createAction('Update User Parameters', props<{ user: UserParametersObj }>());

export const changeUsersScope = createAction('Update Users Scope', props<{ data: UserScopeObj[] }>());
 