import { NgModule } from '@angular/core';
import { PortalModule } from '@angular/cdk/portal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginInterceptor } from './pages/login/login.interceptor';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NoScopeComponent } from './pages/no-scope/no-scope.component';
import { AppMaterialModule } from './app-material.module';
import { PowerbiFrameComponent } from './pages/powerbi-frame/powerbi-frame.component';
import { clearState } from './pages/forecaster/state/foracaster.reducer';

import { OktaAuthModule, OKTA_CONFIG } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { AuthInterceptor } from './auth.interceptor';

import { LocationStrategy, Location, PathLocationStrategy } from '@angular/common';

import { ForecasterEffect } from './pages/forecaster/state/foracaster.effect';


declare const process: any; // Add this line to declare process


const oktaDomain = process.env.OKTA_DOMAIN;
const oktaClientId = process.env.OKTA_CLIENT_ID;

const oktaAuth = new OktaAuth({
  issuer: 'https://' + oktaDomain + '/oauth2/default',
  clientId: oktaClientId,
  redirectUri: window.location.origin + '/callback',
});


@NgModule({
  declarations: [
    AppComponent,
    NoScopeComponent,
    PowerbiFrameComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    PortalModule,
    AppRoutingModule,
    HttpClientModule,
    OktaAuthModule,
    StoreModule.forRoot({}, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
      metaReducers: [clearState],
    }),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({maxAge: 25, logOnly: environment.production}),
    EffectsModule.forFeature([ForecasterEffect]),
  ],
  providers: [
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    //{ provide: HTTP_INTERCEPTORS, useClass: LoginInterceptor, multi: true},
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: OKTA_CONFIG, useValue: { oktaAuth } },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}

