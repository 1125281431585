<div mat-dialog-title class="flex-container">
    <h1 mat-dialog-title class="title">{{title}}</h1>
    <button mat-button class="close-icon" (click)="onDialogButtonCloseClick()">
        <mat-icon>close</mat-icon>
    </button>
</div>

<div mat-dialog-content>
    <form class="large-scale-form" [formGroup]="largeScaleSimulationForm">
        <table>
            <td>
                <section class="section">
                    <div *ngFor="let dim of dims; let i = index">
                        <tr>
                            <mat-form-field>
                                <mat-label class="my-label">{{dimsComboBoxInfo[i].label}}</mat-label>
                          
                                <mat-select 
                                    [disableOptionCentering]="true"
                                    panelClass="my-panel"
                                    disableRipple
                                    multiple

                                    (click)="onDimSelectorClick($event, dim)"
                                    [disabled]='dimsComboBoxInfo[i].is_disabled'
                                    formControlName="{{dim}}">

                                    <mat-select-trigger>
                                        <span *ngIf="largeScaleSimulationForm.controls[dim].value.indexOf('__all__') > -1; else defaultLabel">
                                            (All)
                                        </span>
                                        <ng-template #defaultLabel>
                                            {{ getLabel(dim) }}
                                        </ng-template>
                                    </mat-select-trigger>

                                    <mat-option (click)="onToggleAllSelection(dim)" [value]="'__all__'">(Select All)</mat-option>

                                    <mat-option *ngFor="let item of dimsOptions[dim], let i = index"
                                                [disabled]="item.is_disabled"
                                                [value]="item.key"
                                                (click)="onTosslePerOne(dim)"
                                                class="not-empty-select" >
                                        {{item.label}}
                                    </mat-option>
                          
                                </mat-select>
                            </mat-form-field>
                        </tr>
                    </div>
                </section>
            </td>
            <td>
                <section class="section">  
                    <tr>
                        <div class="inline-section">
                            <div style="flex: 1 0 50%;">               
                                <mat-label class="section-label">Fact Group</mat-label>
                                <mat-radio-group class="section-radio-group" [(ngModel)]="factGroup" [ngModelOptions]="{standalone: true}">
                                    <mat-radio-button value="driver" (change)="onFactGroupChanged($event)">Inputs</mat-radio-button>
                                    <mat-radio-button value="macro_driver" (change)="onFactGroupChanged($event)">Macro</mat-radio-button>
                                    <mat-radio-button value="coefficient" (change)="onFactGroupChanged($event)">Coefficients</mat-radio-button>
                                    <mat-radio-button value="macro_coefficient" (change)="onFactGroupChanged($event)">Macro Coefficients</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <app-mat-selector 
                                class="inline-selector" 
                                style="float: right; flex: 1 0 50%;" 
                                [selector_info]="factInfo" 
                                [selector_items]="factOptions" 
                                [selection_key]="fact" 
                                (keyChanged)="onFactSelectorChanged($event)" 
                                formControlName="fact"
                                name="fact" ngDefaultControl>
                            </app-mat-selector>
                        </div>
                    </tr>
                    <tr>
                        <div class="inline-section">
                            <app-mat-selector class="inline-selector" [selector_info]="startPeriodInfo" [(selector_items)]="startPeriodOptions" [selection_key]="startPeriod" (keyChanged)="onStartPeriodSelectorChanged($event)"></app-mat-selector>
                            <app-mat-selector class="inline-selector" [selector_info]="endPeriodInfo" [(selector_items)]="endPeriodOptions" [selection_key]="endPeriod" (keyChanged)="onEndPeriodSelectorChanged($event)"></app-mat-selector>
                        </div>          
                    </tr>
                    <tr>                  
                        <mat-label class="section-label">Sub Rows</mat-label>
                        <mat-radio-group class="section-radio-group" [(ngModel)]="subRowGroup" [ngModelOptions]="{standalone: true}">
                            <mat-radio-button value="cur_year_ago_percent">% vs YA</mat-radio-button>
                            <mat-radio-button value="cur_year_ago_diff">Abs vs YA</mat-radio-button>
                            <mat-radio-button value="cur_base_percent">% vs Base</mat-radio-button>
                            <mat-radio-button value="cur_base_dif">Abs vs Base</mat-radio-button>
                        </mat-radio-group>
                    </tr>
                    <tr>
                        <mat-form-field appearance="fill" class="section-input">
                            <mat-label>Assumption</mat-label>
                            <input matInput (keydown.enter)="onInputKeyPress($event)" type="number" [(ngModel)]="value" [ngModelOptions]="{standalone: true}" formControlName="value">
                        </mat-form-field>
                        <label class="assumption-label" *ngIf="subRowGroup.includes('percent')">%</label>
                    </tr>
                    <tr>
                        <div class="buttons-panel">
                            <button mat-button class="reset-button" (click)="onResetButtonClick()">RESET</button>
                            <button mat-button class="input-button" (click)="onInputButtonClick()" [disabled]="!largeScaleSimulationForm.valid">INPUT</button>
                            <button mat-button class="input-button" (click)="onInputAndSimulateButtonClick()" [disabled]="!largeScaleSimulationForm.valid">INPUT & SIMULATE</button>
                        </div>
                    </tr>
                </section>
            </td>
        </table>

    </form>

</div>