//import { SystemJsNgModuleLoader } from "@angular/core";

export const SEL_PAGE = 'sel_page';
export const FORECASTER_PAGE = 'forecaster_page';

export const SCENARIOS_KEY = 'scenarios_management';
export const USERS_KEY = 'user_manager_page';
export const SIMULATOR_PAGE_KEY = 'simulator_page';
export const MANAGE_SCOPE_KEY = 'manage_scope';

export const PAGES_WITH_GENERAL_SELECTORS: Array<string> = [SIMULATOR_PAGE_KEY]; // to be removed ? ask LENA

export const STATE_BACK = 'state_back';

export const SELECTORS = 'selectors';
export const NAME_DIMENSION_CONST = 'name_dimension';
export const NAME_LABELS = 'name_labels';
export const NAME_TABLES = 'name_tables';
export const DATE_TABLES = 'date_tables';
export const DATE_LABELS = 'date_labels';
export const ALL_TABLE = 'all_table';
export const START_SIMULATION = 'start_simulation';
export const TIME_DIMENSION = 'time_dimension';
export const ATTRIBUTES = 'attributes';
export const SCENARIOS = 'scenarios';
export const SCENARIOS_DATA = 'scenarios_data';

export const TBL_ADDSTRS = 'tbl_addstrs';
export const TBL_COEFFICIENTS = 'tbl_coefficients';
export const TBL_DECOMP = 'tbl_decomp';
export const TBL_WATERFALL = 'tbl_waterfall';
export const TBL_FORECAST = 'tbl_forecast';
export const TBL_SCENARIOS = 'tbl_scenarios';
export const TBL_USERS = 'tbl_users';
export const TBL_CONSOLIDATED = 'tbl_consolidated';

export const SEL_MULTISELECT = 'sel_multiselect';
export const SEL_MULTISELECT_UP_LEVEL = 'sel_multiselect_up_level';
export const MULTISELECTOR_HIDDEN_COLUMN_COUNT = 0; // to be removed
export const SEL_NAME_LABEL = 'sel_namelabel';
export const SEL_FILTERS = 'sel_filters';
export const SEL_TABLE = 'sel_table';
export const FACTS = 'facts';
export const SUB_ROWS = 'sub_rows';
export const SEL_SORTS = 'sel_sorts';
export const SEL_COLLAPSES = 'sel_collapses';
export const SCROLL_TOP = 'scroll_top';


export const SUBTOTAL_COLLAPSES_MAIN = 'subtotal_collapses_main';
export const SUBTOTAL_COLLAPSES_FACTS = 'subtotal_collapses_facts';

export const FILTER_BASE_KEYS = 'filter_base_keys';
export const USERS = 'users';
export const EMPTY_USER = 'empty_user';

export const SELECTORS_ORDER = 'order';
export const SEL_GENERAL = 'sel_general';
export const SEL_REPORT_TYPE = 'sel_report_type';
export const SEL_TIMEPERIODM = 'sel_timeperiod'; // MARS
export const SEL_TIMEPERIOD_START = 'sel_timeper_start';
export const SEL_TIMEPERIOD_END = 'sel_timeper_end';
export const TIME_HIERARCHY: string[] = ['annual', 'quarterly', 'monthly', 'biweekly', 'weekly', 'daily'];
// export const YEAR_TIME_POINTS: number[] = [1, 4, 2, 26, 52, 366]; - not used 2020-10-27
export const ANNUAL = 'annual';
export const SEL_CURRENCY = 'sel_currency'; // MARS
export const LOCAL_CURRENCY = 'local';
export const SEL_SET_DATA_OPTIONS = 'sel_setdataoptions';

export const SEL_SCENARIO_BASE = 'sel_scenario_bas';
export const SEL_SCENARIO_CUR = 'sel_scenario_cur';
export const CHB_MY_SCOPE_FILTER = 'chb_myscopefilter';
export const IS_CHB_CHECKED = 'checked';
export const IS_CHB_UNCHECKED = 'unchecked';
export const CHB_YEAR_BY_YEAR = 'chb_yearbyyear';
export const CHB_SHOW_TOTALS = 'chb_showtotals';
export const CHB_SHOW_SUBTOTALS = 'chb_showsubtotals';
export const SEL_NAMEDIMMASK = 'sel_namedimmask';
export const HIERARCHY_MASK = 'hierarchy_mask';
export const COMBINATION_LEVEL = 'combination_level';
export const CHB_ONLYEDITEDENTITIES = 'chb_onlyeditedentities';
export const CHB_ONLYMYSCENARIOS = 'chb_onlymyscenarios';
export const SEL_SCN_DEFAULT_NAME = 'sel_scn_default_name';
export const SEL_TAG = 'sel_tag';

export const CHB_SHOW_EXCLUSIONS = 'chb_showexclusions';
export const CHB_SHOW_LOCKED = 'chb_showlocked';
export const CHB_SHOW_LOCKED_INIT = 'chb_showlockedinit';
export const CHB_SHOW_LOCKED_CONSOL = 'chb_showlockedconsol';
export const CHB_SHOW_LOCKED_ACTIVE = 'chb_showlockedactive';
export const LABEL_SHOW_LOCKED_INIT = 'Show Locked Initial';
export const LABEL_SHOW_LOCKED_CONSOL = 'Show Locked Consolidated';
export const LABEL_SHOW_LOCKED_ACTIVE = 'Show Locked Active';

export const BLK_CHARTS = 'blk_charts';
export const RBT_COMPARE_TYPE_CHARTS = 'rbt_compare_type_charts';
export const RBT_VALUE_TYPE_CHARTS = 'rbt_value_type_charts';  // for charts : Value/Growth Rate
export const SEL_COMPARE_0 = 'sel_compare_0';
export const SEL_COMPARE_1 = 'sel_compare_1';
export const RBT_CHART_TYPE = 'rbt_chart_type';
export const LINE = 'line';
export const CHB_CHART_SHOW_LEGEND = 'chb_showlegend';
export const RBT_CHART_LEGEND_POSITION = 'rbt_legend_position';


export const BLK_DECOMPOSITION = 'blk_decomposition';
// export const RBT_OUTPUT = 'rbt_output';
export const RBT_COMPARE_TYPE_DECOMP = 'rbt_compare_type_decomp';
export const RBT_VALUE_TYPE_DECOMP_ALLYEARS = 'rbt_value_type_decomp_allyears';
export const RBT_VALUE_TYPE_DECOMP = 'rbt_value_type_decomp';
export const SEL_METRIC_DECOMP_ALLYEARS = 'sel_metric_decomp_allyears'
export const SEL_METRIC_DECOMP = 'sel_metric_decomp';
export const SEL_DECOMP_START1 = 'sel_decomp_start1';
export const SEL_DECOMP_END1 = 'sel_decomp_end1';
export const SEL_DECOMP_START2 = 'sel_decomp_start2';
export const SEL_DECOMP_END2 = 'sel_decomp_end2';
export const SEL_SHORT_DECOMP = 'sel_short_decomp';
export const SEL_COUNT_DECOMP1 = 'sel_count_decomp1';
export const SEL_COUNT_DECOMP2 = 'sel_count_decomp2';
export const CUR_SCENARIO = 'Simulated scenario';
export const BASE_SCENARIO = 'Baseline scenario';
export const DIFFERENCE_SCENARIO = 'Difference';
export const SIMULATOR = 'Simulated';
export const BASELINE = 'Baseline';

export const FINAL = 'final';
export const PREFINAL = 'prefinal';
export const IS_SHARED = 'is_shared';
export const ACTION = 'action';
export const CHB_FINALBUTTON = 'chb_finalbutton';
export const CHB_PREFINALBUTTON = 'chb_prefinalbutton';
export const CHB_SHAREBUTTON = 'chb_sharebutton';
export const ACTIVE_VISIBLE = 'active_visible';
export const LOCKED_VISIBLE = 'locked_visible';
export const ROLE = 'role';
export const ACTIVE = 'active';


export const SEL_SIMULATOR_TAB = 'sel_tab_simulator';
export const SEL_SCENARIOS_TAB = 'sel_tab_scenarios';
export const TAB_SCENARIOS_ACTIVE = 'active';
export const TAB_SCENARIOS_LOCKED = 'locked';
export const TAB_SCENARIOS_CONSOLIDATED = 'consolidated';


export const SEL_USER_ROLE = 'sel_userrole';

export const ORDER_KEY = '_order';
export const ANY_KEY = 'any';

export const ALL = 'All';
export const MAIN_USER_CODE = '0';
export const ADMIN_USER_CODE = '1';
export const SUPER_USER_CODE = '2';
export const ORDINARY_USER_CODE = '3';


export const CUR_BIG_TABLE_IDS = 'cur_name_tables_ids';


export const INITIAL_SCENARIO_ID = 'initial';

export const ENTITY = 'entity';
export const ROW = 'row';
export const INSTRUCTION = 'instruction';


export const NEW_SCENARIO_NAME = 'new_scenario_name';


export const _VISIBLE = 'visible';
export const _NAME = 'name';
export const _NAME_SHORT = 'name_short';
export const _FILTERED = 'filtered';

export const ACTIONS_COL_NAME = 'Action';  // to be removed after users update 2020-10-27

export namespace Colors {
  export const RED_COLOR = 'rgb(239,62,62)';
  export const GREEN_COLOR = 'rgb(92,196,0)';
  export const BLUE_COLOR = 'rgb(0, 83, 182)';
}

export const COMPARE_PERIODS_KEY = 'periods';
export const ABSOLUTE_VALUE_TYPE = 'absolute';

export const EPSILON = 0.0001;
export const TOTAL = '__total__';
export const _ALL_ = '__all__';

export const LIMIT_CHART_LINES_COUNT = 5;
export const CHART_SUB_ROWS_COUNT = 4;

export const UNDEFINED_ID = 'undefined_id';
export const SCENARIO = 'scenario';
export const BLOCKED_SCENARIOS_NAMES: string[] = ['Initial', 'Consolidated', UNDEFINED_ID];

export const ADD_SCOPE_TITLE = 'Add Scope';
export const DEL_SCOPE_TITLE = 'Remove Scope';
export const EDIT_SCOPE_TITLE = 'User Scope';

export const INITIAL_CONSOLIDATED_ID = 'consolidated';
export const SEL_CONSOLIDATED = 'sel_consolidated';

interface Map<T> {
  [K: string]: T;
}

export const NOTIFICATION_SCENARIO_DICT: Map<string> = {
  '3': 'This entity is already finalized in consolidated scenario.\r\nMaking your version final will replace it.\r\n\r\nDo you want to finalize?',
  '4': 'This entity is already pre-finalized in consolidated scenario.\r\nMaking your version pre-final will replace it.\r\n\r\nDo you want to pre-finalize?',
  '6': 'This entity is already pre-finalized in consolidated scenario.\r\nMaking your version final will replace it.\r\n\r\nDo you want to finalize?',
  '5': 'This entity cannot be pre-finalized because it is already finalized in consolidated scenario.',
  '7': 'Your assumptions for macro drivers differ from already (pre-)finalized in consolidated scenario.\r\n\r\nDo you want to copy the drivers to your scenario and pre-finalize it?',
  '8': 'Your assumptions for macro drivers differ from already (pre-)finalized in consolidated scenario.\r\n\r\nDo you want to copy the drivers to your scenario and finalize it?',
  '9': 'This entity is already finalized in consolidated scenario.\r\nMaking your version final will replace it.\r\nYour assumptions for macro drivers differ from already (pre-)finalized in consolidated scenario.\r\n\r\nDo you want to copy the drivers to your scenario and finalize it?',
  '10': 'This entity is already pre-finalized in consolidated scenario.\r\nMaking your version final will replace it.\r\nYour assumptions for macro drivers differ from already (pre-)finalized in consolidated scenario.\r\n\r\nDo you want to copy the drivers to your scenario and finalize it?',
  '11': 'This entity is already pre-finalized in consolidated scenario.\r\nMaking your version final will replace it.\r\nYour assumptions for macro drivers differ from already (pre-)finalized in consolidated scenario.\r\n\r\nDo you want to copy the drivers to your scenario and pre-finalize it? '
};

export const USERS_TABLE_HEIGHT_REMAIDER = 120 //30+60+30
export const SCOPE_TABLE_HEIGHT_REMAIDER = 180 //30+60+60+30
export const DECOMPOSITION_CHART_HEIGHT_REMAIDER = 310 //30+60+48+60+30
export const MAIN_TABLE_HEIGHT_REMAIDER = 230 //30+60+48+60+30
export const MAIN_TABLE_FACT_COLUMN_WIDTH = 261 // Fact Column
export const MAIN_TABLE_OTHER_COLUMN_WIDTH = 132 // Scope's Column Width
export const SCENARIO_TABLE_HEIGHT_REMAIDER = 170 //30+48+60+30


export const TIME_CHUNKS = {
  'annual': 1,
  'quarterly': 4,
  'monthly': 12,
  'biweekly': 26,
  'weekly': 52
}



// --- commented on 2020-10-27 ---
// export namespace INSTRUCTION_TYPE {
//   export const _DELETE = 'delete';
//   export const _SAVE = 'save';
//   export const _UPDATE = 'update';
// }
// export const EMPTY_SCENARIO_REQUEST_OBJ: ScenarioOperationInsideObject = {
//   entity: [],
//   row: [],
//   instruction: '',
// };
// export const SEL_SETYEARS = 'sel_setyears'; // to be removed
// export const SCN_COLS = 'scn_cols';
// export const MASK_FORECAST_EDIT_LEVEL = 'sel_maskforecasteditlevel';
// export const MASK_COEFFICIENTS_EDIT_LEVEL = 'sel_maskcoefficientseditlevel';
// export const MultiselectorDialogConfig = {
//   data: {},
//   position: {
//     top: '10%',
//     left: '10%'
//   },
//   width: '80%',
// };
// export const CUR_SCENARIO_INDEX_IN_DATA = 0;
// export const BASE_SCENARIO_INDEX_IN_DATA = 1;
// export const LINE_CHART_COLORS = ['#0053b6', '#00b6b3'];
