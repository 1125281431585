<div class="wrapper">
  <div class="inner-block">
    <div class="image">
    </div>
    <h1>
      The page was not found 404
    </h1>
    <div>
      <button (click)="goTo()" class="btn btn-lg">GO TO HOMEPAGE</button>
    </div>
  </div>
</div>
