<div mat-dialog-title class="flex-container">
    <h1 mat-dialog-title class="title">{{title}}</h1>
    <button mat-button class="close-icon" (click)="onNoClick()">
        <mat-icon>close</mat-icon>
    </button>
</div>

<div>
    <form class="form-wrapper" [formGroup]="form">
        <div class="fields-wrapper">
            <span class="scenario-type">Scenario type</span>
            <mat-radio-group formControlName="typeScenario" class="fields-wrapper" aria-label="Select an option">
                <mat-radio-button value="actual">Actual</mat-radio-button>
                <mat-radio-button value="previous">Previous</mat-radio-button>
                <mat-radio-button value="forecast">Forecast</mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="fields-wrapper">
            <span class="scenario-type">Scenario name</span>
            <mat-form-field class="select-form" appearance="fill" [disabled]="form.controls.typeScenario.value === ''">
                <mat-label>Select</mat-label>
                <mat-select
                    formControlName="scenario"
                    [ngClass]="{'select-scenario': form.controls.typeScenario.value !== '' }"
                    [disabled]="form.controls.typeScenario.value === ''">
                    <ng-container *ngIf="getScenarioList(form.controls.typeScenario.value).length">
                        <mat-option
                            *ngFor="let scenario of getScenarioList(form.controls.typeScenario.value)"
                            [value]="setScenarioValue(scenario, form.controls.typeScenario.value)">
                            {{scenario.name}}
                        </mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
        </div>
    </form>

</div>
<div class="bottom_btns_block">
    <div mat-dialog-actions>
      <button mat-raised-button color="basic" (click)="onNoClick()" class="my_bottom-button">Cancel</button>
      <button mat-raised-button
        color="primary"
        [disabled]="!form.valid"
        (click)="setScenario()"
        class="my_bottom-button" cdkFocusInitial>
        OK
      </button>
    </div>
</div>