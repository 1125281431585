<div class="full-height-wrapper">
  <mat-card class="no-scope-card">
    <img mat-card-image class="" src="/assets/images/login_logo.png" alt="Fractal and IAP logo">
    <mat-card-title class="">
      Wellcome to IAP
    </mat-card-title>
    <mat-card-content>
      <p>
        Sorry, you cannot log in to the tool since you are not assigned to any scope. Please contact the Admin
      </p>
    </mat-card-content>
    <mat-card-actions>
      <button (click)="goToLogin()" mat-raised-button>To Login</button>
    </mat-card-actions>
  </mat-card>
</div>