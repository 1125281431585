<div class="my_dialog">
  <h1 mat-dialog-title class="my_title">{{title}}</h1>
  <div class="my-mat-dialog-content">{{data.message}}</div>
  <div class="bottom_btns_block">
    <div mat-dialog-actions>
      <ng-container *ngIf="data.type==='confirmation'; else warning" >
        <button mat-button (click)="onNoClick()">No</button>
        <!-- <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Yes</button> -->
        <button mat-button (click)="onYesClick()" cdkFocusInitial>Yes</button>
      </ng-container>
      <ng-template #warning>
        <button mat-button (click)="onNoClick()" cdkFocusInitial>Ok</button>
      </ng-template>
    </div>
  </div>
</div>
