import {Component, ViewEncapsulation, OnInit, OnDestroy} from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None
})


export class AppComponent implements OnInit {
  title = 'IAP';  

  constructor() {
  }

  ngOnInit() {
  }
}
