import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map, tap} from 'rxjs/operators';
import jwt_decode from 'jwt-decode';
import {SessionStorageService} from 'angular-web-storage';
import {Router} from '@angular/router';
import {LoginCredentials, LoginData} from './login.model';
import {AppState} from '../forecaster/models/forecaster.model';
import {Store} from '@ngrx/store';
import { throwError } from 'rxjs';
import * as bcrypt from 'bcryptjs';

import { Logout } from '../forecaster/state/foracaster.action';
import { environment } from '../../../environments/environment';


const TOKEN_KEY = 'token';
const AUTH_DATA = 'auth_data';
const AUTHENTICATE_URL = 'do_login';
const NEW_PASSWORD_URL = 'reset_user_password';
const PASSWORD_VALIDATION = 'password_validation';
const TOKEN_VALIDATION = 'token_validation';
const LOGOUT = 'logout';
const ON_ERROR_LOGOUT = 'on_error_logout';
const LOGIN_URL = 'login';
const SEND_OTP_TO_EMAIL = 'send_otp_to_email';
const GET_QR_CODE = 'get_qr_code';

const apiUrl = environment.apiUrl + environment.client + '/';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  constructor(private http: HttpClient,
              private sessionStorage: SessionStorageService,
              private store: Store<AppState>,
              private router: Router) {
  }

  getToken(): string {
    const authData: LoginData = JSON.parse(sessionStorage.getItem(AUTH_DATA));
    return authData ? authData.token : undefined;
  }

  getIfHasScope(): boolean {
    const authData: LoginData = JSON.parse(sessionStorage.getItem(AUTH_DATA));
    return authData ? authData.has_scope : undefined;

  }

  setAuthData(authData: LoginData): void {
    sessionStorage.setItem(AUTH_DATA, JSON.stringify(authData));
  }

  getTokenExpirationDate(token: string): Date {
    const decoded = jwt_decode(token);
    if (decoded['exp'] === undefined) {
      // if (decoded.exp === undefined) {
      return null;
    }
    const date = new Date(0);
    date.setUTCSeconds(decoded['exp']);
    // date.setUTCSeconds(decoded.exp);
    return date;
  }

  isTokenExpired(token?: string): boolean {
    if (!token) {
      token = this.getToken();
    }

    if (!token) {
      return true;
    }

    const date = this.getTokenExpirationDate(token);
    if (!date) {
      // this.removeToken();
      return false;
    }
    return !(date.valueOf() > new Date().valueOf());
  }

  login(credentials: LoginCredentials) {
    return this.http.post(`${apiUrl}` + AUTHENTICATE_URL, credentials);
  }


  logout() {
    return this.http.post(`${apiUrl}` + LOGOUT, {}).pipe(
      tap(res => {
        this.sessionStorage.clear();
        this.store.dispatch(new Logout());
      })
    );
  }

  onErrorLogout() {
    this.router.navigateByUrl(LOGIN_URL);
    return this.http.post(`${apiUrl}` + ON_ERROR_LOGOUT, {}).pipe(
      tap(res => this.sessionStorage.clear())
    );
  }

  requestNewPassword(email: string, otp) {
    email = email.toLowerCase();
    return this.http.post<any>(`${apiUrl}` + NEW_PASSWORD_URL, { email, otp })
      .pipe(map((res: any) => {
        if (res && res.data && res.error === false) {
        } else {
          throwError({error: {message: 'E-mail is incorrect'}});
        }
      }));

  }

  sendOtpCodeToEmail(email: string, password: string) {
    email = email.toLowerCase();
    const salt = bcrypt.genSaltSync(10);
    return this.http.post<any>(`${apiUrl}` + SEND_OTP_TO_EMAIL, {
      email: email,
      password: password,
    })
      .pipe(map((res: any) => {
        if (res && res.data && res.error === false) {
        } else {
          throwError({error: {message: 'E-mail or password incorrect'}});
        }
      }));
  }

  getQR(email: string, password: string) {
    email = email.toLowerCase();
    const salt = bcrypt.genSaltSync(10);
    return this.http.post<any>(`${apiUrl}` + GET_QR_CODE, {
      email: email,
      password: password
    });
  }

  passwordValidation(email: string, password: string) {
    email = email.toLowerCase();
    return this.http.post<any>(`${apiUrl}` + PASSWORD_VALIDATION, {
      email: email,
      password: password
    });
  }

  tokenValidation(email: string, password: string, token: string) {
    email = email.toLowerCase();
    return this.http.post<any>(`${apiUrl}` + TOKEN_VALIDATION, {
      email: email,
      password: password,
      token: token,
    });
  }

}
