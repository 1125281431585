import { TAB_SCENARIOS_LOCKED, TAB_SCENARIOS_CONSOLIDATED, UNDEFINED_ID, SCENARIO, _NAME, ENTITY, SCROLL_TOP, BLK_CHARTS, ORDER_KEY, SEL_SHORT_DECOMP, SEL_METRIC_DECOMP_ALLYEARS, SEL_METRIC_DECOMP, _ALL_, CHB_SHOW_LOCKED, ACTIVE, SEL_SCENARIO_BASE, IS_CHB_UNCHECKED, CHB_SHOW_LOCKED_ACTIVE, CHB_SHOW_LOCKED_CONSOL, CHB_SHOW_LOCKED_INIT, CHB_SHOW_SUBTOTALS, CHB_SHOW_TOTALS, SEL_COMPARE_0, SEL_COMPARE_1 } from '../models/forecaster.constants';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concat, forkJoin, from, of } from 'rxjs';
import { mergeMap, concatMap, toArray, switchMap } from 'rxjs/operators';
import { ForecasterService } from '../forecaster.service';
import { SessionStorageService } from 'angular-web-storage';
import { MultiselectorService } from '../../../_components/multiselector.service';
import {
  ChangeAllReqSelector, ChangeTimePoints,
  SetGeneralData,
  SetState,
  SetNewScenarioSelector,
  ClearState,
  InitState,
  UpdateForecastPage,
} from './foracaster.action';
import {
  SetArrayForSelectInEffect, SetDataForSelectInEffect,
  GetFactDataInterface,
  StateStructure, AppState
} from '../models/forecaster.model';
import {
  FORECASTER_PAGE,
  NEW_SCENARIO_NAME,
  SEL_FILTERS, SEL_GENERAL, SEL_NAME_LABEL, SEL_TABLE,
  SIMULATOR_PAGE_KEY,
  SCENARIOS,
  SCENARIOS_KEY,
  SEL_SCENARIO_CUR, SEL_TIMEPERIOD_END,
  SEL_TIMEPERIOD_START,
  SEL_TIMEPERIODM,
  SELECTORS_ORDER,
  STATE_BACK,
  USERS_KEY, FACTS, SEL_SORTS, SEL_COLLAPSES,
  MANAGE_SCOPE_KEY, USERS, FILTER_BASE_KEYS, SEL_SIMULATOR_TAB, BLK_DECOMPOSITION, SEL_DECOMP_START1, SEL_DECOMP_END1,
  SEL_DECOMP_START2, SEL_DECOMP_END2, SEL_SCENARIOS_TAB, TAB_SCENARIOS_ACTIVE
} from '../models/forecaster.constants';
import { ScenariosActions } from '../scenarios-module/action-types';
import {
  AllScenariosState
} from '../scenarios-module/scenarios.model';
import { UsersActions } from '../users-management-module/action-types';
import { ForecasterActions } from './action-types';
import { SimulatorState, SimulatorTotalsState } from '../simulator/simulator.model';
import { SimulatorActions } from '../simulator/action.types';
import { SessionStorageProviderService } from '../session-storage-provider.service';
import { ScopeState } from '../scope-module/scope.model';
import { ScopeActions } from '../scope-module/action.types';
import { MatDialog } from '@angular/material/dialog';
import { ActualizeFailDialogObject, NotificationDialogObject, NotificationType } from '../notification-module/notification.model';
import { ScenariosService } from '../scenarios.service';
import { NOTIFICATION_SCENARIO_DICT } from '../models/forecaster.constants';
import { SpinnerOverlayService } from '@app/shared/services/spinner-overlay.service';
import { SimulatorService } from '../simulator/simulator.service';
import { commonConstants } from '@app/consts/common';
import { NotificationDialogComponent } from '../notification-module/notification-dialog/notification-dialog.component';
import { Store } from '@ngrx/store';
import { ActualizeFailDialogComponent } from '../notification-module/actualize-fail-dialog/actualize-fail-dialog.component';

@Injectable()
export class ForecasterEffect {

  constructor(private actions$: Actions,
    private forecasterService: ForecasterService,
    private sessionStorage: SessionStorageService,
    private multiselectorService: MultiselectorService,
    private scenarioService: ScenariosService,
    private dialog: MatDialog,
    private store: Store<AppState>,
    private sessionStorageProvider: SessionStorageProviderService,
    private spinnerService: SpinnerOverlayService,
    private simulatorService: SimulatorService,
    private scenariosService: ScenariosService
  ) { }

  InitState$ = createEffect(() => this.actions$.pipe(
    ofType(ForecasterActions.InitState),
    concatMap((action: {payload: boolean}) => {
      this.spinnerService.show();
      this.forecasterService.action = action['payload'];
      /*return concat(
        this.forecasterService.getNameDimension(),
        this.forecasterService.getTimeDimension(),
        this.forecasterService.getSelectors(),
        this.forecasterService.getAttributes(),
        this.forecasterService.getScenarios(''),
        this.forecasterService.getState()
      );*/
      return forkJoin([this.forecasterService.getNameDimension()]);
    }),
    concatMap((data) => {
      this.forecasterService.nameDimension = data[0];
      return forkJoin([this.forecasterService.getTimeDimension()]);
    }),
    concatMap((data) => {
      this.forecasterService.timeDimension = data[0];
      return forkJoin([this.forecasterService.getSelectors()]);
    }),
    concatMap((data) => {
      this.forecasterService.selectors = data[0];
      return forkJoin([this.forecasterService.getAttributes()]);
    }),
    concatMap((data) => {
      this.forecasterService.attributes = data[0];
      return forkJoin([this.forecasterService.getScenarios('')]);
    }),
    concatMap((data) => {
      this.forecasterService.scenarios = data[0];
      return forkJoin([this.forecasterService.getState()]);
    }),
    concatMap((data) => {

      const state_obj_from_back = data[0];

      state_obj_from_back[FORECASTER_PAGE] = state_obj_from_back['page']['page']['page'];
      delete state_obj_from_back['page'];
      const general_data = {
        name_dimension: this.forecasterService.nameDimension,
        time_dimension: this.forecasterService.timeDimension,
        selectors: this.forecasterService.selectors,
        attributes: this.forecasterService.attributes,
        scenarios: this.forecasterService.scenarios,
      };
      const set_state_data: StateStructure = {
        scenarios_management: state_obj_from_back[SCENARIOS_KEY],
        user_manager_page: state_obj_from_back[USERS_KEY],
        simulator_page: state_obj_from_back[SIMULATOR_PAGE_KEY],
        manage_scope: state_obj_from_back[MANAGE_SCOPE_KEY],
        forecaster_page: state_obj_from_back[FORECASTER_PAGE],
        loader_on: false,
      };
      const return_arr = [];
      return_arr.push(SetGeneralData(general_data));
      return_arr.push(SetState({ payload: set_state_data }));
      if (this.forecasterService.action) {
        this.store.dispatch(UpdateForecastPage());
        this.forecasterService.action = false;
      }
      // return_arr.push(SetArraySelectors({ payload: array_filters_payloads }));
      this.spinnerService.hide();
      return return_arr;
    }),
  ));

  ChangeScenarioAfterActualization$ = createEffect(() => this.actions$.pipe(
    ofType(ForecasterActions.ChangeScenarioAfterActualization),      
    concatMap((action) => {
      this.spinnerService.show();
      return forkJoin([this.forecasterService.getScenarios(''), [action.payload]]);
    }),
    concatMap((data) => {
      this.forecasterService.scenarios = data[0];
      this.sessionStorage.set(SCENARIOS, data[0]);
      return forkJoin([this.forecasterService.getState(), [data[1]]]);
    }),
    concatMap((data) => {
      const state_obj_from_back = {};
      Object.assign(state_obj_from_back, data[0]);

      state_obj_from_back[FORECASTER_PAGE] = state_obj_from_back['page']['page']['page'];
      delete state_obj_from_back['page'];

      const set_state_data = Object.assign({}, {...state_obj_from_back, loader_on: false });

      this.sessionStorage.set(STATE_BACK, set_state_data);
      this.spinnerService.hide();
      return [UpdateForecastPage()];
    }),
  ));

  ChangePageForecast$ = createEffect(() => this.actions$.pipe(
    ofType(ForecasterActions.ChangePageForecast),
    concatMap((action) => {
      if (!this.spinnerService.check()) this.spinnerService.show();
      const request = [this.forecasterService.setPage(action['page'])];
      return forkJoin([...request]);
    }),
    concatMap(() => {
      //this.spinnerService.hide();
      return [];
    })
  ));

  ChangePage$ = createEffect(() => this.actions$.pipe(
    ofType(ForecasterActions.ChangePage),
    concatMap((action) => {
      this.spinnerService.show();
      const request = [this.forecasterService.setPage(action['page'])];
      return forkJoin([...request]);
    }),
    concatMap(() => {
      const state = this.sessionStorage.get(STATE_BACK);
      const page_id = state[FORECASTER_PAGE];
      let all_data = [];

      switch (page_id) {
        case SIMULATOR_PAGE_KEY:
          const main_selector = state[SIMULATOR_PAGE_KEY][SEL_NAME_LABEL][SEL_GENERAL];
          if (main_selector.length > 0) {
            all_data = this.collectSimulatorRequests();
          }
          break;
        case SCENARIOS_KEY:
          all_data = this.collectScenariosRequests();
          break;
        case USERS_KEY:
          all_data = [this.forecasterService.getUsers()];
          break;
        case MANAGE_SCOPE_KEY:
          all_data = this.collectScopeRequests();
          break;
      }
      if (all_data.length === 0) {
        this.spinnerService.hide();
      }
      return forkJoin([...all_data]);
    }),
    concatMap((request) => {
      const page_id = this.sessionStorage.get(STATE_BACK)[FORECASTER_PAGE];
      this.spinnerService.hide();
      switch (page_id) {
        case SIMULATOR_PAGE_KEY:
          return this.callSimulatorAction(request);
        case SCENARIOS_KEY:
          return this.callScenariosAction(request);
        case USERS_KEY:
          return [UsersActions.getUsers({ all_users: request[0] }), ForecasterActions.HideSpinner()];
        case MANAGE_SCOPE_KEY:
          return this.callScopeAction(request);
      }
      return [];
    })
  ));

  ChangeMainSelector$ = createEffect(() => this.actions$.pipe(
    ofType(ForecasterActions.ChangeMainSelector),
    concatMap((action) => {
      this.spinnerService.show();
      const request = [this.forecasterService.setMainSelector(action.payload)];
      return forkJoin([...request]);
    }),
    concatMap(() => {
      const self = this;
      const state_obj_from_back = self.sessionStorage.get(STATE_BACK);
      const selected_page: string = state_obj_from_back[FORECASTER_PAGE];
      const main_selector = state_obj_from_back[selected_page][SEL_NAME_LABEL][SEL_GENERAL];

      let array_filters_payloads = self.getFiltersPayloads(state_obj_from_back, selected_page, main_selector, false);

      if (selected_page === SIMULATOR_PAGE_KEY) {
        // array_filters_payloads.push(this.getPayloadWithEmptyArray(SIMULATOR_PAGE_KEY, SEL_TABLE, FACTS));
        array_filters_payloads = array_filters_payloads.concat(self.getCollapsesPayload(main_selector));
        array_filters_payloads.push(self.getSortsPayload(selected_page, main_selector));
        array_filters_payloads.push(this.getPayloadWithEmptyArray(SIMULATOR_PAGE_KEY, BLK_CHARTS, SEL_FILTERS));
        // array_filters_payloads.push(this.getPayloadWithEmptyArray(selected_page, SEL_TABLE, FILTER_BASE_KEYS));
      }
      if (selected_page === MANAGE_SCOPE_KEY) {
        // array_filters_payloads.push(this.getPayloadWithEmptyArray(MANAGE_SCOPE_KEY, SEL_TABLE, USERS));
        array_filters_payloads.push(self.getSortsPayload(selected_page, main_selector));
        // array_filters_payloads.push(this.getPayloadWithEmptyArray(selected_page, SEL_TABLE, FILTER_BASE_KEYS));
      }
      const all_requests = [];
      array_filters_payloads.forEach(item => {
        all_requests.push(this.forecasterService.setArraySelector(item));
      });
      return forkJoin([...all_requests]);
    }),
    concatMap(() => {
      const self = this;
      const state_obj_from_back = self.sessionStorage.get(STATE_BACK);
      const selected_page: string = state_obj_from_back[FORECASTER_PAGE];
      if (selected_page === SIMULATOR_PAGE_KEY) {
        return forkJoin([...this.collectSimulatorRequests()]);
      }
      if (selected_page === MANAGE_SCOPE_KEY) {
        return forkJoin([...this.collectScopeRequests()]);
      }
    }),
    concatMap((request) => {
      const self = this;
      const state_obj_from_back = self.sessionStorage.get(STATE_BACK);
      const selected_page: string = state_obj_from_back[FORECASTER_PAGE];
      const all_requests = [];
      if (selected_page === SIMULATOR_PAGE_KEY) {
        all_requests.push(...this.callSimulatorAction(request));
      }
      if (selected_page === MANAGE_SCOPE_KEY) {
        all_requests.push(...this.callScopeAction(request));
      }
      this.spinnerService.hide();
      return all_requests;
    })
  ));

  ChangeArraySelector$ = createEffect(() => this.actions$.pipe(
    ofType(ForecasterActions.ChangeArraySelector),
    concatMap((action) => {
      this.spinnerService.show();
      const req = [this.forecasterService.setArraySelector(action['payload'])];

      if (action['payload'].sub_component === FACTS || action['payload'].sub_component === _ALL_) {
        return forkJoin([...req, this.forecasterService.getAllDataNova()]);
      }
      return forkJoin([...req]);
    }),
    concatMap((res) => {
      if (res.length === 2) {
        this.simulatorService.discardData.next(res[1]);
      }
      this.spinnerService.hide();
      return [];
    })
  ));

  ChangeMainSelectorFilters$ = createEffect(() => this.actions$.pipe(
    ofType(ForecasterActions.ChangeMainSelectorFilters),
    concatMap((action) => {
      this.spinnerService.show();
      const req = [this.forecasterService.setArraySelector(action['payload'])];
      return forkJoin([...req]);
    }),
    concatMap(() => {
      const req = [this.forecasterService.getSubtotal()];
      return forkJoin([...req]);
    }),
    concatMap((request) => {
      this.spinnerService.hide();
      return [SimulatorActions.getSubtotalData({ payload: request[0] }), ForecasterActions.HideSpinner()];
    }),
  ));

  SetArraySelectors$ = createEffect(() => this.actions$.pipe(
    ofType(ForecasterActions.SetArraySelectors),
    concatMap((action) => {
      this.spinnerService.show();
      const array_payloads: SetArrayForSelectInEffect[] = action['payload'];
      const all_requests = [];
      array_payloads.forEach(item => {
        all_requests.push(this.forecasterService.setArraySelector(item));
      });
      return forkJoin([...all_requests]);
    }),
    concatMap(() => {
      return forkJoin([...this.collectSimulatorRequests()]);
    }),
    concatMap((res) => {
      const all_requests = [...this.callSimulatorAction(res)];
      this.spinnerService.hide();
      return all_requests;
    })
  ));

  ChangeArraySelectors$ = createEffect(() => this.actions$.pipe(
    ofType(ForecasterActions.ChangeArraySelectors),
    concatMap((action) => {
      this.spinnerService.show();
      const array_payloads: SetDataForSelectInEffect[] = action['payload'];
      const all_requests = [];
      array_payloads.forEach(payload => {        
        all_requests.push(this.forecasterService.setSelector(payload));
      });
      return forkJoin([...all_requests]);
    }),
    concatMap(() => {
      return forkJoin([...this.collectSimulatorRequests()]);
    }),
    concatMap((res) => {
      const all_requests = [...this.callSimulatorAction(res)];
      this.spinnerService.hide();
      return all_requests;
    })
  ));

  ChangeNoReqSelector$ = createEffect(() => this.actions$.pipe(
    ofType(ForecasterActions.ChangeNoReqSelector),
    concatMap((action: any) => {
      if (!this.spinnerService.check()) {
        this.spinnerService.show();
      }
      const req = [];
      
      if (action['payload'].length >= 0) {
        action['payload'].forEach((el: SetDataForSelectInEffect) => {
          req.push(this.forecasterService.setSelector(el));
        });

        return forkJoin([...req, 'qwe']);
      } else {
        req.push([this.forecasterService.setSelector(action['payload'])]);
      }

      return forkJoin([...req]);
    }),
    concatMap((res) => {
      if (res[res.length - 1] === 'e') {
        const req = [this.forecasterService.getScenarios('')];
        return forkJoin([...req]);
      }
      this.spinnerService.hide();
      return [];
    }),
    concatMap((res) => {
      if (res[0]) {
        return [SimulatorActions.getScenarioInfo({ payload: res[0] })];
      }
      this.spinnerService.hide();
      return [];
    }),
    concatMap((res) => {
      const state_back = this.sessionStorage.get(STATE_BACK);
      if (res) {
        if (state_back[SIMULATOR_PAGE_KEY][SEL_GENERAL][CHB_SHOW_LOCKED_ACTIVE] === IS_CHB_UNCHECKED
          || state_back[SIMULATOR_PAGE_KEY][SEL_GENERAL][CHB_SHOW_LOCKED_CONSOL] === IS_CHB_UNCHECKED
          || state_back[SIMULATOR_PAGE_KEY][SEL_GENERAL][CHB_SHOW_LOCKED_INIT] === IS_CHB_UNCHECKED) {
          const request = [];
          if (!res.payload['order'].find(scen => scen === state_back[SIMULATOR_PAGE_KEY][SEL_GENERAL][SEL_SCENARIO_CUR])) {
            const set_data: SetDataForSelectInEffect = {
              page: SIMULATOR_PAGE_KEY,
              component: SEL_GENERAL,
              sub_component: SEL_SCENARIO_CUR,
              new_value: res.payload['order'][0],
            };
            request.push(this.forecasterService.setSelector(set_data));
          }
          if (!res.payload['order'].find(scen => scen === state_back[SIMULATOR_PAGE_KEY][SEL_GENERAL][SEL_SCENARIO_BASE])) {
            const set_data: SetDataForSelectInEffect = {
              page: SIMULATOR_PAGE_KEY,
              component: SEL_GENERAL,
              sub_component: SEL_SCENARIO_BASE,
              new_value: res.payload['order'][0],
            };
            request.push(this.forecasterService.setSelector(set_data));
          }
          if(request.length > 0) {
            if (!this.forecasterService.isScenarioDisableForSave(state_back[SIMULATOR_PAGE_KEY][SEL_GENERAL][SEL_SCENARIO_CUR])) {
              return forkJoin([
                this.forecasterService.onSave(
                  state_back[SIMULATOR_PAGE_KEY][SEL_GENERAL][SEL_SCENARIO_CUR], 
                  res.payload[state_back[SIMULATOR_PAGE_KEY][SEL_GENERAL][SEL_SCENARIO_CUR]].name
                ),
                ...request
              ]);
            }
            return forkJoin([...request]);
          }
        }
      }
      this.spinnerService.hide();
      return [-1];
    }),
    concatMap(() => {
      const self = this;
      const state_obj_from_back = self.sessionStorage.get(STATE_BACK);
      const selected_page: string = state_obj_from_back[FORECASTER_PAGE];
      if (selected_page === SIMULATOR_PAGE_KEY) {
        return forkJoin([...this.collectSimulatorRequests()]);
      }
      if (selected_page === SCENARIOS_KEY) {
        return forkJoin([...this.collectScenariosRequests()]);
      }      
      this.spinnerService.hide();
    }),
    concatMap((request) => {
      const self = this;
      const state_obj_from_back = self.sessionStorage.get(STATE_BACK);
      const selected_page: string = state_obj_from_back[FORECASTER_PAGE];

      const all_requests = [];
      if (selected_page === SIMULATOR_PAGE_KEY) {
        all_requests.push(...this.callSimulatorAction(request));
      }
      if (selected_page === SCENARIOS_KEY) {
        all_requests.push(...this.callScenariosAction(request));
      }
      this.spinnerService.hide();
      return all_requests;
    })
  ));

  ChangeAllReqArraySelector$ = createEffect(() => this.actions$.pipe(
    ofType(ForecasterActions.ChangeAllReqArraySelector),
    concatMap((action) => {
      this.spinnerService.show();
      const req = [this.forecasterService.setArraySelector(action['payload'])];
      return forkJoin([...req]);
    }),
    concatMap(() => {
      const self = this;
      const state_obj_from_back = self.sessionStorage.get(STATE_BACK);
      const selected_page: string = state_obj_from_back[FORECASTER_PAGE];
      if (selected_page === SIMULATOR_PAGE_KEY) {
        return forkJoin([...this.collectSimulatorRequests()]);
      }
      return [];
    }),
    concatMap((request) => {
      const self = this;
      const state_obj_from_back = self.sessionStorage.get(STATE_BACK);
      const selected_page: string = state_obj_from_back[FORECASTER_PAGE];

      const all_requests = [];
      if (selected_page === SIMULATOR_PAGE_KEY) {
        all_requests.push(...this.callSimulatorAction(request));
      }
      this.spinnerService.hide();
      return all_requests;
    })
  ));

  ChangeAllReqSelector$ = createEffect(() => this.actions$.pipe(
    ofType(ForecasterActions.ChangeAllReqSelector),
    concatMap((action) => {
      if (!this.spinnerService.check()) {
        this.spinnerService.show();
      }
      this.forecasterService.hide = action.payload.hide;
      delete action.payload.hide;
      // Specific logic for Short Decomposition
      const payload = action['payload'];
      if (payload.page == SIMULATOR_PAGE_KEY &&
        payload.component == BLK_DECOMPOSITION &&
        (payload.sub_component == SEL_METRIC_DECOMP_ALLYEARS || payload.sub_component == SEL_METRIC_DECOMP) &&
        payload.new_value != 'value'
      ) {
        const set_data: SetDataForSelectInEffect = {
          page: SIMULATOR_PAGE_KEY,
          component: BLK_DECOMPOSITION,
          sub_component: SEL_SHORT_DECOMP,
          new_value: "No",
        };

        let state: StateStructure = this.sessionStorage.get(STATE_BACK);
        state[SIMULATOR_PAGE_KEY][BLK_DECOMPOSITION][SEL_SHORT_DECOMP] = "No";
        this.sessionStorage.set(STATE_BACK, state);

        const request = [this.forecasterService.setSelector(set_data), this.forecasterService.setSelector(action['payload'])];
        return forkJoin([...request]);
      }
      else {
        const request = [this.forecasterService.setSelector(action['payload'])];
        return forkJoin([...request, [action['payload']]]);
      }
    }),
    concatMap((action) => {
      if (action[1]['new_value'] === 'charts') {
        return forkJoin([this.forecasterService.getFilters({facts: ''})]);
      }
      return [1];
    }),
    concatMap((action) => {
      if (action['length']) {
        const state: StateStructure = this.sessionStorage.get(STATE_BACK);
        const isCheckedFacts = action[0].values.filter(el => el.isChecked).map(el => el.key);
        const isFactChartFirstSelector = isCheckedFacts.indexOf(state[SIMULATOR_PAGE_KEY][BLK_CHARTS][SEL_COMPARE_0]);
        const isFactChartSecondSelector = isCheckedFacts.indexOf(state[SIMULATOR_PAGE_KEY][BLK_CHARTS][SEL_COMPARE_1]);
        let request = [];
        if (isFactChartFirstSelector === -1) {
          state[SIMULATOR_PAGE_KEY][BLK_CHARTS][SEL_COMPARE_0] = isCheckedFacts[0];
          const set_data: SetDataForSelectInEffect = {
            page: SIMULATOR_PAGE_KEY,
            component: BLK_CHARTS,
            sub_component: SEL_COMPARE_0,
            new_value: isCheckedFacts[0],
          };
          request.push(this.forecasterService.setSelector(set_data));
        }
        if (isFactChartSecondSelector === -1) {
          state[SIMULATOR_PAGE_KEY][BLK_CHARTS][SEL_COMPARE_1] = isCheckedFacts[1];
          const set_data: SetDataForSelectInEffect = {
            page: SIMULATOR_PAGE_KEY,
            component: BLK_CHARTS,
            sub_component: SEL_COMPARE_1,
            new_value: isCheckedFacts[1],
          };
          request.push(this.forecasterService.setSelector(set_data));
        }
        if (isFactChartFirstSelector === -1 || isFactChartSecondSelector === -1) {
          this.sessionStorage.set(STATE_BACK, state);
          return forkJoin(request);
        }
      }
      return [1];
    }),
    concatMap(() => {
      const self = this;
      const state_obj_from_back = self.sessionStorage.get(STATE_BACK);
      const selected_page: string = state_obj_from_back[FORECASTER_PAGE];
      if (selected_page === SIMULATOR_PAGE_KEY) {
        return forkJoin([...this.collectSimulatorRequests()]);
      }
      if (selected_page === SCENARIOS_KEY) {
        return forkJoin([...this.collectScenariosRequests()]);
      }
      this.spinnerService.hide();
    }),
    concatMap((request) => {
      const self = this;
      const state_obj_from_back = self.sessionStorage.get(STATE_BACK);
      const selected_page: string = state_obj_from_back[FORECASTER_PAGE];

      const all_requests = [];
      if (selected_page === SIMULATOR_PAGE_KEY) {
        all_requests.push(...this.callSimulatorAction(request));
      }
      if (selected_page === SCENARIOS_KEY) {
        all_requests.push(...this.callScenariosAction(request));
      }
      if (this.forecasterService.hide) {
        this.forecasterService.hide = false; 
        return all_requests;
      }
      this.spinnerService.hide();
      return all_requests;
    })
  ));

  UploadZipActualization$ = createEffect(() => this.actions$.pipe(
    ofType(ForecasterActions.UploadZipActualization),
    concatMap((action) => {
      this.spinnerService.show();
      const request = []
      if (action.action === true && action.status === 'success_warning') {
        request.push(this.forecasterService.runModelForActualization(true));
        return forkJoin([...request, 'sss']);
      }
      if (action.action === true) {
        request.push(this.forecasterService.runModelForActualization(true));
        return forkJoin([...request]);
      }

      return forkJoin([...request, 'qwe']);
    }),
    concatMap((res) => {
      if (res[0] === 'e') {
        return forkJoin([this.forecasterService.finishUploadZIP("No"), 'qwe']);
      }
      if (res[1] === 's') {
        return forkJoin([this.forecasterService.finishUploadZIP("Yes"), 'sss']);
      }
      return forkJoin([this.forecasterService.finishUploadZIP("Yes")]);
    }),
    concatMap((res) => {
      this.store.dispatch(ClearState());
      this.store.dispatch(InitState({payload: false}));
      let data_for_dialog: NotificationDialogObject;
      if (res[1] === 'e') {
        this.spinnerService.hide();
        return [];
      } else if (res[1] === 's') {
        const data_for_dialog: ActualizeFailDialogObject = {
          title: 'Upload result',
          message: 'Data actualization succeeded. Please load the log file to see warnings',
        };
        const dialogRef = this.dialog.open(ActualizeFailDialogComponent, {
          data: data_for_dialog,
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.forecasterService.getLogFile()
              .toPromise()
              .then(res => {
                this.forecasterService.downloadFileFromBackEnd(res);
                this.spinnerService.hide();
              });
          } else {
            this.spinnerService.hide();
          }
        });
        return [];
      } else {
        this.spinnerService.hide();
        data_for_dialog = {
          type: 'result',
          message: 'Data was actualized successfully',
        };
      }
      this.dialog.open(NotificationDialogComponent, {
        data: data_for_dialog,
      });
      return [];
    })
  ));

  //  SIMULATOR

  ChangeTimeScale$ = createEffect(() => this.actions$.pipe(
    ofType(ForecasterActions.ChangeTimeScale),
    concatMap((action) => {
      this.spinnerService.show();
      const request = [this.forecasterService.setSelector(action.payload)];
      return forkJoin([...request]);
    }),
    concatMap(() => {
      const request = [this.forecasterService.getState()];
      return forkJoin([...request]);
    }),
    concatMap((request: any[]) => {
      const state_obj_from_back = request[0];

      state_obj_from_back[FORECASTER_PAGE] = state_obj_from_back['page']['page']['page'];
      delete state_obj_from_back['page'];
      
      const set_state_data: StateStructure = {
        scenarios_management: state_obj_from_back[SCENARIOS_KEY],
        user_manager_page: state_obj_from_back[USERS_KEY],
        simulator_page: state_obj_from_back[SIMULATOR_PAGE_KEY],
        manage_scope: state_obj_from_back[MANAGE_SCOPE_KEY],
        forecaster_page: state_obj_from_back[FORECASTER_PAGE],
        loader_on: false,
      };

      const new_time_points_table: SetDataForSelectInEffect[] = [{
        page: SIMULATOR_PAGE_KEY,
        component: SEL_GENERAL,
        sub_component: SEL_TIMEPERIOD_START,
        new_value: state_obj_from_back[SIMULATOR_PAGE_KEY][SEL_GENERAL][SEL_TIMEPERIOD_START]
      }, {
        page: SIMULATOR_PAGE_KEY,
        component: SEL_GENERAL,
        sub_component: SEL_TIMEPERIOD_END,
        new_value: state_obj_from_back[SIMULATOR_PAGE_KEY][SEL_GENERAL][SEL_TIMEPERIOD_END]
      }];
      
      // this.spinnerService.hide();
      return [
        SetState({ payload: set_state_data }), 
        ChangeTimePoints({ payload: new_time_points_table })
      ];
    })
  ));

  ChangeTimePoints$ = createEffect(() => this.actions$.pipe(
    ofType(ForecasterActions.ChangeTimePoints),
    concatMap((action) => {
      if (this.spinnerService.check()) {
        this.spinnerService.show();
      }
      const request = action.payload.map(payload => this.forecasterService.setSelector(payload));
      return forkJoin([...request]);
    }),
    concatMap(() => {
      return forkJoin([...this.collectSimulatorRequests()]);
    }),
    concatMap((request) => {
      // this.spinnerService.hide();
      return this.callSimulatorAction(request);
    })
  ));

  SetDataPoint$ = createEffect(() => this.actions$.pipe(
    ofType(ForecasterActions.SetDataPoint),
    concatMap((action) => {
      this.spinnerService.show();
      const request = [[action.payload], this.forecasterService.setTheData(action.payload)];
      return forkJoin([...request]);
    }),
    concatMap((request) => {
      const _payload: GetFactDataInterface = {
        component: request[0]['component'],
        fact: request[0]['fact'],
        entity: request[0][ENTITY]
      };

      const state_obj_from_back = this.sessionStorage.get(STATE_BACK);
      const selected_page: string = state_obj_from_back[FORECASTER_PAGE];
      const cur_scenario: string = state_obj_from_back[selected_page][SEL_GENERAL][SEL_SCENARIO_CUR];

      const req = [this.forecasterService.getFactData(_payload), this.forecasterService.getScenarioInfo(cur_scenario)];
      return forkJoin([...req]);
    }),
    concatMap((request) => {
      const all_data = {};
      all_data['data_tree'] = request[0]['data_tree'];
      this.spinnerService.hide();

      return [
        SimulatorActions.getFactData({ payload: all_data }),
        SimulatorActions.getScenarioInfo({ payload: request[1] }),
        ForecasterActions.HideSpinner()
      ];
    }),
  ));

  UpdateAllData$ = createEffect(() => this.actions$.pipe(
    ofType(ForecasterActions.UpdateAllData),
    concatMap(() => {
      this.spinnerService.show();
      return forkJoin([this.forecasterService.updateTheData({ 'init': 'No' })]);
    }),
    // concatMap(() => {
    //   const state_obj_from_back = this.sessionStorage.get(STATE_BACK);
    //   const selected_page: string = state_obj_from_back[FORECASTER_PAGE];
    //   const cur_scenario: string = state_obj_from_back[selected_page][SEL_GENERAL][SEL_SCENARIO_CUR];

    //   const req = [this.forecasterService.getScenarioInfo(cur_scenario)];
    //   return forkJoin([...req]);
    // }),
    // concatMap((request) => {
    //   return [SimulatorActions.getScenarioInfo({ payload: request[1] })];
    // }),
    concatMap(() => {
      return forkJoin([...this.collectSimulatorRequests()]);
    }),
    concatMap((request) => {
      this.spinnerService.hide();
      return this.callSimulatorAction(request);
    })
  ));

  updateForecastPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ForecasterActions.UpdateForecastPage),
      concatMap(() => {
        let all_data = this.collectSimulatorRequests();
        return concat(...all_data).pipe(toArray());
      }),
      concatMap((all_data) => {
        const all_requests = [];
        all_requests.push(...this.callSimulatorAction(all_data));
        return all_requests;
      })
    )
  );

  updateTotalsForecastPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ForecasterActions.UpdateTotalsForecastPage),
      concatMap(() => {
        this.spinnerService.show();
        let all_data = this.collectSimulatorTotalsRequests();
        return concat(...all_data).pipe(toArray());
      }),
      concatMap((all_data) => {
        const all_requests = [];
        all_requests.push(...this.callSimulatorTotalsAction(all_data));
        this.spinnerService.hide();
        return all_requests;
      })
    )
  );

  RefreshTable$ = createEffect(() => this.actions$.pipe(
    ofType(ForecasterActions.RefreshTable),
    concatMap(() => {
      this.spinnerService.show();
      return forkJoin([...this.collectSimulatorRequests()]);
    }),
    concatMap((request) => {
      this.spinnerService.hide();
      return this.callSimulatorAction(request);
    })
  ));

  ResetAllData$ = createEffect(() => this.actions$.pipe(
    ofType(ForecasterActions.ResetAllData),
    concatMap(() => {
      this.spinnerService.show();
      return forkJoin([this.forecasterService.resetTheData()]);
    }),
    concatMap(() => {
      return forkJoin([...this.collectSimulatorRequests()]);
    }),
    concatMap((request) => {
      this.spinnerService.hide();
      return this.callSimulatorAction(request);
    })
  ));

  //  SCENARIOS

  ChangeScenario$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ForecasterActions.ChangeScenario),
      concatMap((action) => {
        this.spinnerService.show();
        const request = [[action.payload], this.forecasterService.changeScenario(action.payload)];
        return forkJoin([...request]);
      }),
      concatMap((returned_data) => {
        const request = returned_data[0];
        const code: string = String(returned_data[1]);
        if (code === '2') {
          // if save as
          if (request[SCENARIO][UNDEFINED_ID]) {
            const scenario_name = returned_data[0][SCENARIO][UNDEFINED_ID][_NAME];
            this.sessionStorage.set(NEW_SCENARIO_NAME, scenario_name);
          }
          const state = this.sessionStorage.get(STATE_BACK);
          const page_id = state[FORECASTER_PAGE];
          let all_data = [];
          switch (page_id) {
            case SIMULATOR_PAGE_KEY: all_data = this.collectSimulatorRequests(); break;
            case SCENARIOS_KEY: {
              all_data = [this.forecasterService.getScenarios(state[SCENARIOS_KEY][SEL_GENERAL][SEL_SCENARIOS_TAB])]; 
              break;
            }
          }
          return forkJoin([...all_data]);
        }

        const dialog_type: NotificationType = code === '5' ? 'warning' : 'confirmation';
        const text_message = NOTIFICATION_SCENARIO_DICT[code];
        this.scenarioService.getNotificationResponse(dialog_type, text_message, request, this.dialog);
        return [];
      }),
      concatMap((all_data) => {

        if (all_data) {
          const self = this;
          const state = this.sessionStorage.get(STATE_BACK);
          const page_id = state[FORECASTER_PAGE];
          const all_requests = [];
          switch (page_id) {
            case SIMULATOR_PAGE_KEY:
              all_requests.push(...this.callSimulatorAction(all_data));
              // if save as
              if (this.sessionStorage.get(NEW_SCENARIO_NAME) && this.sessionStorage.get(NEW_SCENARIO_NAME) !== '') {
                // all_requests.push(SetNewScenarioSelector(), ForecasterActions.HideSpinner());
                // return [SetNewScenarioSelector()];
                // all_requests.push(SetNewScenarioSelector());
                all_requests.push(SetNewScenarioSelector());
              }
              break;
            case SCENARIOS_KEY:
              all_requests.push(
                ScenariosActions.getScenarios({ scenarios_data: all_data[0], consolidated: undefined, }),
                ForecasterActions.HideSpinner());
              break;
          }
          this.spinnerService.hide();
          this.scenariosService.navigateByLink.next();
          return all_requests;
        } else {
          this.spinnerService.hide();
          return [];
        }
      }),
    )
  );

  SetNewScenarioSelector$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ForecasterActions.SetNewScenarioSelector),
      concatMap(() => {
        if (this.sessionStorage.get(NEW_SCENARIO_NAME) && this.sessionStorage.get(NEW_SCENARIO_NAME) !== '') {
          const scenarios = this.sessionStorage.get(SCENARIOS);
          const state_obj_from_back = this.sessionStorage.get(STATE_BACK);
          // -- find id of new scenario
          const new_scenario_name: string = this.sessionStorage.get(NEW_SCENARIO_NAME);
          const scenarios_names_array: Array<string> = scenarios[SELECTORS_ORDER]
            .map(scenario_id => scenarios[scenario_id].name);
          const index_of_name: number = scenarios_names_array.indexOf(new_scenario_name);
          /// ---- LS ----- temporary while back is ready
          // -- remove scenario name field from session storage
          this.sessionStorage.set(NEW_SCENARIO_NAME, '');
          if (index_of_name === -1) {
            return [];
          }
          /// ---- LS end -----
          const new_id_to_select: string = scenarios[SELECTORS_ORDER][index_of_name];
          // -- set new scenario id as selected for current page in state
          const selected_page: string = state_obj_from_back[FORECASTER_PAGE];
          // !!! ATTENTION the following row was in logic, but seems to be redundant
          // state_obj_from_back[selected_page][SEL_GENERAL][SEL_SCENARIO_CUR] = new_id_to_select;
          this.sessionStorage.set(STATE_BACK, state_obj_from_back);
          const payload = {
            page: selected_page,
            component: SEL_GENERAL,
            sub_component: SEL_SCENARIO_CUR,
            new_value: new_id_to_select
          };
          //  ??? Maybe it is better to use ChangeNoReqSelector here
          // return [ChangeAllReqSelector({ payload: payload })];
          return [ForecasterActions.ChangeArraySelectors({ payload: [payload] })];
        }
        return [];
      })
    )
  );

  FinalizeEntity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ForecasterActions.FinalizeEntity),
      concatMap((action) => {
        this.spinnerService.show();
        const request = [[action.payload], this.forecasterService.finalizeEntity(action.payload)];
        return forkJoin([...request]);
      }),
      concatMap((all_data) => {
        if (all_data[1]['result'] == 1) {
          const data: NotificationDialogObject = {
            type: 'confirmation',
            message: all_data[1]['message'],
          };
          const dialogRef = this.dialog.open(NotificationDialogComponent, {
            data: data,
          });
          return forkJoin([[all_data[0]], dialogRef.afterClosed()]);
        } else if (all_data[1]['result'] == 0 && all_data[1]['message'] !== '') {
          const data: NotificationDialogObject = {
            type: 'information',
            message: all_data[1]['message'],
          };
          const dialogRef = this.dialog.open(NotificationDialogComponent, {
            data: data,
          });
          return [0];
        } else if (all_data[1]['result'] == -1) {
          const data: NotificationDialogObject = {
            type: 'error',
            message: all_data[1]['message'],
          };
          this.dialog.open(NotificationDialogComponent, {
            data: data,
          });
          return [-1];
        } else {
          return [0];
        }
      }),
      concatMap((all_data) => {
        if (all_data !== 0 && all_data !== -1 && all_data[1]) {
          const payload = { ...all_data[0], confirm: 'Yes'};
          const request = [this.forecasterService.finalizeEntity(payload)];
          return forkJoin([...request]);
        } else if (all_data === -1) {
          return [-1];
        } else {
          return [0];
        }
      }),
      concatMap((all_data) => {
        if (all_data !== -1) {
          if (all_data[0] && all_data[0]['result'] == 0 && all_data[0]['message'] !== '') {
            const data: NotificationDialogObject = {
              type: 'information',
              message: all_data[0]['message'],
            };
            const dialogRef = this.dialog.open(NotificationDialogComponent, {
              data: data,
            });
          }
          const set_data: SetDataForSelectInEffect = {
            page: SCENARIOS_KEY,
            component: SEL_GENERAL,
            sub_component: SEL_SCENARIOS_TAB,
            new_value: ACTIVE,
          };
          this.store.dispatch(ChangeAllReqSelector({payload: set_data}));
          return [1];
        }
        return [0];
      }),
      concatMap((all_data) => {
        if (all_data === 0) {
          this.spinnerService.hide();
        }
        return [];
      })
    )
  );

  //  USERS

  // TODO remove this
  changeChbMyScopeFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ForecasterActions.ChangeChbMyScopeFilter),
      concatMap((input) => {
        this.spinnerService.show();
        return from(this.forecasterService.setSelector(input.payload));
      }),
      concatMap(() => {
        this.spinnerService.hide();
        return [];
      })
    )
  );


  // ---------------------------------------------------------------------------------------------------------------------------



  // ------ Simulation Requests
  private collectSimulatorRequests() {
    let all_data = [];

    const state_obj_from_back = this.sessionStorage.get(STATE_BACK);
    const main_selector = state_obj_from_back[SIMULATOR_PAGE_KEY][SEL_NAME_LABEL][SEL_GENERAL];
    const selected_tab = state_obj_from_back[SIMULATOR_PAGE_KEY][SEL_GENERAL][SEL_SIMULATOR_TAB];

    const is_charts = selected_tab === 'charts'; // true; // false if is charts
    const is_decomposition = selected_tab === 'decomposition'; // true; // false if is decomp
    const is_waterfall = selected_tab === 'waterfall'; // true; // false if is decomp

    if (main_selector.length > 0 && !is_decomposition && !is_charts && !is_waterfall) {
      const table_tab = state_obj_from_back[SIMULATOR_PAGE_KEY][SEL_TABLE];
      if (table_tab[CHB_SHOW_SUBTOTALS] === 'checked' && table_tab[CHB_SHOW_TOTALS] === 'checked') {
        all_data = [
          this.forecasterService.getAllDataNova(),
          this.forecasterService.getScenarios(''),
          this.forecasterService.getFilters({ "sub_rows": "" }),
          //this.forecasterService.getChartData(),
          this.forecasterService.getSubtotal(),
          this.forecasterService.getTotal(),
        ];
      } else if (table_tab[CHB_SHOW_SUBTOTALS] === 'checked') {
        all_data = [
          this.forecasterService.getAllDataNova(),
          this.forecasterService.getScenarios(''),
          this.forecasterService.getFilters({ "sub_rows": "" }),
          //this.forecasterService.getChartData(),
          this.forecasterService.getSubtotal(),
          // this.forecasterService.getTotal(),
        ];
      } else if (table_tab[CHB_SHOW_TOTALS] === 'checked') {
        all_data = [
          this.forecasterService.getAllDataNova(),
          this.forecasterService.getScenarios(''),
          this.forecasterService.getFilters({ "sub_rows": "" }),
          //this.forecasterService.getChartData(),
          // this.forecasterService.getSubtotal(),
          this.forecasterService.getTotal(),
        ];
      } else {
        all_data = [
          this.forecasterService.getAllDataNova(),
          this.forecasterService.getScenarios(''),
          this.forecasterService.getFilters({ "sub_rows": "" }),
          //this.forecasterService.getChartData(),
          // this.forecasterService.getSubtotal(),
          // this.forecasterService.getTotal(),
        ];
      }
    } else if (main_selector.length > 0 && is_charts) {
      all_data = [
        this.forecasterService.getScenarios(''),
        // this.forecasterService.getFilters({"sub_rows": ""}),
        this.forecasterService.getChartData(),
        //this.forecasterService.getSubtotal(),
      ];
    } else if (main_selector.length > 0 && is_decomposition) {
      all_data = [
        this.forecasterService.getDecomposition(),
        this.forecasterService.getScenarios(''),
      ];
    } else if (main_selector.length > 0 && is_waterfall) {
      all_data = [
        this.forecasterService.getWaterfall(),
        this.forecasterService.getScenarios(''),
      ];
    }
    if (all_data.length === 0) {
      this.spinnerService.hide();
    }

    return all_data;
  }

  private collectSimulatorTotalsRequests() {
    let all_data = [];

    const state_obj_from_back = this.sessionStorage.get(STATE_BACK);
    const main_selector = state_obj_from_back[SIMULATOR_PAGE_KEY][SEL_NAME_LABEL][SEL_GENERAL];
    const selected_tab = state_obj_from_back[SIMULATOR_PAGE_KEY][SEL_GENERAL][SEL_SIMULATOR_TAB];

    const is_charts = selected_tab === 'charts'; // true; // false if is charts
    const is_decomposition = selected_tab === 'decomposition'; // true; // false if is decomp
    const is_waterfall = selected_tab === 'waterfall'; // true; // false if is decomp

    if (main_selector.length > 0 && !is_decomposition && !is_charts && !is_waterfall) {
      const table_tab = state_obj_from_back[SIMULATOR_PAGE_KEY][SEL_TABLE];
      if (table_tab[CHB_SHOW_SUBTOTALS] === 'checked' && table_tab[CHB_SHOW_TOTALS] === 'checked') {
        all_data = [
          this.forecasterService.getSubtotal(),
          this.forecasterService.getTotal(),
        ];
      } else if (table_tab[CHB_SHOW_SUBTOTALS] === 'checked') {
        all_data = [
          this.forecasterService.getSubtotal(),
          // this.forecasterService.getTotal(),
        ];
      } else if (table_tab[CHB_SHOW_TOTALS] === 'checked') {
        all_data = [
          // this.forecasterService.getSubtotal(),
          this.forecasterService.getTotal(),
        ];
      }
    }

    if (all_data.length === 0) {
      this.spinnerService.hide();
    }

    return all_data;
  }

  private callSimulatorTotalsAction(all_data) {
    const state_obj_from_back = this.sessionStorage.get(STATE_BACK);
    const main_selector = state_obj_from_back[SIMULATOR_PAGE_KEY][SEL_NAME_LABEL][SEL_GENERAL];
    const selected_tab = state_obj_from_back[SIMULATOR_PAGE_KEY][SEL_GENERAL][SEL_SIMULATOR_TAB];

    const is_charts = selected_tab === 'charts'; // true; // false if is charts
    const is_decomposition = selected_tab === 'decomposition'; // true; // false if is decomp
    const is_waterfall = selected_tab === 'waterfall'; // true; // false if is decomp

    let req: SimulatorTotalsState = {
      subtotal: undefined,
      total: undefined,
    };

    if (main_selector.length > 0 && !is_decomposition && !is_charts && !is_waterfall) {
      
      const table_tab = state_obj_from_back[SIMULATOR_PAGE_KEY][SEL_TABLE];
      if (table_tab[CHB_SHOW_SUBTOTALS] === 'checked' && table_tab[CHB_SHOW_TOTALS] === 'checked') {
        req = {
          subtotal: all_data[0],
          total: all_data[1],
        };
      } else if (table_tab[CHB_SHOW_SUBTOTALS] === 'checked') {
        req = {
          subtotal: all_data[0],
          total: undefined,
        };
      } else if (table_tab[CHB_SHOW_TOTALS] === 'checked') {
        req = {
          subtotal: undefined,
          total: all_data[0],
        };
      } else {
        req = {
          subtotal: undefined,
          total: undefined,
        };
      }      
    }

    return [
      //ForecasterActions.ShowSpinner(),
      SimulatorActions.getTotalsData({ payload: req }),
      //ForecasterActions.HideSpinner()
    ];
  }

  private callSimulatorAction(all_data) {
    const state_obj_from_back = this.sessionStorage.get(STATE_BACK);
    const main_selector = state_obj_from_back[SIMULATOR_PAGE_KEY][SEL_NAME_LABEL][SEL_GENERAL];
    const selected_tab = state_obj_from_back[SIMULATOR_PAGE_KEY][SEL_GENERAL][SEL_SIMULATOR_TAB];

    const is_charts = selected_tab === 'charts'; // true; // false if is charts
    const is_decomposition = selected_tab === 'decomposition'; // true; // false if is decomp
    const is_waterfall = selected_tab === 'waterfall'; // true; // false if is decomp

    let req: SimulatorState = {
      main_table: undefined,
      cagr_table: undefined,
      subtotal: undefined,
      total: undefined,
      decomposition: undefined,
      scenarios: undefined,
      waterfall: undefined,
      sub_rows: undefined,
    };

    if (main_selector.length > 0 && !is_decomposition && !is_charts && !is_waterfall) {
      const table_tab = state_obj_from_back[SIMULATOR_PAGE_KEY][SEL_TABLE];
      if (table_tab[CHB_SHOW_SUBTOTALS] === 'checked' && table_tab[CHB_SHOW_TOTALS] === 'checked') {
        req = {
          main_table: all_data[0],
          cagr_table: undefined, //all_data[2],
          subtotal: all_data[3],
          total: all_data[4],
          decomposition: undefined,
          waterfall: undefined,
          scenarios: all_data[1],
          sub_rows: all_data[2],
        };
      } else if (table_tab[CHB_SHOW_SUBTOTALS] === 'checked') {
        req = {
          main_table: all_data[0],
          cagr_table: undefined, //all_data[2],
          subtotal: all_data[3],
          total: undefined,
          decomposition: undefined,
          waterfall: undefined,
          scenarios: all_data[1],
          sub_rows: all_data[2],
        };
      } else if (table_tab[CHB_SHOW_TOTALS] === 'checked') {
        req = {
          main_table: all_data[0],
          cagr_table: undefined, //all_data[2],
          subtotal: undefined,
          total: all_data[3],
          decomposition: undefined,
          waterfall: undefined,
          scenarios: all_data[1],
          sub_rows: all_data[2],
        };
      } else {
        req = {
          main_table: all_data[0],
          cagr_table: undefined, //all_data[2],
          subtotal: undefined,
          total: undefined,
          decomposition: undefined,
          waterfall: undefined,
          scenarios: all_data[1],
          sub_rows: all_data[2],
        };
      }
    } else if (main_selector.length > 0 && is_charts) {
      req = {
        main_table: undefined,
        cagr_table: all_data[1], //all_data[2],
        subtotal: undefined, //all_data[3],
        total: undefined,
        decomposition: undefined,
        waterfall: undefined,
        scenarios: all_data[0],
        sub_rows: undefined,
      };
    } else if (main_selector.length > 0 && is_waterfall) {
      req = {
        main_table: undefined,
        cagr_table: undefined,
        subtotal: undefined,
        total: undefined,
        decomposition: undefined,
        scenarios: all_data[1],
        sub_rows: undefined,
        waterfall: all_data[0],
      };
      this.spinnerService.hide();
    } else if (main_selector.length > 0 && is_decomposition) {
      req = {
        main_table: undefined,
        cagr_table: undefined,
        subtotal: undefined,
        total: undefined,
        decomposition: all_data[0],
        scenarios: all_data[1],
        sub_rows: undefined,
        waterfall: undefined,
      };
      this.spinnerService.hide();
    }

    return [
      //ForecasterActions.ShowSpinner(),
      SimulatorActions.getAllSimulatorData(req),
      //ForecasterActions.HideSpinner()
    ];
  }

  // ------ Scope Requests
  private collectScopeRequests() {
    let all_data = [];

    const state_obj_from_back = this.sessionStorage.get(STATE_BACK);
    const main_selector = state_obj_from_back[MANAGE_SCOPE_KEY][SEL_NAME_LABEL][SEL_GENERAL];
    if (main_selector.length > 0) {
      all_data = [
        // this.forecasterService.getAllDataNova(),
        this.forecasterService.getUsers(),
      ];
    } else {
      this.spinnerService.hide();
    }
    return all_data;
  }

  private callScopeAction(all_data) {
    const state_obj_from_back = this.sessionStorage.get(STATE_BACK);
    const main_selector = state_obj_from_back[MANAGE_SCOPE_KEY][SEL_NAME_LABEL][SEL_GENERAL];

    let req: ScopeState = {
      // data_tree: undefined,
      all_users: undefined
    };

    if (main_selector.length > 0) {
      req = {
        // data_tree: all_data[0],
        all_users: all_data[0]
      };
    }
    return [ScopeActions.getUsers(req)];
  }

  // ------ Scenario Requests
  private collectScenariosRequests() {
    const state_obj_from_back = this.sessionStorage.get(STATE_BACK);
    const selected_tab = state_obj_from_back[SCENARIOS_KEY][SEL_GENERAL][SEL_SCENARIOS_TAB];

    let all_data = [];
    if (selected_tab === TAB_SCENARIOS_ACTIVE) {
      all_data = [this.forecasterService.getScenarios(TAB_SCENARIOS_ACTIVE)];
    } else if (selected_tab === TAB_SCENARIOS_LOCKED) {
      all_data = [this.forecasterService.getScenarios(TAB_SCENARIOS_LOCKED)];
    } else if (selected_tab === TAB_SCENARIOS_CONSOLIDATED) {
      all_data = [this.forecasterService.getConsolidated()];
    }
    return all_data;
  }

  private callScenariosAction(all_data) {
    const state_obj_from_back = this.sessionStorage.get(STATE_BACK);
    const selected_tab = state_obj_from_back[SCENARIOS_KEY][SEL_GENERAL][SEL_SCENARIOS_TAB];

    let req: AllScenariosState = {
      scenarios_data: undefined,
      consolidated: undefined,
    };

    if (selected_tab !== TAB_SCENARIOS_CONSOLIDATED) {   // ----- active, locked {
      req = {
        scenarios_data: all_data[0],
        consolidated: undefined,
      };
    } else {
      req = {
        scenarios_data: undefined,
        consolidated: all_data[0],
      };
    }
    return [ScenariosActions.getScenarios(req)];
  }


  /// ----- SERVICE FUNCTIONS for the TABLE with filters/ sorts / collapses     ----------------
  private getPayloadWithEmptyArray(page, component, sub_component): SetArrayForSelectInEffect {
    return {
      page: page,
      component: component,
      sub_component: sub_component,
      new_value: [],
    };
  }

  private getFiltersPayloads(state_obj, page: string, new_main_selector, is_init): SetArrayForSelectInEffect[] {

    const arra_payloads: SetArrayForSelectInEffect[] = [];

    return arra_payloads;

  }

  private getSortsPayload(page, main_selector): SetArrayForSelectInEffect {
    const sort_cols = [...Array(main_selector.length).fill('asc')];
    sort_cols.push('default');
    sort_cols.push('default');
    return {
      page: page,
      component: SEL_TABLE,
      sub_component: SEL_SORTS,
      new_value: sort_cols,
    };
  }

  private getCollapsesPayload(main_selector): SetArrayForSelectInEffect[] {
    const arra_payloads: SetArrayForSelectInEffect[] = [];
    if (main_selector.length > 0) {
      main_selector.forEach(column_id => {
        arra_payloads.push(this.getPayloadWithEmptyArray(SIMULATOR_PAGE_KEY, SEL_COLLAPSES, column_id));
      });
    }
    arra_payloads.push(this.getPayloadWithEmptyArray(SIMULATOR_PAGE_KEY, SEL_COLLAPSES, FACTS));
    return arra_payloads;
  }

  // getTimePoints(state, old_ts, new_ts, component_name, sub_component_start, sub_component_end) {
  //   const old_tp_start = state[SIMULATOR_PAGE_KEY][component_name][sub_component_start];
  //   const old_tp_end = state[SIMULATOR_PAGE_KEY][component_name][sub_component_end];
  //   let new_tp_start = this.sessionStorageProvider.getNewTimePoint(new_ts, old_ts, old_tp_start, true);
  //   let new_tp_end = this.sessionStorageProvider.getNewTimePoint(new_ts, old_ts, old_tp_end, false);

  //   const orderToCheck: string[] = this.sessionStorageProvider.getTimeDimension()[new_ts + ORDER_KEY];
  //   if (!orderToCheck.find(item => item === new_tp_start)) {
  //     new_tp_start = orderToCheck[0];
  //   }
  //   if (!orderToCheck.find(item => item === new_tp_end)) {
  //     new_tp_end = orderToCheck[orderToCheck.length - 1];
  //   }

  //   // adjust decomp timepoints
  //   if (component_name === BLK_DECOMPOSITION) {
  //     if (new_tp_start === new_tp_end) {
  //       const order: string[] = this.sessionStorageProvider.getTimeDimension()[new_ts + ORDER_KEY];
  //       new_tp_start = order[0];
  //       new_tp_end = order[order.length - 1];
  //     }
  //   }

  //   const tp_start_obj: SetDataForSelectInEffect = {
  //     page: SIMULATOR_PAGE_KEY,
  //     component: component_name, // SEL_GENERAL,
  //     sub_component: sub_component_start,
  //     new_value: new_tp_start,
  //   };
  //   const tp_end_obj: SetDataForSelectInEffect = {
  //     page: SIMULATOR_PAGE_KEY,
  //     component: component_name,
  //     sub_component: sub_component_end,
  //     new_value: new_tp_end,
  //   };
  //   const new_time_points: SetDataForSelectInEffect[] = [tp_start_obj, tp_end_obj];
  //   return new_time_points;
  // }
}
