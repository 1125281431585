import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { ComboBoxInformation, ComboBoxItem } from '@app/_components/model/common.interfaces';
import { commonConstants } from '@app/consts/common';

import { ForecasterService } from '../../../forecaster.service';
import { SessionStorageProviderService } from '../../../session-storage-provider.service';

@Component({
  selector: 'app-actualization-setup-dialog',
  templateUrl: './actualization-setup-dialog.component.html',
  styleUrls: ['./actualization-setup-dialog.component.less']
})
export class ActualizationSetupDialogComponent implements OnInit {  
  constructor(
    public dialogRef: MatDialogRef<ActualizationSetupDialogComponent>,
    private dialog: MatDialog,
    private forecastService: ForecasterService,
    private sessionStorageProvider: SessionStorageProviderService,
    @Inject(MAT_DIALOG_DATA) public data,
    @Inject(DOCUMENT) private document: Document,    
  ) { }

  title = "Data Update is available.";
  subtitle = "Do you want to update your scenarios with it?";
  action: String;

  ngOnInit(): void {
  }

  onDialogButtonCloseClick() {
    this.dialogRef.close();
  }

  onOkClick() {
    const data = {
      response: 'OK',
    }
    this.dialogRef.close(data);
  }

}
