<h1 mat-dialog-title class="my_title">{{data.title}}</h1>

<form [formGroup]="myForm" autocomplete="off" novalidate class="my_dialog"  (keyup.enter)="onInputClick($event)">

  <mat-form-field class="my_form-field">
    <mat-label>Input new scenario name</mat-label>
    <input matInput formControlName="name" required >
  </mat-form-field>
  <ng-container *ngIf="data.isSelector">
    <ng-container class="right" *ngIf="selScenarioItems$| async as scenarios_items">
      <div class="selector-wrapper">
        <app-mat-selector
          (keyChanged)="onCurScenarioChanged($event)"
          [selector_items]="scenarios_items" [selector_info]="selScenarioCurrentInfo">
        </app-mat-selector>
      </div>
    </ng-container>
  </ng-container>
  <div class="my_error">
    <div *ngIf="(f.name.invalid && f.name.touched) || f.name.dirty">
      <!--?<small *ngIf="f.name.errors?.required">Scenario Name is required.​</small>-->
      <small *ngIf="f.name.errors?.minlength">Scenario Name must be at least 4 characters long​. </small>
      <small *ngIf="f.name.errors?.pattern">Scenario Name is invalid. </small>
      <small *ngIf="f.name.errors?.forbiddenScenarioName">Scenario with this name can't be saved as or renamed. Please,
        enter another name. </small>
    </div>
  </div>

</form>

<div class="bottom_btns_block">
  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()" cdkFocusInitial>Cancel</button>
    <button mat-button [disabled]="myForm.invalid" [mat-dialog-close]="getNewData()" #btn>Save</button>
  </div>
</div>