import {Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import { MatSelect } from '@angular/material/select';
import {
  ComboBoxInformation,
  ComboBoxItem,
} from '../../../_components/model/common.interfaces';

@Component({
  selector: 'app-mat-selector',
  templateUrl: './mat-selector.component.html',
  styleUrls: ['./mat-selector.component.scss']
})
export class MatSelectorComponent implements OnInit, OnChanges {

  @Input() selector_items: ComboBoxItem[];
  @Input() selector_info: ComboBoxInformation;
  @Input() selection_key: string;

  @Output() keyChanged = new EventEmitter();


  @ViewChild('singleSelect') singleSelect: MatSelect;

  constructor() {
  }

  ngOnInit() {
    this.selection_key = this.selector_info.active_key;
  }

  ngOnChanges() {
    this.selection_key = this.selector_info.active_key;
  }

  onSelect(option_key: string, index) {
    if (this.selector_items[index].is_disabled) {
      return;
    }
    this.selection_key = option_key;
    this.keyChanged.emit(option_key);
  }
}
