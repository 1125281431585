<div class="dialog-wrapper">
    <div mat-dialog-title class="flex-container">
        <h1 mat-dialog-title class="title">{{title}}</h1>
        <button mat-button class="close-icon" (click)="onDialogButtonCloseClick()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div mat-dialog-content>
        <form class="new-actualization-form" [formGroup]="actualizationNewForm">
            <section class="section">
                <table>
                    <td>
                        <tr>
                            <mat-form-field class="tag-input">
                                <mat-label>Enter Tag</mat-label>
                                <input matInput #tag maxlength="16" formControlName="tag">
                            </mat-form-field>
                        </tr>
                        <tr>
                            <div class="buttons-panel">
                                <button mat-button class="input-button" (click)="fileInput.click()">OK</button>
                                <button mat-button class="input-button" (click)="onDialogButtonCloseClick()">CANCEL</button>
                            </div>
                        </tr>
                        <tr>
                            <mat-slide-toggle [color]="'primary'" [checked]="false" formControlName="consolidatedAsActual" style="padding-top: 25px;">
                                Set Consolidated as Actual
                            </mat-slide-toggle>
                        </tr>
                    </td>
                </table>
            </section>
        </form>
    </div>
    <input #fileInput type="file" name="stl" accept=".zip,.csv" style="display:none;" (change)="onOkClick($event)" (click)="$event.target.value=null">
</div>
