<div mat-dialog-title class="flex-container">
    <h1 mat-dialog-title class="title">{{title}}</h1>
    <button mat-button class="close-icon" (click)="onDialogButtonCloseClick()">
        <mat-icon>close</mat-icon>
    </button>
</div>

<div class="container mat-dialog-content">

    <div class="left-panel">
        <h3>Available Dimensions</h3>
        <div class="dimensions-panel">
            <mat-selection-list #availableList>
            <mat-list-option *ngFor="let dimension of availableDimensions" [value]="dimension">
                {{getLabel(dimension)}}
            </mat-list-option>
            </mat-selection-list>
        </div>
    </div>

    <div class="buttons">
        <button mat-raised-button class="selector-button" (click)="moveToSelected()">
        <mat-icon>chevron_right</mat-icon>
        </button>
        <button mat-raised-button class="selector-button" (click)="moveToAvailable()">
        <mat-icon>chevron_left</mat-icon>
        </button>
        <button mat-raised-button class="selector-button" (click)="moveAllToSelected()">
        <mat-icon>keyboard_double_arrow_right</mat-icon>
        </button>
        <button mat-raised-button class="selector-button" (click)="moveAllToAvailable()">
        <mat-icon>keyboard_double_arrow_left</mat-icon>
        </button>
    </div>
    
    <div class="right-panel">
        <h3>Selected Dimensions</h3>
        <div class="dimensions-panel">
            <mat-selection-list #selectedList>
            <mat-list-option *ngFor="let dimension of selectedDimensions" [value]="dimension">
                {{getLabel(dimension)}}
            </mat-list-option>
            </mat-selection-list>
        </div>
    </div>

    <div class="buttons">
        <button mat-raised-button class="selector-button" (click)="moveUp()">
        <mat-icon>expand_less</mat-icon>
        </button>
        <button mat-raised-button class="selector-button" (click)="moveDown()">
        <mat-icon>expand_more</mat-icon>
        </button>
    </div>
  
</div>

<div mat-dialog-actions class="dialog-actions">
    <button mat-raised-button color="basic" (click)="onDialogButtonCloseClick()">Cancel</button>
    <button mat-raised-button color="primary" [disabled]="isApplyButtonDisabled()" (click)="onDialogButtonApplyClick()">Apply</button>
</div>
