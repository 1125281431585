import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { ComboBoxInformation, ComboBoxItem } from '@app/_components/model/common.interfaces';
import { commonConstants } from '@app/consts/common';

import { ForecasterService } from '../../../forecaster.service';
import { SessionStorageProviderService } from '../../../session-storage-provider.service';

@Component({
  selector: 'app-new-actualization-dialog',
  templateUrl: './actualization-new-dialog.component.html',
  styleUrls: ['./actualization-new-dialog.component.less']
})
export class ActualizationNewDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ActualizationNewDialogComponent>,
    private dialog: MatDialog,
    private forecastService: ForecasterService,
    private sessionStorageProvider: SessionStorageProviderService,
    @Inject(MAT_DIALOG_DATA) public data,
    @Inject(DOCUMENT) private document: Document,    
  ) { }

  title = "Set New Actualization Tag";

  public actualizationNewForm: FormGroup = new FormGroup({});

  action: String;

  ngOnInit(): void {
    // define form controls
    this.actualizationNewForm.addControl("tag", new FormControl('', [Validators.required]));
    this.actualizationNewForm.addControl("consolidatedAsActual", new FormControl('', [Validators.required]));

    // set default values
    this.actualizationNewForm.controls['consolidatedAsActual'].setValue(false);
  }

  onDialogButtonCloseClick() {
    this.dialogRef.close();
  }

  onOkClick(event) {
    const data = {
      tag: this.actualizationNewForm.controls['tag'].value,
      consolidatedAsActual: this.actualizationNewForm.controls['consolidatedAsActual'].value,
      response: 'OK',
      fileList: FileList = event.target.files
    }
    this.dialogRef.close(data);
  }

}
