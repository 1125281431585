import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-no-scope',
  templateUrl: './no-scope.component.html',
  styleUrls: ['./no-scope.component.less']
})
export class NoScopeComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  goToLogin() {
    const LOGIN_URL = '/login';
    this.router.navigateByUrl(LOGIN_URL);
  }
}
