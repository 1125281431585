
                    
    <mat-form-field>
      <mat-label class="my-label">{{selector_info.label}}</mat-label>

      <mat-select [(value)]="selection_key"
                  [disableOptionCentering]="true"
                  panelClass="my-panel"
                  disableRipple
                  [disabled]='selector_info.is_disabled'>

        <mat-option *ngFor="let item of selector_items, let i = index"
                    [disabled] = "item.is_disabled"
                    [value]="item.key"
                    (click)="onSelect(item.key, i)"
                    [matTooltip]="item.label"
                    class="not-empty-select" >
                    <!-- <mat-icon *ngIf="selector_info.item_id==='sel_scenario_cur'">cached</mat-icon> -->
          {{item.label}}
        </mat-option>

      </mat-select>
    </mat-form-field>
