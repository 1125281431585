<div class="my_dialog">
    <h1 mat-dialog-title class="my_title">{{data.title}}</h1>
    <div class="my-mat-dialog-content">
        <button mat-raised-button color="primary" (click)="onLoadClick()">Load Log</button>
        {{data.message}}
    </div>
    <div class="bottom_btns_block">
        <div mat-dialog-actions>
            <button mat-button (click)="onNoClick()" cdkFocusInitial>Ok</button>
        </div>
    </div>
</div>