import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import {MatDialogRef} from "@angular/material/dialog";
import {NotificationDialogObject} from "../notification.model";

@Component({
  selector: 'app-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.less']
})
export class NotificationDialogComponent implements OnInit {
  title: string;

  constructor(public dialogRef: MatDialogRef<NotificationDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: NotificationDialogObject) {
    switch (data.type){
      case 'confirmation': this.title = "Confirmation"; break;
      case 'warning':      this.title = "Warning"; break;
      case 'error':        this.title = "Error"; break;
      case 'result':        this.title = "Upload result"; break;
      case 'information': this.title = "Information"; break;
    }
  }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  onYesClick(): void {
    this.dialogRef.close(true);
  }
}
