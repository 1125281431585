<div class="container">
  <div class="row">
    <div id="loader">
      <div class="triple-spinner"></div>
      <div class="loading"></div>
      <p class="spinner-message" *ngIf="message && message !== ''">
        {{message}}
      </p>
    </div>
  </div>
</div>