import {Injectable} from '@angular/core';
import {
  Router,
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import {LoginService} from './login.service';
import {NO_SCOPE_URL} from './login.model';

@Injectable({providedIn: 'root'})

export class LoginGuard implements CanActivate, CanActivateChild {

  constructor(private router: Router,
              private loginService: LoginService, ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.loginService.isTokenExpired()) {
      this.router.navigate(['/login'], {
        queryParams: {returnUrl: state.url}
      });
    } else if (this.loginService.getIfHasScope() === false) {
      this.router.navigateByUrl(NO_SCOPE_URL);
    }

    return true;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.loginService.isTokenExpired()) {
      this.router.navigate(['/login'], {
        queryParams: {returnUrl: state.url}
      });
    }
    return true;
  }
}
