import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { ComboBoxInformation, ComboBoxItem } from '@app/_components/model/common.interfaces';

import { ForecasterService } from '../../../forecaster.service';
import { SessionStorageProviderService } from '../../../session-storage-provider.service';
import { SEL_REPORT_TYPE } from '../../../models/forecaster.constants';


@Component({
  selector: 'app-main-report',
  templateUrl: './main-report.component.html',
  styleUrls: ['./main-report.component.less']
})
export class MainReportComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<MainReportComponent>,
    private dialog: MatDialog,
    private forecastService: ForecasterService,
    private sessionStorageProvider: SessionStorageProviderService,
    @Inject(MAT_DIALOG_DATA) public data,
    @Inject(DOCUMENT) private document: Document,    
  ) { }

  title = "Generate Report";
  
  scenarioOptions: Array<ComboBoxItem> = [];
  selectedScenarios: any;
  selReportType: any;

  public mainReportForm: FormGroup = new FormGroup({});

  ngOnInit(): void {
    // define form controls
    this.mainReportForm.addControl("scope", new FormControl('', [Validators.required]));
    this.mainReportForm.addControl("scenario", new FormControl('', [Validators.required]));
    this.selReportType = this.sessionStorageProvider.getSelectorById(SEL_REPORT_TYPE);
    
    // get list of scenarios
    this.forecastService.getScenarios("").subscribe(res => {
      res['order'].forEach(item => {
        this.scenarioOptions.push(
          {
            key: item,
            label: res[item]['name'],
            is_disabled: false,
          }
        )
      });
    });
  }

  onDialogButtonCloseClick() {
    this.dialogRef.close();
  }

  isOptionSelected(key) {
    if(this.mainReportForm.controls['scenario'].value.includes(key)) {
      return true;
    }
    else {
      return false;
    }
  }

  onGenerate() {
    this.dialogRef.close(
      { 
        option: this.mainReportForm.controls['scope'].value,
        scenarios: this.mainReportForm.controls['scenario'].value,
      }
    );
  }

  getLabel() {
    const countOfMembers: number = this.mainReportForm.controls['scenario'].value.length;
    const dimLabels = this.scenarioOptions;

    if (countOfMembers == 0) {
      return '';
    }
    else if(countOfMembers == 1) {
      const item = dimLabels.find(x => x.key === this.mainReportForm.controls['scenario'].value[0]);
      return item.label;
    }
    else if(countOfMembers == 2) {
      const item = dimLabels.find(x => x.key === this.mainReportForm.controls['scenario'].value[0]);
      return item.label + ' (+1 other)';
    }
    else {
      const item = dimLabels.find(x => x.key === this.mainReportForm.controls['scenario'].value[0]);
      return item.label + ' (+' + (countOfMembers-1).toString() + ' others)';
    }
  }

}
