<div class="dialog-wrapper">
    <div mat-dialog-title class="flex-container">
        <div></div>
        <div>
            <h1 mat-dialog-title class="title">{{title}}</h1>
            <h1 mat-dialog-title class="title">{{subtitle}}</h1>
        </div>
        <button mat-button class="close-icon" (click)="onDialogButtonCloseClick()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="buttons-panel">
        <button mat-button class="input-button" (click)="onOkClick()">YES</button>
        <button mat-button class="input-button" (click)="onDialogButtonCloseClick()">NO</button>
    </div>
</div>