import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { catchError, concatMap, switchMap, toArray} from 'rxjs/operators';
import { concat, from, of } from 'rxjs';

import { ComboBoxInformation, ComboBoxItem } from '../../../../../_components/model/common.interfaces';
import { NameDimensionObject } from '../../../models/forecaster.model';
import { ForecasterService } from '../../../forecaster.service';
import { SessionStorageProviderService } from '../../../session-storage-provider.service';
import { SpinnerOverlayService } from '@app/shared/services/spinner-overlay.service';

import { SEL_TIMEPERIODM, TBL_FORECAST, _NAME } from '../../../models/forecaster.constants';
import { SetDataInterface } from '../../../models/forecaster.model';
import { ErrorDialogComponent } from '@app/shared/components/error-dialog/error-dialog.component'


@Component({
  selector: 'app-large-scale-simulation',
  templateUrl: './large-scale-simulation.component.html',
  styleUrls: ['./large-scale-simulation.component.less']
})
export class LargeScaleSimulationComponent implements OnInit {

  constructor(
    private forecastService: ForecasterService,
    private spinnerService: SpinnerOverlayService,
    private ssp: SessionStorageProviderService,
    public dialogRef: MatDialogRef<LargeScaleSimulationComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data,
    @Inject(DOCUMENT) private document: Document,
  ) { }

  title = "Large Scale Simulation Input";

  factInfo: ComboBoxInformation = {
    item_id: "fact_selector",
    label: "Fact",
  }
  factOptions: ComboBoxItem[] = [];

  startPeriodInfo: ComboBoxInformation = {}
  endPeriodInfo: ComboBoxInformation = {}
  startPeriodOptions: ComboBoxItem[] = [];
  endPeriodOptions: ComboBoxItem[] = [];
  startIndex: number = 0;
  endIndex: number = 1;
  startPeriod: string;
  endPeriod: string;

  dims: string[];
  dimsComboBoxInfo: ComboBoxInformation[] = [];
  dimsOptions = {};

  //@ViewChildren(MatOption) allSelected: QueryList<MatOption>;

  factsAttributes: object;
  factsArr = [];
  fact;

  factGroup: string = "driver";
  subRowGroup: string = "cur_year_ago_percent";

  value: Number;

  isHumanClick: Boolean = true;

  wasTouched: Boolean = false;

  lowestLevelPeriod: string = 'weekly';

  public largeScaleSimulationForm: FormGroup = new FormGroup({});


  ngOnInit(): void {

    var self = this;

    this.spinnerService.show();

    // get start end period combo info
    this.startPeriodInfo = this.data['timePeriodStart'];
    this.endPeriodInfo = this.data['timePeriodEnd'];

    // get lowest level
    const timePeriods = this.ssp.getSelectorById(SEL_TIMEPERIODM);
    timePeriods['order'].forEach(function(period) {
      if(timePeriods[period]['editable']=='Yes') {
        self.lowestLevelPeriod = period;
      }
    });

    const timeScale = this.lowestLevelPeriod; //this.data['timeScale']['active_key'];

    // get list of facts
    this.factsAttributes = this.ssp.getTableDetails(TBL_FORECAST, timeScale);

    // convert input dict to array
    for (let key in this.factsAttributes) {
      let value = this.factsAttributes[key];

      if(!value.hasOwnProperty('editable') || value['editable'] === "Yes") {
        this.factsArr.push({
          group: value['front_type'],
          key: key,
          label: value['name'],
        });
      }
    }

    this.factOptions = this.factsArr.filter(el => el.group === this.factGroup);
    this.factInfo.active_key = this.factOptions[0].key;
    this.fact = this.factInfo.active_key;

    const timeDim = this.ssp.getTimeDimension();
    const listOfTimePeriods = timeDim[timeScale + '_order'];

    if(this.data['timeScale']['active_key'] !== this.lowestLevelPeriod) {
      this.startIndex = 0;
      this.endIndex = listOfTimePeriods.length - 1;//1;
    }
    else {
      this.startIndex = listOfTimePeriods.findIndex((element) => element == this.startPeriodInfo.active_key);
      this.endIndex = listOfTimePeriods.findIndex((element) => element == this.endPeriodInfo.active_key);
    }

    this.getListOfOptionsForPeriods();

    if(this.data['timeScale']['active_key'] !== "weekly") {
      if(!this.startPeriod) this.startPeriod = this.startPeriodInfo.active_key= this.startPeriodOptions[0].key;
      if(!this.endPeriod) this.endPeriod = this.endPeriodInfo.active_key = this.endPeriodOptions[this.endPeriodOptions.length - 1].key;
    }
    else {
      if(!this.startPeriod) this.startPeriod = this.startPeriodInfo.active_key;
      if(!this.endPeriod) this.endPeriod = this.endPeriodInfo.active_key;
    }

    // get list of dimensions
    const nameDimension: NameDimensionObject = this.ssp.getNameDimension();

    const dimsAttributes = nameDimension.attributes;
    this.dims = nameDimension.name_labels;

    // generate list of elements
    this.dims.forEach(function(dim: string) {
      let comboBoxElement: ComboBoxInformation = {
        item_id: dim,
        label: dimsAttributes[dim]['name'],
      };
      self.dimsComboBoxInfo.push(comboBoxElement);

      // add dummy options
      //self.dimsOptions[dim] = [{key: "dummy", label: ""}];

      // add form controls
      self.largeScaleSimulationForm.addControl(dim, new FormControl('', [Validators.required]));
      self.largeScaleSimulationForm.controls[dim].setValue(['__all__']);

    });

    this.largeScaleSimulationForm.addControl("fact", new FormControl(this.factGroup, [Validators.required]));
    this.largeScaleSimulationForm.addControl("value", new FormControl('', [Validators.required]));

    /*this.largeScaleSimulationForm = new FormGroup({
      //name: new FormControl('', [Validators.required, Validators.maxLength(60)]),
      //fact: new FormControl('', [Validators.required]),
      value: new FormControl('', [Validators.required])
    });*/

    // hide spinner
    this.spinnerService.hide();

  }

  onFactGroupChanged(data) {
    this.factGroup = data.value;
    this.factOptions = this.factsArr.filter(el => el.group === this.factGroup);

    this.factInfo.active_key = this.factOptions[0].key;
    this.fact = this.factOptions[0].key;
  }

  onFactSelectorChanged(event) {
    this.fact = event;
  }

  onStartPeriodSelectorChanged(event) {
    this.startPeriod = event;
    this.startIndex = this.startPeriodOptions.findIndex((element) => element.key == event);
    this.getListOfOptionsForPeriods();
  }

  onEndPeriodSelectorChanged(event) {
    this.endPeriod = event;
    this.endIndex = this.endPeriodOptions.findIndex((element) => element.key == event);
    this.getListOfOptionsForPeriods();
  }

  onDimSelectorClick(event, dim) {
    let self = this;

    if(this.isHumanClick) {
      let selector: HTMLElement = event.target; //this.document.getElementById(event.srcElement.parentElement.id);
      //const selectedDimIndex = this.dims.findIndex((item) => item === dim);

      // generate entity key
      let entityValue = this.getEntityValueAll();

      // generate list of options
      let requestData = {};
      requestData[dim] = "";
      requestData['entity'] = entityValue;

      this.forecastService.getFilters(requestData).subscribe(result => {
        self.dimsOptions[dim] = result['values'].map(({name, isDisabled, ...rest}) => ({...rest, label: name, is_disabled: isDisabled}));

        // check select
        const isSelectedAll = this.largeScaleSimulationForm.controls[dim].value.indexOf('__all__') > -1;
        if (isSelectedAll) {
          self.largeScaleSimulationForm.controls[dim]
            .patchValue([...self.dimsOptions[dim].filter(item => !item.is_disabled).map(item =>  item.key), '__all__']);
        }

        self.isHumanClick = false;
        selector.click();
      });

    }
    else {
      self.isHumanClick = true;
    }
  }

  onInputKeyPress(event: KeyboardEvent) {
    if(event.key=='Enter') {
      event.preventDefault();
    }
  }

  onInputButtonClick() {
    let self = this;

    this.spinnerService.show();

    // generate entity key
    let entityValue = this.getEntityValueAll();

    // get list of selected dims
    let selectedDims = this.getListOfSelectedDims();

    const timeScale = this.lowestLevelPeriod; //this.data['timeScale']['active_key'];

    // generate request
    let requestData: SetDataInterface = {
      component: TBL_FORECAST,
      entity: entityValue,
      fact: this.fact,
      new_value: String(this.value),
      time: timeScale,
      start: this.startPeriod,
      end: this.endPeriod,
      sub_row: this.subRowGroup,
      selected_dims: selectedDims,
      option: ""
    }

    this.forecastService.setTheData(requestData).subscribe(
      result => {
        self.spinnerService.hide();
        self.wasTouched = true;
      },
      error => {
        self.spinnerService.hide();

        const data = {
          errorMsg: JSON.stringify(error),
        };
        self.dialog.open(ErrorDialogComponent, {data: data,});
      }
    );

  }

  onInputAndSimulateButtonClick() {
    let self = this;

    this.spinnerService.show('0%');

    // generate entity key
    let entityValue = this.getEntityValueAll();

    // get list of selected dims
    let selectedDims = this.getListOfSelectedDims();

    const timeScale = this.lowestLevelPeriod; //this.data['timeScale']['active_key'];

    // generate request
    let requestData: SetDataInterface = {
      component: TBL_FORECAST,
      entity: entityValue,
      fact: this.fact,
      new_value: String(this.value),
      time: timeScale,
      start: this.startPeriod,
      end: this.endPeriod,
      sub_row: this.subRowGroup,
      selected_dims: selectedDims,
      option: ""
    }

    this.forecastService.setTheData(requestData).subscribe(result => {
      self.forecastService.checkCounterUpdateData({'init':'No'}).pipe(
        concatMap((count: number) => {

          const arrayReq = [];
          for (let i = 0; i < count; i++) {
            arrayReq.push(

              from(
                this.forecastService.updateTheData({'init':'No'}).pipe(
                  switchMap(
                    res => {
                      this.spinnerService.updateMessage(Math.round(((i + 1)/count) * 100) + '%');

                      return of({});
                    }
                  ),
                  catchError((e) => {
                      self.spinnerService.hide();

                      const data = {
                        errorMsg: JSON.stringify(e),
                      };
                      //self.dialog.open(ErrorDialogComponent, {data: data,});

                      return of({});
                    }
                  )
                )
              )
            );
        }

        return concat(...arrayReq).pipe(toArray());

      }))
      .toPromise().then(() => {
          self.wasTouched = true;
          self.dialogRef.close({  wasTouched: this.wasTouched });
          self.spinnerService.hide();
        });
    });
  }

  onResetButtonClick() {
    var self = this;
    this.dims.forEach(dim => self.largeScaleSimulationForm.controls[dim].patchValue(['__all__']));
  }

  onDialogButtonCloseClick() {
    this.dialogRef.close({  wasTouched: this.wasTouched });
  }

  onToggleAllSelection(dim) {
    const isSelectedAll = this.largeScaleSimulationForm.controls[dim].value.indexOf('__all__') > -1;
    if (isSelectedAll) {
      this.largeScaleSimulationForm.controls[dim]
        .patchValue([...this.dimsOptions[dim].filter(item => !item.is_disabled).map(item => item.key ), '__all__']);
    } else {
      this.largeScaleSimulationForm.controls[dim].patchValue([]);
    }
  }

  onTosslePerOne(dim) {
    const isSelectedAll = this.largeScaleSimulationForm.controls[dim].value.indexOf('__all__') > -1;

    // deselect all
    if (isSelectedAll) {
      let values = this.largeScaleSimulationForm.controls[dim].value;
      const index = values.indexOf('__all__');
      if (index !== -1) {
        values.splice(index, 1);
      }
      this.largeScaleSimulationForm.controls[dim].patchValue(values);

      return false;
    }

    // select all
    if (this.largeScaleSimulationForm.controls[dim].value.length === this.dimsOptions[dim].filter(item => !item.is_disabled).length) {
      let values = this.largeScaleSimulationForm.controls[dim].value;
      values.push('__all__');
      this.largeScaleSimulationForm.controls[dim].patchValue(values);
    }
  }

  getEntityValueAll(): string[] {
    let entityValue=[], separator="~";
    this.dims.forEach(dim => {
      const isInitState = (this.largeScaleSimulationForm.controls[dim].value.indexOf('__all__') > -1) && this.largeScaleSimulationForm.controls[dim].value.length == 1;
      const isNotSelected = this.largeScaleSimulationForm.controls[dim].value.length == 0;
      const isAllSelected = this.largeScaleSimulationForm.controls[dim].value.indexOf('__all__') > -1;

      if(isInitState) {
        entityValue.push('__all__');
      }
      else if(isNotSelected) {
        entityValue.push('__all__');
      }
      else if(isAllSelected) {
        entityValue.push('__all__');
      }
      else {
        const dimMembers = (this.largeScaleSimulationForm.controls[dim].value.length === this.dimsOptions[dim].length) ? "__all__" : this.largeScaleSimulationForm.controls[dim].value.join(separator);
        entityValue.push(dimMembers);
      }
    });

    return entityValue;
  }

  getEntityValue(): string {
    let entityValue="", separator="~";
    for(let i = 0; i < this.dims.length; i++) {
      entityValue = entityValue + (this.dimsComboBoxInfo[i].active_key === undefined || this.dimsComboBoxInfo[i].active_key === null ? "" : this.dimsComboBoxInfo[i].active_key + separator);
    }
    if(entityValue.substring(entityValue.length-1, entityValue.length) === separator) {
      entityValue = entityValue.substring(0, entityValue.length-1);
    };

    return entityValue;
  }

  getListOfSelectedDims(): string[] {
    let selectedDims: string[] = [];

    this.dims.forEach(dim => {
      if(this.largeScaleSimulationForm.controls[dim].value.length) {
        selectedDims.push(dim);
      }
    })

    return selectedDims;
  }

  getListOfOptionsForPeriods() {
    // get list of options for start end dates
    const timeDim = this.ssp.getTimeDimension();
    const timeScale = this.lowestLevelPeriod; //this.data['timeScale']['active_key'];
    const listOfTimePeriods = timeDim[timeScale + '_order'];

    this.startPeriodOptions = [];
    this.endPeriodOptions = [];

    listOfTimePeriods.forEach(period => {
      const index = listOfTimePeriods.indexOf(period);
      this.startPeriodOptions.push(
        { key: period, label: timeDim[timeScale][period]['name'], is_disabled: (index > this.endIndex) || (timeDim[timeScale][period].hasOwnProperty('is_forecast') && timeDim[timeScale][period]['is_forecast']==false) }
      );
      this.endPeriodOptions.push(
        { key: period, label: timeDim[timeScale][period]['name'], is_disabled: (index < this.startIndex) || (timeDim[timeScale][period].hasOwnProperty('is_forecast') && timeDim[timeScale][period]['is_forecast']==false) }
      );
    });

  }

  getLabel(dim) {
    const countOfMembers: number = this.largeScaleSimulationForm.controls[dim].value.length;
    const dimLabels = this.dimsOptions[dim];

    if (countOfMembers == 0) {
      return '';
    }
    else if(countOfMembers == 1) {
      const item = dimLabels.find(x => x.key === this.largeScaleSimulationForm.controls[dim].value[0]);
      return item.label;
    }
    else if(countOfMembers == 2) {
      const item = dimLabels.find(x => x.key === this.largeScaleSimulationForm.controls[dim].value[0]);
      return item.label + ' (+1 other)';
    }
    else {
      const item = dimLabels.find(x => x.key === this.largeScaleSimulationForm.controls[dim].value[0]);
      return item.label + ' (+' + (countOfMembers-1).toString() + ' others)';
    }
  }

}
