import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActualizeFailDialogObject } from '../notification.model';

@Component({
  selector: 'app-actualize-fail-dialog',
  templateUrl: './actualize-fail-dialog.component.html',
  styleUrls: ['./actualize-fail-dialog.component.less']
})
export class ActualizeFailDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ActualizeFailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ActualizeFailDialogObject) { }

  ngOnInit() {
  }

  onLoadClick(): void {
    this.dialogRef.close(true);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
