import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationDialogComponent } from './notification-module/notification-dialog/notification-dialog.component';
import { NotificationDialogObject, NotificationType } from './notification-module/notification.model';
import { AppState, SetArrayForSelectInEffect, StateStructure} from './models/forecaster.model';
import { ChangeScenario, ShowSpinner, HideSpinner, ChangeArraySelector } from './state/foracaster.action';
import { Store } from '@ngrx/store';
import { AllScenariosModelBack, ChangeScenarioData } from './scenarios-module/scenarios.model';
import { SessionStorageService } from 'angular-web-storage';
import {
  BLOCKED_SCENARIOS_NAMES, FILTER_BASE_KEYS, INSTRUCTION, SCENARIOS, SEL_FILTERS, SEL_GENERAL, SEL_SCENARIO_CUR, SEL_TABLE, SIMULATOR_PAGE_KEY, STATE_BACK, UNDEFINED_ID  
} from './models/forecaster.constants';
import { ScopeInstruction, ScopeObj, UserScopeObj } from './users-management-module/users.model';
import { MultiselectorService } from '../../_components/multiselector.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class ScenariosService {
  navigateByLink = new Subject();
  link: string;
  constructor(private store: Store<AppState>,
    private sessionStorage: SessionStorageService,
    private router: Router,
    private multiService: MultiselectorService,
    private _snackBar: MatSnackBar) {
      this.navigateByLink.subscribe(() => {
        if (this.link) {
          this.router.navigate([this.link]);
        }
      });
    }

  getNotificationResponse(type: NotificationType, message: string, request, dialog: MatDialog): boolean {
    this.store.dispatch(HideSpinner());
    if (message === '' || type === undefined) {
      return;
    }
    const data: NotificationDialogObject = {
      type: type,
      message: message,
    };
    const dialogRef = dialog.open(NotificationDialogComponent, {
      data: data,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (type === 'confirmation' && result) {
        request['is_sure'] = 'Yes';
        this.store.dispatch(ShowSpinner());
        this.store.dispatch(ChangeScenario({ payload: request }));
        return true;
      }
    });
    return false;
  }

  getBlockedScenarioNames(): string[] {
    const scenarios: AllScenariosModelBack = this.sessionStorage.get(SCENARIOS);
    const blocked_from_storage = scenarios.order
      .filter((scenario_id: string) =>
        scenarios[scenario_id].is_initial === 'Yes' ||
        scenarios[scenario_id].is_consolidated === 'Yes' ||
        scenarios[scenario_id].is_locked === 'Yes' ||
        scenarios[scenario_id].is_shared === 'Yes' ||
        scenarios[scenario_id].entity !== undefined &&
        (scenarios[scenario_id].entity.final !== undefined && scenarios[scenario_id].entity.final.length > 0 ||
          scenarios[scenario_id].entity.prefinal !== undefined && scenarios[scenario_id].entity.prefinal.length > 0))
      .map(item => scenarios[item].name);
    let blocked_names: string[] = [...BLOCKED_SCENARIOS_NAMES, ...blocked_from_storage];
    blocked_names = blocked_names.map(item => item.toLowerCase());
    return blocked_names;
  }

  scenarioNameExists(scenario_name): boolean {
    const scenarios: AllScenariosModelBack = this.sessionStorage.get(SCENARIOS);
    const all_scenario_names: string[] = scenarios.order.map(scenario_id => scenarios[scenario_id].name.toLowerCase());
    const need_confirm = all_scenario_names.indexOf(scenario_name.toLowerCase()) !== -1;
    return need_confirm;
  }

  dispatchScenario(scenario_id: string, new_name: string, action: string, link?) {
    const self = this;
    const set_object: ChangeScenarioData = {
      is_sure: 'No',
      scenario: {}
    };

    let message = 'The scenario was ';
    switch (action) {
      case 'saveas':
        set_object[INSTRUCTION] = 'save'; set_object.scenario[UNDEFINED_ID] = { name: new_name };
        message = message + 'saved'; break;
      case 'save':
        set_object[INSTRUCTION] = 'save'; set_object.scenario[scenario_id] = {};
        message = message + 'saved'; break;
      case 'delete':
        set_object[INSTRUCTION] = 'delete'; set_object.scenario[scenario_id] = {};
        message = message + 'deleted'; break;
      case 'rename':
        set_object[INSTRUCTION] = 'rename'; set_object.scenario[scenario_id] = { name: new_name };
        message = message + 'renamed'; break;

    }
    this.store.dispatch(ShowSpinner());
    self.store.dispatch(ChangeScenario({ payload: set_object }));
    // this.store.dispatch(HideSpinner());
    self.openSnackBar(message);
    if (link) {
      this.link = link;
    }
  }

  openSnackBar(message) {
    this._snackBar.open(message, 'Ok', {
      duration: 2000,
      verticalPosition: 'bottom',
      horizontalPosition: 'center',
      panelClass: ['snackbar_green']
    });
  }

  isScenarioDisableForSave(scenario_id): boolean {
    const scenarios: AllScenariosModelBack = this.sessionStorage.get(SCENARIOS);
    const res: boolean =
      scenarios[scenario_id].is_saved === 'Yes' ||  
      scenarios[scenario_id].is_initial === 'Yes' ||
      scenarios[scenario_id].is_consolidated === 'Yes' ||
      scenarios[scenario_id].is_locked === 'Yes' ||
      scenarios[scenario_id].is_shared === 'Yes';
    return res;
  }

  isScenarioDisableForLargeScale(scenario_id): boolean {
    const scenarios: AllScenariosModelBack = this.sessionStorage.get(SCENARIOS);
    const res: boolean =
      scenarios[scenario_id].is_consolidated === 'Yes' ||
      scenarios[scenario_id].is_locked === 'Yes';
    return res;
  }

  isScenarioInitial(scenario_id): boolean {
    const scenarios: AllScenariosModelBack = this.sessionStorage.get(SCENARIOS);
    const res: boolean =
      scenarios[scenario_id].is_initial === 'Yes';
    return res;
  }

  isScenarioSaved(scenario_id): boolean {
    const scenarios: AllScenariosModelBack = this.sessionStorage.get(SCENARIOS);
    const res: boolean =
      scenarios[scenario_id].is_saved === 'Yes';
    return res;
  }

  isScenarioUpdated(scenario_id): boolean {
    const scenarios: AllScenariosModelBack = this.sessionStorage.get(SCENARIOS);
    const res: boolean =
      scenarios[scenario_id].is_updated === 'Yes';
    return res;
  }

  isScenarioDisableForSaveAs(scenario_id): boolean {
    const scenarios: AllScenariosModelBack = this.sessionStorage.get(SCENARIOS);
    const res = scenarios[scenario_id].is_locked === 'Yes';
    return res;
  }

  isScenarioDisableForActualization(scenario_id): boolean {
    const scenarios: AllScenariosModelBack = this.sessionStorage.get(SCENARIOS);
    const res = scenarios[scenario_id].is_actualized === 'Yes';
    return res;
  }

  getFullUserScopeObject(return_array: [], user_list: string[]): UserScopeObj[] {
    let scope_list: UserScopeObj[] = []
    return_array.forEach(item => {
      const scope_obj: ScopeObj = this.getScopeObject(item["entity_list"]);
      const user_scope_obj: UserScopeObj = {
        users: user_list,
        instruction: item["instruction"],
        entity: scope_obj,
      };
      scope_list.push(user_scope_obj);
    })
    return scope_list;
  }

  getUserScopeObject(entity_list: string[], user_list: string[], instruction: ScopeInstruction): UserScopeObj[] {
    const scope_obj: ScopeObj = this.getScopeObject(entity_list);
    const user_scope_obj: UserScopeObj = {
      users: user_list,
      instruction: instruction,
      entity: scope_obj,
    };
    const scope_list: UserScopeObj[] = [user_scope_obj];
    return scope_list;
  }

  getScopeObject(entity_list: string[]): ScopeObj {
    const scope_obj: ScopeObj = {
      edit: entity_list,
      review: []
    };
    return scope_obj;
  }
  
  // ------------------------------------------
  correctFilterBase(main_selector: string[], state_b: StateStructure,  name_labels: string[], page: string) {

    let filter_base_keys = state_b[page][SEL_TABLE][FILTER_BASE_KEYS];
    let filter_base = state_b[page][SEL_FILTERS];

    const filter_base_keys_old = [...filter_base_keys];

    // filter_base_keys = filter_base_keys_old.filter(col_key => {
    name_labels.filter(col_key => filter_base[col_key].length > 0).forEach(col_key => {
      const is_in_main_selector = this.multiService.isItemInArray(main_selector, col_key);
      const was_in_filter_base_keys = this.multiService.isItemInArray(filter_base_keys, col_key);
      if (!(is_in_main_selector && was_in_filter_base_keys)) {
        const set_data: SetArrayForSelectInEffect = {
          page: page,
          component: SEL_FILTERS,
          sub_component: col_key,
          new_value: [],
        };
        this.store.dispatch(ChangeArraySelector({ payload: set_data }));
        
        filter_base[col_key] = [];
        if (this.multiService.isItemInArray(filter_base_keys, col_key)) {
          filter_base_keys = filter_base_keys.filter(key => key != col_key)
        }
      }
      // return rez;
    });
    // const new_keys = main_selector.filter(key => !this.multiService.isItemInArray(filter_base_keys, key) && filter_base[key].length > 0);
    // filter_base_keys = filter_base_keys.concat(new_keys);

    if (!this.multiService.isTheSameArray2Array(filter_base_keys, filter_base_keys_old)) {
      const set_data_keys: SetArrayForSelectInEffect = {
        page: page,
        component: SEL_TABLE,
        sub_component: FILTER_BASE_KEYS,
        new_value: filter_base_keys,
      };
      this.store.dispatch(ChangeArraySelector({ payload: set_data_keys }));
    }
    return filter_base;
  }  
}
