
export const environment = {
    production: false,
    client: 'FRTO',
    //apiUrl: "http://localhost:1111/api/",
    apiUrl: "/api/",
    exclusionsFeature: false,
    subtotalFeature: true,
    totalFeature: true,
    waterfallAggFeature: true,
    reportFeature: true,
    exportDataFeature: false,
    sso: false,
};
  