<div mat-dialog-title class="flex-container">
    <h1 mat-dialog-title class="title">{{title}}</h1>
    <button mat-button class="close-icon" (click)="onDialogButtonCloseClick()">
        <mat-icon>close</mat-icon>
    </button>
</div>

<div mat-dialog-content>
    <form class="main-report-form" [formGroup]="mainReportForm">
        <section class="section">
            <table>
                <td>
                    <tr>
                        <mat-radio-group class="report-radio-group" formControlName="scope">
                            <mat-radio-button class="report-radio-button" *ngFor="let order of selReportType.order" [value]="order">{{ selReportType[order].name }}</mat-radio-button>
                        </mat-radio-group>
                    </tr>
                    <tr>
                        <mat-form-field>
                            <mat-label class="my-label">Scenario</mat-label>
                            <mat-select 
                                [disableOptionCentering]="true"
                                panelClass="my-panel"
                                disableRipple
                                multiple
                                formControlName="scenario"
                                name="scenario">

                                <mat-select-trigger>
                                    {{ getLabel() }}
                                </mat-select-trigger>

                                <mat-option *ngFor="let item of scenarioOptions, let i = index"
                                            [disabled]="!isOptionSelected(item.key) && mainReportForm.controls['scenario'].value?.length >= 3"
                                            [value]="item.key"
                                            class="not-empty-select">
                                        {{item.label}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </tr>
                    <tr>
                        <div class="buttons-panel">
                            <button mat-raised-button color="basic" class="input-button" (click)="onDialogButtonCloseClick()">Cancel</button>
                            <button mat-raised-button color="primary" class="input-button" (click)="onGenerate()" [disabled]="!mainReportForm.valid">Generate</button>
                        </div>
                    </tr>
                </td>
            </table>
        </section>
    </form>
</div>