<div class="wrapper">
  <div class="inner-block">
    <div class="image">
    </div>
    <h1>
      The page session error
    </h1>
    <div class="small-text">
      Please return to tab with active session in your browser
    </div>
  </div>
</div>
