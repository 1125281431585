import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { LoginGuard } from './pages/login/login.guard';
import { SessionErrorComponent } from './pages/session-error/session-error.component';
import { NoScopeComponent } from './pages/no-scope/no-scope.component';
import { PowerbiFrameComponent } from './pages/powerbi-frame/powerbi-frame.component';
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';

import { environment } from 'environments/environment'

const routes: Routes = environment.sso ? 
  [
    { path: 'callback', component: OktaCallbackComponent, },
    {
      path: '',
      redirectTo: 'home', //'/login',
      pathMatch: 'full',
    },
    /*{
      path: 'login',
      loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
      canActivate: [OktaAuthGuard]
    },*/
    {
      path: 'home',
      loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
      //canActivate: [LoginGuard]
      canActivate: [OktaAuthGuard]
    },
    {
      path: 'forecaster',
      loadChildren: () => import('./pages/forecaster/forecaster.module').then(m => m.ForecasterModule),
      //canActivate: [LoginGuard]
      canActivate: [OktaAuthGuard]
    },
    {
      path: 'no-scope',
      component: NoScopeComponent
    },
    {
      path: 'powerbi-frame',
      component: PowerbiFrameComponent
    },
    {
      path: 'session-error',
      component: SessionErrorComponent
    },
    {
      path: '404',
      component: NotFoundComponent
    },
    {
      path: '**',
      redirectTo: '/404'
    },
  ]
:
 [
    {
      path: '',
      redirectTo: '/login',
      pathMatch: 'full',
    },
    {
      path: 'login',
      loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
    },
    {
      path: 'home',
      loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
      canActivate: [LoginGuard]
    },
    {
      path: 'forecaster',
      loadChildren: () => import('./pages/forecaster/forecaster.module').then(m => m.ForecasterModule),
      canActivate: [LoginGuard]
    },
    {
      path: 'no-scope',
      component: NoScopeComponent
    },
    {
      path: 'powerbi-frame',
      component: PowerbiFrameComponent
    },
    {
      path: 'session-error',
      component: SessionErrorComponent
    },
    {
      path: '404',
      component: NotFoundComponent
    },
    {
      path: '**',
      redirectTo: '/404'
    },
  ]
;

@NgModule({
  declarations: [NotFoundComponent, SessionErrorComponent],
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes, { useHash: true })]
})
export class AppRoutingModule {
}
