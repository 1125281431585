import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, Injectable } from '@angular/core';
import { SpinnerOverlayComponent } from '../components/spinner-overlay/spinner-overlay.component';

@Injectable({
  providedIn: 'root'
})
export class SpinnerOverlayService {
  private overlayRef: OverlayRef = null;
  private component: ComponentRef<SpinnerOverlayComponent> = null;

  constructor(private overlay: Overlay) {}

  public show(message = '') {
    if (this.check()) {
       return;
    }
    // Returns an OverlayRef (which is a PortalHost)
    
    if (!this.overlayRef) {
      this.overlayRef = this.overlay.create();
    }

    // Create ComponentPortal that can be attached to a PortalHost
    const spinnerOverlayPortal = new ComponentPortal(SpinnerOverlayComponent);
    this.component = this.overlayRef.attach(spinnerOverlayPortal); // Attach ComponentPortal to PortalHost

    (this.component.instance as any).message = message;
  }

  public hide() {
    if (this.check()) {
      this.overlayRef.detach();
      this.overlayRef = null;
    }
  }

  public check(): boolean {
    return !!this.overlayRef;
  }

  public updateMessage(updatedMessage) {
    (this.component.instance as any).message = updatedMessage;
  }
}
