import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { ComboBoxInformation, ComboBoxItem } from '@app/_components/model/common.interfaces';
import { commonConstants } from '@app/consts/common';

import { ForecasterService } from '../../../forecaster.service';
import { SessionStorageProviderService } from '../../../session-storage-provider.service';
import { SEL_GENERAL, SEL_SCN_DEFAULT_NAME, SEL_TAG, SCENARIOS_KEY, STATE_BACK } from '@app/pages/forecaster/models/forecaster.constants';
import { SessionStorageService } from 'angular-web-storage';

@Component({
  selector: 'app-actualization-update-dialog',
  templateUrl: './actualization-update-dialog.component.html',
  styleUrls: ['./actualization-update-dialog.component.less']
})
export class ActualizationUpdateDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ActualizationUpdateDialogComponent>,
    private dialog: MatDialog,
    private forecastService: ForecasterService,
    private sessionStorage: SessionStorageService,
    private sessionStorageProviderService: SessionStorageProviderService,
    @Inject(MAT_DIALOG_DATA) public data,
    @Inject(DOCUMENT) private document: Document,    
  ) { }

  title: string = '';
  selectedBy: string = 'create';

  public actualizationUpdateForm: FormGroup = new FormGroup({});

  action: String;
  commonConstants;

  ngOnInit(): void {
    this.title = this.sessionStorageProviderService.getSelectorById(SEL_SCN_DEFAULT_NAME).initial.name;
    const sel_tag: string = this.sessionStorage.get(STATE_BACK)[SCENARIOS_KEY][SEL_GENERAL][SEL_TAG];

    // define form controls
    this.actualizationUpdateForm.addControl("edit_tag", new FormControl(sel_tag, []));
    this.actualizationUpdateForm.addControl("set_tag", new FormControl('', []));
    this.actualizationUpdateForm.addControl("consolidatedAsActual", new FormControl('', [Validators.required]));

    // set default values
    this.actualizationUpdateForm.controls['consolidatedAsActual'].setValue(false);

    this.action = this.data.action;
    this.commonConstants = commonConstants;
  }

  selectType(event) {
    this.selectedBy = event.target.value;
    if (this.selectedBy === 'edit') {
      this.actualizationUpdateForm.controls.set_tag.disable();
      this.actualizationUpdateForm.controls.consolidatedAsActual.disable();
    } else {
      this.actualizationUpdateForm.controls.set_tag.enable();
      this.actualizationUpdateForm.controls.consolidatedAsActual.enable();
    }
  }

  onDialogButtonCloseClick() {
    this.dialogRef.close();
  }

  onOkClick(event) {
    const data = {
      tag: this.selectedBy === 'edit' ? 'skip_tag_update' : this.actualizationUpdateForm.controls['set_tag'].value,
      edit_tag: this.actualizationUpdateForm.controls['edit_tag'].value,
      consolidatedAsActual: this.actualizationUpdateForm.controls['consolidatedAsActual'].value,
      response: 'OK',
      fileList: FileList = event.target.files
    }
    this.dialogRef.close(data);
  }

  // onNextClick() {
  //   const data = {
  //     tag: this.actualizationUpdateForm.controls['tag'].value,
  //     consolidatedAsActual: this.actualizationUpdateForm.controls['consolidatedAsActual'].value,
  //     response: 'NEXT',
  //   }
  //   this.dialogRef.close(data);
  // }

  // onSkipClick() {
  //   const data = {
  //     tag: this.actualizationUpdateForm.controls['tag'].value,
  //     consolidatedAsActual: this.actualizationUpdateForm.controls['consolidatedAsActual'].value,
  //     response: 'SKIP',
  //   }
  //   this.dialogRef.close(data);
  // }

  // onSkipClickFile(event) {
  //   const data = {
  //     tag: this.actualizationUpdateForm.controls['tag'].value,
  //     consolidatedAsActual: this.actualizationUpdateForm.controls['consolidatedAsActual'].value,
  //     response: 'SKIP',
  //     fileList: FileList = event.target.files
  //   }
  //   this.dialogRef.close(data);
  // }

}
