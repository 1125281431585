import { createAction, props } from '@ngrx/store';
import { SimulatorState, SimulatorTotalsState } from './simulator.model';

export const getAllSimulatorData = createAction(
  'Do all simulator requests',
  props<SimulatorState>()
);

export const getFactData = createAction(
  'Get data for fact after set_data_point',
  props<{ payload: Object }>()
);

export const getSubtotalData = createAction(
  'Get data for subtotal after main_selector_filters changing',
  props<{ payload: Object }>()
);

export const getTotalsData = createAction(
  'Get data for totals after main_selector_filters changing',
  props<{ payload: SimulatorTotalsState }>()
);

export const getScenarioInfo = createAction(
  'Get updated scenario information',
  props<{ payload: Object }>()
);