export interface LoginData {
  token: string;
  has_scope: boolean;
}
export interface LoginCredentials {
  email: string;
  password: string;
  otp: number;
}

export const NO_SCOPE_URL = '/no-scope';
