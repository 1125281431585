import { Inject, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';

import { LoginService } from '@app/pages/login/login.service';
import { environment } from 'environments/environment';
import { catchError } from 'rxjs/operators';
import { SpinnerOverlayService } from './shared/services/spinner-overlay.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth,
    private spinnerService: SpinnerOverlayService,
    private loginService: LoginService,
    ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(this.addAuthHeaderToAllowedOrigins(request)).pipe(
      catchError(() => {
        this.spinnerService.hide();
        return throwError(() => new Error('The Error'));
      })
    );
  }

  private addAuthHeaderToAllowedOrigins(request: HttpRequest<unknown>): HttpRequest<unknown> {
    let req = request;
    /*const allowedOrigins = ['http://localhost'];
    if(!!allowedOrigins.find(origin => request.url.includes(origin))) {
      const authToken = this._oktaAuth.getAccessToken();
      req = request.clone({ setHeaders: { 'Authorization': `Bearer ${authToken}` } });
    }*/

    const authToken = environment.sso ? this._oktaAuth.getAccessToken() : this.loginService.getToken();
    req = request.clone({ setHeaders: { 'Authorization': `Bearer ${authToken}` } });

    return req;
  }
}
