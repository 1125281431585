import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ScenarioNameDialogObject } from '../../../../pages/forecaster/scenarios-module/scenarios.model';
import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import { Observable } from 'rxjs';
import { ComboBoxInformation, ComboBoxItem, ReturnInterface } from '@app/_components/model/common.interfaces';
import { map } from 'rxjs/operators';

function forbiddenScenarioNameValidator(blocked_scenarios: string[]) {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const new_name = control.value.toLowerCase();
    const forbidden = blocked_scenarios.indexOf(new_name) !== -1;
    return forbidden ? { 'forbiddenScenarioName': { value: control.value } } : null;
  };
}
@Component({
  selector: 'app-scenario-name-dialog',
  templateUrl: './scenario-name-dialog.component.html',
  styleUrls: ['./scenario-name-dialog.component.less']
})
export class ScenarioNameDialogComponent implements OnInit {

  selScenarioItems$: Observable<ComboBoxItem[]>;
  selScenarioCurrentInfo: ComboBoxInformation;
  selectedScenario: ReturnInterface;
  myForm: FormGroup;

  constructor(public dialogRef: MatDialogRef<ScenarioNameDialogComponent>,
    // public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: ScenarioNameDialogObject,
    public fb: FormBuilder) {
    // this.myForm = fb.group({
    //   name: [data.scenario_name, [
    //     Validators.required,
    //     Validators.minLength(4),
    //     // "^(?=.{4,15}$)(?![-_])(?!.*[-_]{2})[a-zA-Z0-9-_]+(?<![-_])$"),
    //     // "^(?=[a-zA-Z0-9._]{4,15}$)(?!.*[_.]{2})[^_.].*[^_.]$"),
    //     Validators.pattern('^(?![-_. ])(?!.*[-_. ]{2})[a-zA-Z0-9-_. ]+(?<![-_.])$'),
    //     forbiddenScenarioNameValidator(data.blocked_names),
    //   ]
    //   ],
    // });
  }
  @ViewChild('btn') btn;

  ngOnInit() {
    this.myForm = this.fb.group({
      name: [this.data.scenario_name, [
        Validators.required,
        Validators.minLength(4),
        // "^(?=.{4,15}$)(?![-_])(?!.*[-_]{2})[a-zA-Z0-9-_]+(?<![-_])$"),
        // "^(?=[a-zA-Z0-9._]{4,15}$)(?!.*[_.]{2})[^_.].*[^_.]$"),
        Validators.pattern('^(?![-_. ])(?!.*[-_. ]{2})[a-zA-Z0-9-_. ]+(?<![-_.])$'),
        forbiddenScenarioNameValidator(this.data.blocked_names),
      ]
      ],
    });
    if (this.data.selScenarioCurrentInfo$) {
      this.data.selScenarioCurrentInfo$.subscribe((res) => {
        this.selScenarioCurrentInfo = {
          active_key: res.active_key,
          item_id: res.item_id,
          label: "Create on the base of"
        };
        this.selectedScenario = {
          item_id: res.item_id,
          new_value: res.active_key
        };
      })
    }
    
    this.selScenarioItems$ = this.data.selScenarioItems$;
  }

  get f() { return this.myForm.controls; }

  onInputClick(event) { 
    const html_input = event.target as HTMLInputElement;
    if (this.myForm.invalid)
      return;
    this.btn.focus();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getNewData() {
    return this.data.isSelector ? {...this.myForm.getRawValue(), selectedScenario: this.selectedScenario} : this.myForm.getRawValue();
  }

  onCurScenarioChanged(new_value: string) {
    this.selectedScenario.new_value = new_value;
  }
}
