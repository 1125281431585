import { BLK_CHARTS, BLK_DECOMPOSITION, SEL_METRIC_DECOMP, SEL_METRIC_DECOMP_ALLYEARS, SEL_SHORT_DECOMP } from '../models/forecaster.constants';
import { createReducer, on } from '@ngrx/store';

import {
  ChangeAllReqArraySelector,
  ChangeAllReqSelector,
  ChangeArraySelector, ChangeMainSelector, ChangeMainSelectorFilters,
  ChangeNoEffectSelector,
  ChangeNoReqSelector,
  ChangePageForecast,
  ChangePage, ChangeScenario, ChangeTimePoints, ChangeTimeScale, ClearState,
  HideSpinner,
  InitState,
  ResetAllData,
  SetArraySelectors,
  SetDataPoint,
  SetGeneralData, SetNewScenarioSelector,
  SetState, ShowSpinner,
  UpdateAllData,
  ChangeArraySelectors,
} from './foracaster.action';
import { SetArrayForSelectInEffect, SetDataForSelectInEffect, StateStructure } from '../models/forecaster.model';
import { SessionStorageService } from 'angular-web-storage';
import {
  FACTS, FILTER_BASE_KEYS,
  FORECASTER_PAGE, MANAGE_SCOPE_KEY, SEL_COLLAPSES, SEL_FILTERS,
  SEL_SORTS, SEL_TABLE, SIMULATOR_PAGE_KEY,
  STATE_BACK, USERS
} from '../models/forecaster.constants';
import { ForecasterActions } from './action-types';


export const STATE_STRUCTURE: StateStructure = {
  scenarios_management: {},
  user_manager_page: {},
  simulator_page: {},
  manage_scope: {},
  forecaster_page: '',
  loader_on: false
};
const sessionStorage: SessionStorageService = new SessionStorageService();

const _mainReducer = createReducer(
  STATE_STRUCTURE,

  on(InitState, state => {
    return state;
  }),
  on(SetGeneralData, (state, prop) => {
    for (const item in prop) {
      sessionStorage.set(item, prop[item]);
    }
    return state;
  }),
  on(SetState, (state, prop) => {
    state = prop.payload;
    sessionStorage.set(STATE_BACK, state);
    return { ...state };
  }),

  on(ChangePage, (state, prop) => {
    // state[FORECASTER_PAGE] = prop.page;
    // sessionStorage.set(STATE_BACK, state);
    sessionStorage.set(STATE_BACK, { ...state, [FORECASTER_PAGE]:prop.page});

    return { ...state, [FORECASTER_PAGE]:prop.page};
  }),
  on(ChangePageForecast, (state, prop) => {
    sessionStorage.set(STATE_BACK, { ...state, [FORECASTER_PAGE]:prop.page});
    return { ...state, [FORECASTER_PAGE]:prop.page};
  }),
  on(ChangeMainSelector, (state, prop) => {
    const payload = prop.payload;
    const page = payload.page;
    state[page][payload.component][payload.sub_component] = payload.new_value;
    const main_selector = payload['new_value'];
    // if (main_selector.length === 0) { 
    //   state[page][SEL_TABLE][FILTER_BASE_KEYS] = [];
    // }
    if (page === SIMULATOR_PAGE_KEY) {
      main_selector.forEach(id => {
        // state[page][SEL_FILTERS][id] = [];
        state[page][SEL_COLLAPSES][id] = [];
      });
      // state[page][SEL_TABLE][FACTS] = [];
      state[page][SEL_COLLAPSES][FACTS] = [];
      // state[page][SEL_TABLE][FILTER_BASE_KEYS] = [];
      const sort_cols = [...Array(main_selector.length).fill('asc')];
      sort_cols.push('default');
      sort_cols.push('default');
      state[page][SEL_TABLE][SEL_SORTS] = sort_cols;
      state[page][BLK_CHARTS][SEL_FILTERS] = [];
    }
    if (page === MANAGE_SCOPE_KEY) {
      // main_selector.forEach(id => {
      //   state[page][SEL_FILTERS][id] = [];
      // });
      // state[page][SEL_TABLE][USERS] = [];
      // state[page][SEL_TABLE][FILTER_BASE_KEYS] = [];
      const sort_cols = [...Array(main_selector.length).fill('asc')];
      sort_cols.push('default');
      sort_cols.push('default');
      state[page][SEL_TABLE][SEL_SORTS] = sort_cols;
    }

    sessionStorage.set(STATE_BACK, state);
    return { ...state };
  }),
  on(ChangeAllReqArraySelector, (state, prop) => {
    const payload = prop.payload;
    state[payload.page][payload.component][payload.sub_component] = payload.new_value;
    sessionStorage.set(STATE_BACK, state);
    return { ...state };
  }),
  on(ChangeArraySelector, (state, prop) => {
    const payload = prop.payload;
    state[payload.page][payload.component][payload.sub_component] = payload.new_value;
    sessionStorage.set(STATE_BACK, state);
    return { ...state };
  }),
  on(ChangeArraySelectors, (state, prop) => {
    const payloads = prop.payload;
    payloads.forEach(payload => {
      state[payload.page][payload.component][payload.sub_component] = payload.new_value;
    });
    sessionStorage.set(STATE_BACK, state);
    return { ...state };
  }),
  on(ChangeMainSelectorFilters, (state, prop) => {
    const payload = prop.payload;
    state[payload.page][payload.component][payload.sub_component] = payload.new_value;
    sessionStorage.set(STATE_BACK, state);
    return { ...state };
  }),
  on(SetArraySelectors, (state, prop) => {
    const array_payloads: SetArrayForSelectInEffect[] = prop.payload;
    array_payloads.forEach(item => {
      if (state[item.page][item.component] === undefined) {
        state[item.page][item.component] = {};
      }
      state[item.page][item.component][item.sub_component] = item.new_value;
    });
    sessionStorage.set(STATE_BACK, state);
    return { ...state };
  }),
  on(ChangeNoReqSelector, (state, prop) => {
    const payload: any = prop['payload'];
    if (payload.length >= 0) {
      payload.forEach((el: SetDataForSelectInEffect) => {
        state[el['page']][el['component']][el['sub_component']] = el['new_value'];
      });
    } else {
      state[payload['page']][payload['component']][payload['sub_component']] = payload['new_value'];
    }
    sessionStorage.set(STATE_BACK, state);
    return { ...state };
  }),
  on(ChangeAllReqSelector, (state, prop) => {
    const payload = prop['payload'];
    state[payload['page']][payload['component']][payload['sub_component']] = payload['new_value'];
    if(payload.page == SIMULATOR_PAGE_KEY && 
      payload.component == BLK_DECOMPOSITION && 
      (payload.sub_component == SEL_METRIC_DECOMP_ALLYEARS || payload.sub_component == SEL_METRIC_DECOMP ) && 
      payload.new_value != 'value'
    ) {
      state[payload['page']][payload['component']][SEL_SHORT_DECOMP] = "No";
    }
    sessionStorage.set(STATE_BACK, state);
    return { ...state };
  }),
  on(ChangeNoEffectSelector, (state, prop) => {
    const payload = prop['payload'];
    state[payload['page']][payload['component']][payload['sub_component']] = payload['new_value'];
    sessionStorage.set(STATE_BACK, state);
    return state;
  }),

  on(ShowSpinner, (state) => {
    // state.loader_on = true;
    return { ...state, loader_on: true };
  }),
  on(HideSpinner, (state) => {
    // state.loader_on = false;
    return { ...state, loader_on: false };
  }),
  on(ClearState, (state) => {
    state = undefined;
    return state;
  }),

  //  SIMULATOR

  on(ChangeTimeScale, (state, prop) => {
    const payload = prop['payload'];
    state[payload['page']][payload['component']][payload['sub_component']] = payload['new_value'];
    return state;
  }),
  on(ChangeTimePoints, (state, prop) => {
    const start = prop['payload'][0];
    const end = prop['payload'][1];
    state[start['page']][start['component']][start['sub_component']] = start['new_value'];
    state[end['page']][end['component']][end['sub_component']] = end['new_value'];
    sessionStorage.set(STATE_BACK, state);
    return { ...state };
  }),
  on(SetDataPoint, (state) => {
    return state;
  }),
  on(UpdateAllData, (state) => {
    return state;
  }),
  on(ResetAllData, (state) => {
    return state;
  }),

  //  SCENARIOS

  on(ChangeScenario, (state, props) => {
    return state;
  }),
  on(SetNewScenarioSelector, (state) => {
    return state;
  }),

  //  USERS
  // TODO remove this
  on(ForecasterActions.ChangeChbMyScopeFilter,
    (state, props) => {
      state[props.payload.page][props.payload.component][props.payload.sub_component] = props.payload.new_value;
      sessionStorage.set(STATE_BACK, state);
      return { ...state };
    }),

);

export function ForecasterReducer(state, action) {

  return _mainReducer(state, action);
}



export function clearState(reducer) {
  return function (state, action) {

    if (action.type === ForecasterActions.ActionTypes.LOGOUT) {
      state = undefined;
    }

    return reducer(state, action);
  };
}


// on(UpdateMultiSelector, (state, prop) => {
//   state[state.forecaster_page][SEL_MULTISELECT] = prop.payload.entities;
//   if (prop.payload.update_up_level) { //  up level needs update
//     state[state.forecaster_page][SEL_MULTISELECT_UP_LEVEL] = prop.payload.entities;
//   }
//   sessionStorage.set(STATE_BACK, state);
//   return { ...state };
// }),

// on(ChangeMultiSelector, (state, prop) => {
//   const page_id = state[FORECASTER_PAGE];
//   const multi_table = prop['payload'][page_id];
//   state[page_id][SEL_MULTISELECT] = multi_table;
//   if (prop.is_apply) { // uplevel_needs_update
//     state[page_id][SEL_MULTISELECT_UP_LEVEL] = multi_table;
//   }
//   sessionStorage.set(STATE_BACK, state);
//   return { ...state };
// }),


// on(DeleteScenario, (state, prop) => {
//   if (prop['id'] === sessionStorage.get(STATE_BACK)[SIMULATOR_PAGE_KEY][SEL_GENERAL][SEL_SCENARIO_BASE]) {
//     const upd_state = sessionStorage.get(STATE_BACK);
//     upd_state[SIMULATOR_PAGE_KEY][SEL_GENERAL][SEL_SCENARIO_BASE] = INITIAL_SCENARIO_ID;
//     sessionStorage.set(STATE_BACK, upd_state);
//     state = upd_state;
//   }
//   if (prop['id'] === sessionStorage.get(STATE_BACK)[SIMULATOR_PAGE_KEY][SEL_GENERAL][SEL_SCENARIO_CUR]) {
//     const upd_state = sessionStorage.get(STATE_BACK);
//     upd_state[SIMULATOR_PAGE_KEY][SEL_GENERAL][SEL_SCENARIO_CUR] = INITIAL_SCENARIO_ID;
//     sessionStorage.set(STATE_BACK, upd_state);
//     state = upd_state;
//   }
//   return state;
// }),

// on(SetNewScenarioSelectorOld, (state) => {
//   return state;
// }),

// on(SaveAsScenario, (state) => {
//   return state;
// }),
// on(SaveScenario, (state) => {
//   return state;
// }),

// on(UpdateScenarioList, (state) => {
//   return state;
// }),


// on(UpdateScenario, (state) => {
//   return state;
// }),


// on(ApplyManageLikeItems, (state) => {
//   return state;
// }),

// on(GetDataForFailedSet, state => {
//   return state;
// }),
