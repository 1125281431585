import { Component, OnInit, Inject } from '@angular/core';
import { ViewChild } from '@angular/core';
import { MatSelectionList } from '@angular/material/list';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { combineLatest, Observable, of, forkJoin } from 'rxjs';
import { SEL_GENERAL, SEL_NAME_LABEL, SIMULATOR_PAGE_KEY, STATE_BACK } from '@app/pages/forecaster/models/forecaster.constants';
import { StateStructure } from '@app/pages/forecaster/models/forecaster.model';
import { SessionStorageProviderService } from '@app/pages/forecaster/session-storage-provider.service';
import { SessionStorageService } from 'angular-web-storage';

@Component({
  selector: 'app-scope-selector',
  templateUrl: './scope-selector.component.html',
  styleUrls: ['./scope-selector.component.less']
})
export class ScopeSelectorComponent implements OnInit {

  @ViewChild('availableList', { static: false }) availableList: MatSelectionList;
  @ViewChild('selectedList', { static: false }) selectedList: MatSelectionList;

  anySelectedScope$: Observable<string[]>;

  allDimensions: string[] = [];
  availableDimensions: string[] = [];
  selectedDimensions: string[] = [];

  attributes;

  title = "Change Scope";

  constructor(
    public dialogRef: MatDialogRef<ScopeSelectorComponent>,
    private sessionStorageProvider: SessionStorageProviderService,
    private sessionStorage: SessionStorageService,
    @Inject(MAT_DIALOG_DATA) public data,
  ) { }

  ngOnInit(): void {

    // Get state
    const state_b: StateStructure = this.sessionStorage.get(STATE_BACK);

    // Get attributes
    this.attributes = this.sessionStorageProvider.getNameDimensionAttributes();

    // Get list of all exists dimensions
    this.allDimensions = this.sessionStorageProvider.getNameLabels();

    // Get selected dimensions
    this.selectedDimensions = state_b[this.data['page']][SEL_NAME_LABEL][SEL_GENERAL];

    // Calculate available dimensions
    this.availableDimensions = this.allDimensions.filter(dimension => !this.selectedDimensions.includes(dimension));

  }

  onDialogButtonCloseClick() {
    this.dialogRef.close({ });
  }

  onDialogButtonApplyClick() {
    this.dialogRef.close({ data: this.selectedDimensions });
  }

  moveToSelected() {
    const selected = this.availableList.selectedOptions.selected.map(item => item.value);
    this.availableDimensions = this.availableDimensions.filter(item => !selected.includes(item));
    this.selectedDimensions = [...this.selectedDimensions, ...selected];
  }

  moveToAvailable() {
    const selected = this.selectedList.selectedOptions.selected.map(item => item.value);
    this.selectedDimensions = this.selectedDimensions.filter(item => !selected.includes(item));
    this.availableDimensions = [...this.availableDimensions, ...selected];
  }

  moveAllToSelected() {
    this.selectedDimensions = [...this.allDimensions];
    this.availableDimensions = [];
  }

  moveAllToAvailable() {
    this.availableDimensions = [...this.allDimensions];
    this.selectedDimensions = [];
  }

  moveUp() {
    const selected = Array.from(this.selectedList.options)
      .filter(option => option.selected)
      .map(item => item.value);
    for (const item of selected) {
      const index = this.selectedDimensions.indexOf(item);
      if (index > 0) {
        this.selectedDimensions.splice(index, 1);
        this.selectedDimensions.splice(index - 1, 0, item);
      }
    }
  }
  
  moveDown() {
    const selected = Array.from(this.selectedList.options)
      .filter(option => option.selected)
      .map(item => item.value)
      .reverse();
    for (const item of selected) {
      const index = this.selectedDimensions.indexOf(item);
      if (index < this.selectedDimensions.length - 1) {
        this.selectedDimensions.splice(index, 1);
        this.selectedDimensions.splice(index + 1, 0, item);
      }
    }
  }  

  isApplyButtonDisabled() {
    return this.selectedDimensions.length === 0;
  }

  getLabel(dim) {
    return this.attributes[dim]['name'];
  }

}
