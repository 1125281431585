import { createAction, props } from '@ngrx/store';
import { AllUsersModel, UserScopeObj, UserParametersObj } from '../users-management-module/users.model';

export const getUsers = createAction(
  '[ChangePage] Get users',
  props<{ all_users: AllUsersModel }>()
);

export const createUserFromScope = createAction(
  'Create User from Scope',
  props<{ user: UserParametersObj  }>()
);

export const changeUsersScopeFromScope = createAction(
  'Update Users Scope from Scope',
  props<{ data: UserScopeObj[] }>()
);
