import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import * as FileSaver from 'file-saver';

import { AppState } from '@app/pages/forecaster/models/forecaster.model';
import {
  GetFactDataInterface,
  MultiSelectorSetter, SetArrayForSelectInEffect, SetDataForSelectInEffect,
  SetDataInterface
} from './models/forecaster.model';
import { UserItemParamsOnlyModel, UserParametersObj, UsersScope, UserScopeObj } from './users-management-module/users.model';
import {
  ChangeScenarioData, FinalizeEntityData,
} from './scenarios-module/scenarios.model';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import {NotificationDialogComponent} from './notification-module/notification-dialog/notification-dialog.component';
import {NotificationDialogObject} from './notification-module/notification.model';
import { ScenarioNameDialogObject } from '@app/pages/forecaster/scenarios-module/scenarios.model'
import { ScenarioNameDialogComponent } from '@app/shared/ui-elements/common-dialogs/scenario-name-dialog/scenario-name-dialog.component';

import {
    SEL_GENERAL,
    SEL_SCENARIO_BASE,
    SEL_SCENARIO_CUR,
    SEL_TIMEPERIODM,
    SIMULATOR_PAGE_KEY,
    MULTISELECTOR_HIDDEN_COLUMN_COUNT,
    TBL_ADDSTRS,
    SEL_TIMEPERIOD_START, SEL_TIMEPERIOD_END, ORDER_KEY, _NAME,
    IS_CHB_CHECKED, CHB_YEAR_BY_YEAR, IS_CHB_UNCHECKED, SEL_CURRENCY, STATE_BACK, SEL_SIMULATOR_TAB, LOCAL_CURRENCY, SCENARIOS,

} from '@app/pages/forecaster/models/forecaster.constants';
import {
    StateStructure, TimeDimensionObject
} from '@app/pages/forecaster/models/forecaster.model';
import {
    ReturnInterface,
} from '@app/_components/model/common.interfaces';
import {
    ChangeAllReqSelector, ChangeArraySelector,
    // ChangeMultiSelector,
    ChangeTimeScale,
    // SaveAsScenario, SaveScenario,
    ShowSpinner, ChangeScenario, RefreshTable, UpdateForecastPage, ClearState, InitState, ChangeScenarioAfterActualization,
  } from '@app/pages/forecaster/state/foracaster.action';
import { ScenariosService } from '@app/pages/forecaster/scenarios.service';
import { environment } from '../../../environments/environment';

import { SessionStorageService } from 'angular-web-storage';
import { ComboBoxItem } from '@app/_components/model/common.interfaces';
import { BehaviorSubject, concat, forkJoin, from, Observable, of, Subject } from 'rxjs';
import { SpinnerOverlayService } from '@app/shared/services/spinner-overlay.service';
import { concatMap, switchMap, toArray } from 'rxjs/operators';
import { getScenarioInfo } from './simulator/simulator.actions';

import { LargeScaleSimulationComponent } from './simulator/dialogs/large-scale-simulation/large-scale-simulation.component'
import { MainReportComponent } from './simulator/dialogs/main-report/main-report.component';
import { ScopeSelectorComponent } from './simulator/dialogs/scope-selector/scope-selector.component';

import { ActualizationUpdateDialogComponent } from '@app/pages/forecaster/simulator/actualization/actualization-update-dialog/actualization-update-dialog.component';
import { ActualizationNewDialogComponent } from '@app/pages/forecaster/simulator/actualization/actualization-new-dialog/actualization-new-dialog.component';
import { ActualizationSetupDialogComponent } from './simulator/actualization/actualization-setup-dialog/actualization-setup-dialog.component';
import { commonConstants } from '@app/consts/common';
import { ForecasterActions } from './state/action-types';
import { ExportDataComponent } from './simulator/dialogs/export-data/export-data.component';

const GET_NAME_DIMENSION = 'get_name_dimension';
const GET_TIME_DIMENSION = 'get_time_dimension';
const GET_SELECTORS = 'get_selectors';
const GET_ATTRIBUTES = 'get_attributes';
const GET_STATE = 'get_state';
const GET_STATE_MULTI_SELECTOR = 'get_state_multi_selector';

const GET_DATA = 'get_data';
const GET_DECOMPOSITION = 'get_decomposition';
const GET_WATERFALL = 'get_waterfall';
const GET_CAGR = 'get_cagr';
const GET_TOTAL = 'get_total';
const GET_SUBTOTAL = 'get_subtotal';
const GET_FILTER = 'get_filter';

const GET_DATA_NOVA = 'get_data_from_db';

const SET_SELECTOR = 'set_selector';
const SET_MULTI_SELECTOR = 'set_state_multi_selector';
const SET_PAGE = 'set_page';
const SET_DATA = 'set_data';
// const SET_MAIN_SELECTOR = 'set_main_selector';
// const SET_ARRAY_SELECTOR = 'set_array_selector';

const UPDATE_DATA = 'update_data';
const RESET_DATA = 'reset_data';
const UPLOAD_TO_DB = 'upload_to_db';

const GENERATE_INDIVIDUAL_REPORT = 'generate_individual_report';
const GENERATE_USERS_ACTIVITY_REPORT = 'generate_user_activity_report';

const GET_USERS = 'get_users';
const CREATE_USER = 'create_user';
const DELETE_USER = 'delete_user';
// const UPDATE_USERS_SCOPE = 'update_users_scope';
const CHANGE_USERS_SCOPE = 'update_users_scope'; // 'change_users_scope';
const UPDATE_USER_PARAMS = 'update_user_params';
const GET_USER_SCOPE = 'get_user_scope';

const GET_SCENARIOS = 'get_scenarios';
const GET_SCENARIO_INFO = 'get_scenario_info';
const GET_CONSOLIDATED = 'get_consolidated';
// const UPDATE_SCENARIO = 'update_scenario';
const CHANGE_SCENARIO = 'change_scenario';

const UPLOAD_ZIP = 'upload_zip';
const FINISH_UPLOAD_ZIP = 'finish_upload_zip';
const SEND_UPLOAD_ZIP_MAIL = 'send_upload_zip_mail';
const GET_HELPFILE = 'get_helpfile';
const GET_LOGFILE = 'get_logfile';
const GET_UPLOADTEMLATEFILE = 'get_uploadtemlatefile';
const GET_UPLOADGUIDEFILE = 'get_uploadguidefile';
const UPLOAD_SCENARIO = 'upload_scenario';
const UPLOAD_SCENARIO_FINISH_PART1 = 'finish_scenario_upload_part1';
const UPLOAD_SCENARIO_FINISH_PART2 = 'finish_scenario_upload_part2';

const GET_CHART_BY_YEARS = 'get_chart_by_years';
const GET_CHART_DATA = 'get_data_for_chart';

const apiUrl = environment.apiUrl + environment.client + '/';
const GET_REPORT = 'get_report';

const START_ACTUALIZING_SCENARIO = 'start_actualizing_scenario ';
const FINISH_ACTUALIZING_SCENARIO = 'finish_actualizing_scenario';
const FINALIZE_ENTITY = 'finalize_entity';

@Injectable({
  providedIn: 'root'
})
export class ForecasterService {
  private subjectRefreshTable = new Subject<any>(); //need to create a subject

  discardStatus = new BehaviorSubject<boolean>(false);
  discardFilters = new Subject<boolean>();

  constructor(
    private store: Store<AppState>,
    private http: HttpClient,
    private scenarioService: ScenariosService,
    private sessionStorage: SessionStorageService,
    private spinnerService: SpinnerOverlayService,
    private dialog: MatDialog,
    ) {}

  selSubPeriod: string;
  selSubPeriod2: string;
  action: boolean;
  nameDimension: Object;
  timeDimension: Object;
  selectors: Object;
  attributes: Object;
  scenarios: Object;
  hide: boolean;

  getNameDimension() {
    return this.http.post(`${apiUrl}` + GET_NAME_DIMENSION, {});
  }

  getTimeDimension() {
    return this.http.post(`${apiUrl}` + GET_TIME_DIMENSION, {});
  }

  getSelectors() {
    return this.http.post(`${apiUrl}` + GET_SELECTORS, {});
  }

  getAttributes() {
    return this.http.post(`${apiUrl}` + GET_ATTRIBUTES, {});
  }

  getState() {
    return this.http.post(`${apiUrl}` + GET_STATE, {});
  }

  getStateMultiSelector() {
    return this.http.post(`${apiUrl}` + GET_STATE_MULTI_SELECTOR, {});
  }


  getFactData(data: GetFactDataInterface) {
    return this.http.post(`${apiUrl}` + GET_DATA, { 'set_data': data });
  }


  getDecomposition() {
    return this.http.post(`${apiUrl}` + GET_DECOMPOSITION, {});
  }

  getWaterfall() {
    return this.http.post(`${apiUrl}` + GET_WATERFALL, {});
  }

  getCagr() {
    return this.http.post(`${apiUrl}` + GET_CAGR, {});
  }

  getTotal() {
    return this.http.post(`${apiUrl}` + GET_TOTAL, {});
  }

  getSubtotal() {
    return this.http.post(`${apiUrl}` + GET_SUBTOTAL, {});
  }

  generateIndividualReport() {
    return this.http.post(`${apiUrl}` + GENERATE_INDIVIDUAL_REPORT, {}, { headers: new HttpHeaders(
      {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
      }
    ), 
    observe: 'response',
    responseType: 'blob' as 'json'});
  }

  generateUsersActivityReport() {
    return this.http.post(`${apiUrl}` + GENERATE_USERS_ACTIVITY_REPORT, {}, { headers: new HttpHeaders(
      {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
      }
    ), 
    observe: 'response',
    responseType: 'blob' as 'json'});
  }

  setSelector(data: SetDataForSelectInEffect | SetArrayForSelectInEffect) {
    if (!data.new_value) {
      data.new_value = '';
    }
    return this.http.post(`${apiUrl}` + SET_SELECTOR, { 'set_data': data });
  }

  setMultiSelector(data: MultiSelectorSetter) {
    return this.http.post(`${apiUrl}` + SET_MULTI_SELECTOR, { 'set_data': data });
  }

  setPage(data: string) {
    return this.http.post(`${apiUrl}` + SET_PAGE, { set_data: data });
  }

  setTheData(data: SetDataInterface) {
    return this.http.post(`${apiUrl}` + SET_DATA, { set_data: data });
  }

  // set_selector for all selector with array_data
  setArraySelector(data: SetArrayForSelectInEffect) { // TODO remove
    if (!data.new_value) {
      data.new_value = [''];
    }
    return this.http.post(`${apiUrl}` + SET_SELECTOR, { 'set_data': data });
  }

  // set_main_selector
  setMainSelector(data: SetArrayForSelectInEffect) { // TODO remove
    if (!data.new_value) {
      data.new_value = [''];
    }
    return this.http.post(`${apiUrl}` + SET_SELECTOR, { 'set_data': data });
  }


  updateTheData(data) {
    return this.http.post(`${apiUrl}` + UPDATE_DATA, {set_data: data});
  }

  checkCounterUpdateData(data) {
    return this.http.post(`${apiUrl}` + 'get_update_enum', {set_data: data});
  }

  resetTheData() {
    return this.http.post(`${apiUrl}` + RESET_DATA, {});
  }

  getUsers() {
    return this.http.post(`${apiUrl}` + GET_USERS, {});
  }


  createUser(data) {
    return this.http.post(`${apiUrl}` + CREATE_USER, { set_data: data });
  }


  deleteUser(data: string) {
    return this.http.post(`${apiUrl}` + DELETE_USER, { set_data: data });
  }

  changeUsersScope(data: UserScopeObj[]) {
    return this.http.post(`${apiUrl}` + CHANGE_USERS_SCOPE, { set_data: data });
  }

  updateUserParams(data: UserParametersObj) {
    return this.http.post(`${apiUrl}` + UPDATE_USER_PARAMS, { set_data: data });
  }

  finalizeEntity(set_data: FinalizeEntityData) {
    return this.http.post(`${apiUrl}` + FINALIZE_ENTITY, { set_data });
  }

  getScenarios(scenarios_list_type: string) {
    return this.http.post(`${apiUrl}` + GET_SCENARIOS, { set_data: scenarios_list_type });
  }

  getScenarioInfo(scenario_id: string) {
    return this.http.post(`${apiUrl}` + GET_SCENARIO_INFO, { set_data: scenario_id });
  }

  getConsolidated() {
    return this.http.post(`${apiUrl}` + GET_CONSOLIDATED, {});
  }

  changeScenario(data: ChangeScenarioData) {
    return this.http.post(`${apiUrl}` + CHANGE_SCENARIO, { set_data: data });
  }

  uploadZIP(data, is_scenario : boolean) {

    if (is_scenario)
      return this.http.post(`${apiUrl}` + UPLOAD_SCENARIO, data);
    return this.http.post(`${apiUrl}` + UPLOAD_ZIP, data);
  }

  finishUploadZIP(is_success: string) {
    return this.http.post(`${apiUrl}` + FINISH_UPLOAD_ZIP, { 'success': is_success });
  }

  sendUploadZIPMail() {
    return this.http.post(`${apiUrl}` + SEND_UPLOAD_ZIP_MAIL, {});
  }

  finishScenarioUploadPart1() {
    return this.http.post(`${apiUrl}` + UPLOAD_SCENARIO_FINISH_PART1, {});
  }

  finishScenarioUploadPart2() {
    return this.http.post(`${apiUrl}` + UPLOAD_SCENARIO_FINISH_PART2, {});
  }

  getLogFile(): any {
    return this.http.post(`${apiUrl}` + GET_LOGFILE, {}, { headers: new HttpHeaders(
      {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
      }
    ), 
    observe: 'response',
    responseType: 'blob' as 'json'});
  }

  getHelpFile(): any {
    return this.http.post(`${apiUrl}` + GET_HELPFILE, {}, { headers: new HttpHeaders(
      {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
      }
    ), 
    observe: 'response',
    responseType: 'blob' as 'json'});
  }

  getUploadTemplateFile() {
    return this.http.post(`${apiUrl}` + GET_UPLOADTEMLATEFILE, {}, { headers: new HttpHeaders(
      {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
      }
    ), 
    observe: 'response',
    responseType: 'blob' as 'json'});
  }

  getUploadGuideFile() {
    return this.http.post(`${apiUrl}` + GET_UPLOADGUIDEFILE, {}, { headers: new HttpHeaders(
      {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
      }
    ), 
    observe: 'response',
    responseType: 'blob' as 'json'});
  }

  getChartByYears() {
    return this.http.post(`${apiUrl}` + GET_CHART_BY_YEARS, {});
  }


  getAllDataNova(action: string = 'update') {
    return this.http.post(`${apiUrl}` + GET_DATA, {'action': action});
  }

  getFilters(attrs) {
    return this.http.post(`${apiUrl}` + GET_FILTER, attrs);
  }

  getStartActualizingScenario(scenario_id: string) {
    return this.http.post(`${apiUrl}` + START_ACTUALIZING_SCENARIO, {scenario_id: scenario_id});
  }

  getFinishActualizingScenario(scenario_id: string) {
    return this.http.post(`${apiUrl}` + FINISH_ACTUALIZING_SCENARIO, {scenario_id: scenario_id});
  }

  isScenarioDisableForSave(scenario_id): boolean {
    return this.scenarioService.isScenarioDisableForSave(scenario_id);
  }

  isScenarioDisableForSaveAs(scenario_id): boolean {
    return this.scenarioService.isScenarioDisableForSaveAs(scenario_id);
  }

  isScenarioDisableForLargeScale(scenario_id): boolean {
    return this.scenarioService.isScenarioDisableForLargeScale(scenario_id);
  }

  isScenarioDisableForActualization(scenario_id): boolean {
    return this.scenarioService.isScenarioDisableForActualization(scenario_id);
  }

  isModelDisabledForRun(scenario_id): boolean {
    return this.scenarioService.isScenarioUpdated(scenario_id);
  }

  runModelForActualization(is_init: boolean = false) {
    let s_init = is_init ? 'Yes' : 'No';
    this.spinnerService.show('0%');
    return this.checkCounterUpdateData({'init':s_init})
      .pipe(
        concatMap((count: number) => {
          const arrayReq = [];
          for (let i = 0; i < count; i++) {
            arrayReq.push(
              from(
                this.updateTheData({'init':s_init}).pipe(
                  switchMap(() => {
                    this.spinnerService.updateMessage(Math.round(((i + 1)/count) * 100) + '%');
                    return of({});
                  })
                )
              )
            );
        }
        return concat(...arrayReq).pipe(toArray());
      }));
  }

  runModel(is_init: boolean = false) {
    let s_init = is_init ? 'Yes' : 'No';
    this.spinnerService.show('0%');
    this.checkCounterUpdateData({'init':s_init})
      .pipe(
        concatMap((count: number) => {
          const arrayReq = [];
          for (let i = 0; i < count; i++) {
            arrayReq.push(
              from(
                this.updateTheData({'init':s_init}).pipe(
                  switchMap(() => {
                    this.spinnerService.updateMessage(Math.round(((i + 1)/count) * 100) + '%');
                    return of({});
                  })
                )
              )
            );
        }

        return concat(...arrayReq).pipe(toArray());

      }))
      .toPromise()
      .then(() => {
        this.spinnerService.hide();
        this.store.dispatch(RefreshTable());
        },
        (error) => {
          this.spinnerService.hide();
          const data = {
            errorMsg: JSON.stringify(error),
          };
          //this.dialog.open(ErrorDialogComponent, {data: data,});
        }
      );
  }

  runModelOnScenarioLoad(scenarioName) {
    if (this.spinnerService.check()) {
      this.spinnerService.updateMessage('0%');
    }
    else {
      this.spinnerService.show('0%');
    }
    this.finishScenarioUploadPart1().pipe(
        concatMap(() => {
          return from(this.checkCounterUpdateData({'init':'No'}));
        }),
        concatMap((count: number) => {
          const arrayReq = [];
          for (let i = 0; i < count; i++) {
            arrayReq.push(
              from(
                this.updateTheData({'init':'No'}).pipe(
                  switchMap(() => {
                    this.spinnerService.updateMessage(Math.round(((i + 1)/count) * 100) + '%');
                    return of({});
                  })
                )
              )
            );
        }
        arrayReq.push(this.finishScenarioUploadPart2());
        return concat(...arrayReq).pipe(toArray());
      }))
      .toPromise()
      .then(() => {
        this.getScenarios('').subscribe(
          (res) => {
            this.sessionStorage.set(SCENARIOS, res);
            let scenarioId = '';
            for (let key in res) {
              if (res[key].name === scenarioName) {
                scenarioId = key;
              }
            }
            let state = this.sessionStorage.get(STATE_BACK);
            state[SIMULATOR_PAGE_KEY][SEL_GENERAL][SEL_SCENARIO_CUR] = scenarioId;
            this.sessionStorage.set(STATE_BACK, state);
            this.store.dispatch(getScenarioInfo({ payload: res}))
            const set_data: SetDataForSelectInEffect = {
              page: SIMULATOR_PAGE_KEY,
              component: SEL_GENERAL,
              sub_component: SEL_SCENARIO_CUR,
              new_value: scenarioId,
            };
            this.store.dispatch(ChangeAllReqSelector({ payload: set_data }));
            this.spinnerService.hide();
            const data_for_dialog: NotificationDialogObject = {
              type: 'result',
             message: 'Scenarios were uploaded successfully',
            };
            const dialogRef = this.dialog.open(NotificationDialogComponent, {
              data: data_for_dialog,
            });
          }
        )

      },
      (error) => {
        this.spinnerService.hide();
        const data = {
          errorMsg: JSON.stringify(error),
        };
        //this.dialog.open(ErrorDialogComponent, {data: data,});
      });
  }

  onInitialSaveAs(old_scenario_id, new_scenario_id, link?) {
    let self = this;
    const data: NotificationDialogObject = {
      type: 'confirmation',
      message: 'There were changes in Initial scenario. Do you want to save it to another scenario?',
    };
    const dialogRef = self.dialog.open(NotificationDialogComponent, {
      data: data,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === undefined) {
        self.onGeneralSelectorChanged(SEL_GENERAL, { 'item_id': SEL_SCENARIO_CUR, 'new_value': new_scenario_id });
      }
      else {
        self.onSaveAs(old_scenario_id, '', true, new_scenario_id, link);
      }
    });

  }

  onGeneralSelectorChanged(block_id: string, input_data: ReturnInterface) {
    const state: StateStructure = this.sessionStorage.get(STATE_BACK);
    const old_value = state[SIMULATOR_PAGE_KEY][SEL_GENERAL][input_data.item_id];
    const new_value = input_data.new_value;

    if (old_value === new_value) {
      return;
    }

    //this.store.dispatch(ShowSpinner());

    const set_data: SetDataForSelectInEffect = {
      page: SIMULATOR_PAGE_KEY,
      component: SEL_GENERAL,
      sub_component: input_data.item_id,
      new_value: input_data.new_value,
    };

    // Tab specific logic
    //this.timePeriodItemsUpdate();

    switch (input_data.item_id) {
      case (SEL_TIMEPERIODM):
        return this.store.dispatch(ChangeTimeScale({ payload: set_data }));
      // case (SEL_SETYEARS):
      //   return this.store.dispatch(ChangeAllReqSelector({ payload: set_data }));
      case (SEL_SCENARIO_BASE):
        return this.store.dispatch(ChangeAllReqSelector({ payload: set_data }));
      case (SEL_SCENARIO_CUR):
        return this.store.dispatch(ChangeAllReqSelector({ payload: set_data }));
      case (SEL_TIMEPERIOD_START):
        return this.store.dispatch(ChangeAllReqSelector({ payload: set_data }));
      case (SEL_TIMEPERIOD_END):
        return this.store.dispatch(ChangeAllReqSelector({ payload: set_data }));
      case (SEL_CURRENCY):
        return this.store.dispatch(ChangeAllReqSelector({ payload: set_data }));
      case (SEL_SIMULATOR_TAB):
        return this.store.dispatch(ChangeAllReqSelector({ payload: set_data }));
    }
  }

  onSave(scenario_id: string, scenario_name: string) {
    // TODO use scenarios from session storage here
    const self = this;
    //const cur_scenario_name: string = all_scenarios.filter(scenario => scenario.key === scenario_id)[0].label;
    const data: NotificationDialogObject = {
      type: 'confirmation',
      message: 'Do you want to save scenario \'' + scenario_name.trim() + '\'?',
    };
    const dialogRef = self.dialog.open(NotificationDialogComponent, {
      data: data,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        self.scenarioService.dispatchScenario(scenario_id, scenario_name, 'save');
        return;
      }
    });
    return;
  }


  onSaveAs(scenario_id: string, scenario_name: string = '', from_initial: boolean = false, new_scenario_id = "", link?) {
    const self = this;
    const data: ScenarioNameDialogObject = {
      scenario_name: scenario_name,
      title: 'Save As',
      blocked_names: self.scenarioService.getBlockedScenarioNames(),
    };
    const dialogRef = self.dialog.open(ScenarioNameDialogComponent, {
      data: data,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const new_name = result.name.trim();
        if (!self.scenarioService.scenarioNameExists(new_name)) {
          self.scenarioService.dispatchScenario(scenario_id, new_name, 'saveas', link);
          return;
        }
        self.onExists(scenario_id, new_name, from_initial, new_scenario_id, link);
      }
      else {
        if (from_initial && !self.scenarioService.isScenarioSaved(scenario_id)) {
          self.onInitialSaveAs(scenario_id, new_scenario_id, link);
        }
      }
    });
    return;
  }

  onActualization(scenario_id: string) {
    this.spinnerService.show();
    this.getStartActualizingScenario(scenario_id).pipe(
      concatMap(() => forkJoin([this.runModelForActualization()])),
      concatMap(() => forkJoin([this.getFinishActualizingScenario(scenario_id)])),
      // concatMap(() => [ForecasterActions.ClearState()]),
      // concatMap(() => [ForecasterActions.InitState({payload: true})]),
    ).subscribe(
      (res: any[]) => {
        const resalt = JSON.parse(res[0]);
        if (resalt['message'] !== '') {
          const data: NotificationDialogObject = {
            type: 'information',
            message: resalt['message'],
          };
          this.dialog.open(NotificationDialogComponent, {
            data: data,
          });
        } 
        // this.store.dispatch(ClearState());
        this.store.dispatch(ChangeScenarioAfterActualization({ payload: scenario_id }));
        // this.store.dispatch(UpdateForecastPage());
      },
      () => this.spinnerService.hide());
  }

  largeScaleSimulation(time_scale, sel_time_period_start_info, sel_time_period_end_info): MatDialogRef<LargeScaleSimulationComponent> {
    const self = this;
    const data = {
      'timeScale': time_scale,
      'timePeriodStart': sel_time_period_start_info,
      'timePeriodEnd': sel_time_period_end_info,
    };

    const dialogRef = self.dialog.open(LargeScaleSimulationComponent, {data: data,});

    return dialogRef;
  }


  mainReport(): MatDialogRef<MainReportComponent> {
    const self = this;
    const data = {
    };

    return self.dialog.open(MainReportComponent, {data: data,});
  }

  scopeSelector(page): MatDialogRef<ScopeSelectorComponent> {
    const self = this;
    const data = {
      page: page
    };

    return self.dialog.open(ScopeSelectorComponent, {data: data,});
  }

  exportData(): MatDialogRef<ExportDataComponent> {
    const self = this;
    const data = {
    };

    return self.dialog.open(ExportDataComponent, {data: data,});
  }

  uploadToDB(body) {
    return this.http.post(`${apiUrl}` + UPLOAD_TO_DB, body);
  }

  onExists(scenario_id: string, new_name: string, from_initial: boolean = false, new_scenario_id = "", link?) {
    const self = this;
    const data: NotificationDialogObject = {
      type: 'confirmation',
      message: 'Scenario \'' + new_name + '\' already exists. Do you want to replace it?',
    };
    const dialogRef = self.dialog.open(NotificationDialogComponent, {
      data: data,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        self.scenarioService.dispatchScenario(scenario_id, new_name, 'saveas', link);
        return;
      } else {
        self.onSaveAs(scenario_id, new_name, from_initial, new_scenario_id, link);
      }
    });
    return;
  }

  getSelSubPeriod() {
    return this.selSubPeriod;
  }

  setSelSubPeriod(val) {
    this.selSubPeriod = val;
  }

  getSelSubPeriod2() {
    return this.selSubPeriod2;
  }

  setSelSubPeriod2(val) {
    this.selSubPeriod2 = val;
  }

  getChartData() {
    return this.http.post(`${apiUrl}` + GET_CHART_DATA, {});
  }

  sendRefreshTableEvent(showTotals: string, showSubTotal: string) {
    this.subjectRefreshTable.next({
      totals: showTotals,
      subTotals: showSubTotal,
    });
  }

  getRefreshTableEvent(): Observable<any> {
    return this.subjectRefreshTable.asObservable();
  }

  getReport(data): Observable<any> {
    return this.http.post(`${apiUrl}` + GET_REPORT, data, { headers: new HttpHeaders(
      {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
      }
    ), 
    observe: 'response',
    responseType: 'blob' as 'json'});
  }

  getActualizationUpdateDialog(action: String): MatDialogRef<ActualizationUpdateDialogComponent> {
    const self = this;
    const data = {
      action: action,
    };

    return self.dialog.open(ActualizationUpdateDialogComponent, {data: data,});
  }

  getActualizationNewDialog(action: String): MatDialogRef<ActualizationNewDialogComponent> {
    const self = this;
    const data = {
      action: action,
    };

    return self.dialog.open(ActualizationNewDialogComponent, {data: data,});
  }

  getActualizationSetupDialog(action: String): MatDialogRef<ActualizationSetupDialogComponent> {
    const self = this;
    const data = {
      action: action,
    };

    return self.dialog.open(ActualizationSetupDialogComponent, {data: data,});
  }

  downloadFileFromBackEnd(file: HttpResponse<Blob>) {
    const headerProperty = file.headers.get('content-disposition')
      .split(';')
      .map(h => h.trim())
      .filter(h => h.startsWith('filename='))
      .reduce(h => h.length === 1 ? h[0] : "NoName")
      .replace('filename=', '');;
    FileSaver.saveAs(file.body, headerProperty);
  }

  getScopeStr() {
    return this.http.post(`${apiUrl}` + GET_USER_SCOPE, {});
  }

}

