import { Injectable } from '@angular/core';
import { SessionStorageService } from 'angular-web-storage';
import {
  _NAME,
  ANY_KEY,
  ATTRIBUTES,
  NAME_DIMENSION_CONST,
  SELECTORS, SELECTORS_ORDER, SEL_TIMEPERIODM, SEL_SIMULATOR_TAB,
  ORDER_KEY,
  TIME_DIMENSION, STATE_BACK, SIMULATOR_PAGE_KEY, TIME_HIERARCHY, MANAGE_SCOPE_KEY, TBL_FORECAST, LOCAL_CURRENCY,
} from './models/forecaster.constants';
import {
  AttributesObject,
  NameDimensionObject,
  SelectorObject,
  TimeDimensionObject,
  TimeItemDetails
} from './models/forecaster.model';
import {
  ComboBoxInformation,
  ComboBoxItem,
  SimpleSelectDataInterface,
  SimpleSelectDataItemInterface
} from '../../_components/model/common.interfaces';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageProviderService {

  constructor(private sessionStorage: SessionStorageService) {
  }

  //  Attributes
  getAttributesTable(table_key: string, timescale_key = ANY_KEY): AttributesObject {
    return this.sessionStorage.get(ATTRIBUTES)[table_key];
  }

  getTableOrder(table_key: string, timescale_key = ANY_KEY): string[] {
    return this.sessionStorage.get(ATTRIBUTES)[table_key][timescale_key + ORDER_KEY];
  }

  getTableDetails(table_key: string, timescale_key = ANY_KEY): object {
    return this.sessionStorage.get(ATTRIBUTES)[table_key][timescale_key];
  }

  getTableOrderByAttributesObject(attributes: AttributesObject, timescale_key = ANY_KEY): string[] {
    return attributes[timescale_key + ORDER_KEY];
  }

  getTableDetailsByAttributesObject(attributes: AttributesObject, timescale_key = ANY_KEY): object {
    return attributes[timescale_key];
  }

  orderFilteredByYesParameters(order: string[], attributes: AttributesObject, ...parameters: string[]): string[] {
    console.log('order', order);
    console.log('attributes', attributes);
    console.log('parameters', parameters);

    return order.filter(item => parameters.every(param => attributes[item][param] === 'Yes'));
  }

  orderMappedByParameter(order: string[], attributes: AttributesObject, parameter: string): string[] {
    return order.map(item => attributes[item][parameter]);
  }

  //  Time dimension

  getTimeDimension(): TimeDimensionObject {
    return this.sessionStorage.get(TIME_DIMENSION);
  }

  getCroppedTimeLineOrder(_start: string, _end: string, time_line_key: string): string[] {
    const order: string[] = this.getTimeDimension()[time_line_key + ORDER_KEY];
    const start_index = order.indexOf(_start);
    const end_index = order.indexOf(_end);
    return order.slice(start_index, end_index + 1);
  }

  getCroppedTimeItems(_start: string, _end: string, time_line_key: string): TimeItemDetails[] {
    const td: TimeDimensionObject = this.getTimeDimension();
    const order: string[] = this.getCroppedTimeLineOrder(_start, _end, time_line_key);
    return order.map((time_point_id: string): TimeItemDetails => td[time_line_key][time_point_id]);
  }

  getNewTimePoint(new_ts: string, old_ts: string, old_tp: string, for_start: boolean): string { // ts - time scale, tp - time point
    let new_tp = '';
    const time_dim: TimeDimensionObject = this.getTimeDimension();
    const time_dim_keys: string[] = Object.keys(time_dim);
    const ts_hierarchy: string[] = TIME_HIERARCHY.filter(item => time_dim_keys.indexOf(item) >= 0);
    const new_ts_index = ts_hierarchy.indexOf(new_ts);
    const old_ts_index = ts_hierarchy.indexOf(old_ts);

    if (new_ts_index > old_ts_index) { // from annual to quarterly
      const new_ts_order: string[] = time_dim[new_ts + ORDER_KEY];
      if (for_start) {
        for (let i = 0; i < new_ts_order.length; i++) {
          if (new_ts_order[i].includes(old_tp)) {
            return new_ts_order[i];
          }
        }
        return new_ts_order[0];
      } else {
        for (let i = new_ts_order.length - 1; i > -1; i--) {
          if (new_ts_order[i].includes(old_tp)) {
            return new_ts_order[i];
          }
        }
        return new_ts_order[new_ts_order.length - 1];
      }
    } else if (new_ts_index < old_ts_index) { // from quarterly to annual
      new_tp = old_tp.split('_', new_ts_index + 1).join('_');
      return new_tp;
    } else { // from annual to annual
      return old_tp;
    }
    return new_tp;
  }


  //  Name dimension

  getNameDimension(): NameDimensionObject {
    return this.sessionStorage.get(NAME_DIMENSION_CONST);
  }

  getNameLabels(): string[] {
    return this.getNameDimension().name_labels;
  }

  getNameTables(): string[][] {
    return this.getNameDimension().name_tables;
  }

  getNameDimensionAttributes(): object {
    return this.getNameDimension().attributes;
  }

  //  Selectors

  getSelectorById(selector_id: string): SelectorObject { //   TODO add interface for selector
    return this.sessionStorage.get(SELECTORS)[selector_id];
  }

  getSelectorObjectById(selector_id: string): SimpleSelectDataInterface {
    const selector: SelectorObject = this.getSelectorById(selector_id);
    return { // ButtonGroupDataInterface | SimpleSelectDataInterface
      data: selector.order.map((item): SimpleSelectDataItemInterface => {
        return {
          label: selector[item][_NAME],
          key: item,
          is_disabled: selector[item]['is_disabled']
        };
      }),
      label: selector.label,
    };
  }

  getComboBoxItems(selector_id: string): ComboBoxItem[] {
    const selector: SelectorObject = this.getSelectorById(selector_id);
    if (!selector) {
      return undefined;
    }
    return selector.order.map((item: string): ComboBoxItem => {
      return {
        key: item,
        label: selector[item].name,
        is_disabled: selector[item]['is_disabled'] && selector[item]['is_disabled'] === 'Yes',
      };
    });
  }

  getTimePeriodComboBoxItems(pageTabKey: string): ComboBoxItem[] {
    const selectorId: string = SEL_TIMEPERIODM;
    const selector: SelectorObject = this.getSelectorById(selectorId);

    if (!selector) {
      return undefined;
    }
    if (pageTabKey == 'decomposition') {
      return selector.order.map((item: string): ComboBoxItem => {
        return {
          key: item,
          label: selector[item].name,
          is_disabled: selector[item]['decomposition_visible'] && selector[item]['decomposition_visible'] === 'No',
        };
      });
    }
    else if (pageTabKey == 'waterfall') {
      return selector.order.map((item: string): ComboBoxItem => {
        return {
          key: item,
          label: selector[item].name,
          is_disabled: selector[item]['waterfall_visible'] && selector[item]['waterfall_visible'] === 'No',
        };
      });
    }
    else {
      return selector.order.map((item: string): ComboBoxItem => {
        return {
          key: item,
          label: selector[item].name,
          is_disabled: selector[item]['forecast_visible'] && selector[item]['forecast_visible'] === 'No',
        };
      });
    }

  }


  getSelectorOrder(selector): string[] {
    return selector[SELECTORS_ORDER];
  }

  getStateForSimulationPage(): Object {
    return this.sessionStorage.get(STATE_BACK)[SIMULATOR_PAGE_KEY];
  }

  getStateForManageScopePage(): Object {
    return this.sessionStorage.get(STATE_BACK)[MANAGE_SCOPE_KEY];
  }

  getFactsAttributes(sel_currency: ComboBoxInformation): object {
    const facts_attributes = { ...this.getTableDetails(TBL_FORECAST) };
    if (sel_currency) {
      const keys: string[] = Object.keys(facts_attributes);
      keys.forEach(id => {
        let metric = facts_attributes[id].metric;
        if (sel_currency.active_key === LOCAL_CURRENCY && metric.includes('USD')) {
          metric = metric.replace('USD', 'LC');
          metric = metric.trim();
          facts_attributes[id].metric = metric;  // .replace("USD", "LC")
        }
        if (sel_currency.active_key !== LOCAL_CURRENCY && metric.includes('LC')) {
          metric = metric.replace('LC', 'USD');
          metric = metric.trim();
          facts_attributes[id].metric = metric;  // .replace("LC", "USD")
        }
      });
    }
    return facts_attributes;
  }
}
