import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SCENARIOS } from '@app/pages/forecaster/models/forecaster.constants';
import { AllScenariosItemModelBack, AllScenariosModelBack } from '@app/pages/forecaster/scenarios-module/scenarios.model';
import { SessionStorageService } from 'angular-web-storage';

@Component({
  selector: 'app-export-data',
  templateUrl: './export-data.component.html',
  styleUrls: ['./export-data.component.less']
})
export class ExportDataComponent implements OnInit {

  title: string = "Export Data";
  form: FormGroup;
  scenarios: AllScenariosModelBack;

  constructor(
    public dialogRef: MatDialogRef<any>,
    private formBuilder: FormBuilder,
    private sessionStorage: SessionStorageService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      typeScenario: ['', [Validators.required]],
      scenario: ['', [Validators.required]]
    });
    this.scenarios = this.sessionStorage.get(SCENARIOS);
  }

  getScenarioList(type: string): AllScenariosItemModelBack[] {
    const scenariosByType = [];
    for (let key in this.scenarios) {
      if (this.scenarios[key].is_consolidated === 'No'){
        scenariosByType.push(this.scenarios[key]);
      }
    }
    for (let key in this.data) {
      if (this.data[key].hasOwnProperty('name'))
        scenariosByType.push(this.data[key]);
    }
    return scenariosByType;
  }

  onNoClick(): void {
    this.dialogRef.close(null);
  }

  setScenarioValue(scenario: AllScenariosItemModelBack, type: string) {
    for (let key in this.scenarios) {
      if(this.scenarios[key].name === scenario.name) {
        return key;
      }
    }
    for (let key in this.data) {
      if (this.data[key].name === scenario.name)
        return key;
    }

  }

  setScenario() {
    this.dialogRef.close(this.form.value);
  }

}
