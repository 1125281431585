<div class="dialog-wrapper">
    <div mat-dialog-title class="flex-container">
        <div class="form-group">
            <label class="radio-inline">
            <input 
                type="radio" 
                name="selected" 
                (click)="selectType($event)" 
                value="create" 
                required
                checked>
                Create New {{title}} Scenario
            </label>
            <label class="radio-inline">
            <input 
                type="radio" 
                name="selected"  
                (click)="selectType($event)" 
                value="edit" 
                required>
                Edit Current {{title}} Scenario
            </label>
       </div>
        <button mat-button class="close-icon" (click)="onDialogButtonCloseClick()">
            <mat-icon>close</mat-icon>
        </button>                
    </div>
    <div mat-dialog-content>
        <form class="new-actualization-form" [formGroup]="actualizationUpdateForm">
            <section class="section">
                <table>
                    <td>
                        <tr>
                            <mat-form-field class="tag-input">
                                <mat-label>Edit Tag For The Current {{title}} Scenario</mat-label>
                                <input matInput #edit_tag maxlength="16" placeholder="Enter Tag (not mandatory)" formControlName="edit_tag">
                            </mat-form-field>
                        </tr>
                        <tr>
                            <mat-form-field class="tag-input" [disabled]="selectedBy === 'edit'">
                                <mat-label>Set Tag For The New {{title}} Scenario</mat-label>
                                <input matInput [disabled]="selectedBy === 'edit'" #set_tag maxlength="16" placeholder="Enter Tag (not mandatory)" formControlName="set_tag">
                            </mat-form-field>
                        </tr>
                        <tr>
                            <div class="buttons-panel">
                                <button mat-button class="input-button" (click)="fileInput.click()">OK</button>
                                <button mat-button class="input-button" (click)="onDialogButtonCloseClick()">CANCEL</button>
                            </div>
                        </tr>
                        <tr>
                            <mat-slide-toggle [disabled]="selectedBy === 'edit'" [color]="'primary'" [checked]="false" formControlName="consolidatedAsActual" style="padding-top: 25px;">
                                Incorporate Finalizations
                            </mat-slide-toggle>
                        </tr>


                        <!-- <tr *ngIf="action == commonConstants.ACTUALIZATION_UPLOAD_ACTUALIZATION_UPDATE">
                            <div class="buttons-panel">
                                <button mat-button class="input-button" (click)="fileInput.click()">OK</button>
                                <button mat-button class="input-button" (click)="fileInput1.click()">SKIP</button>
                                <button mat-button class="input-button" (click)="onDialogButtonCloseClick()">CANCEL</button>
                            </div>
                        </tr> -->
                        <!-- <tr *ngIf="action == commonConstants.ACTUALIZATION_UPLOAD_NEW_ACTUALIZATION || action == commonConstants.ACTUALIZATION_SETUP_ACTUALIZATION">
                            <div class="new-buttons-panel">
                                <button mat-button class="input-button" (click)="onNextClick()" [disabled]="!actualizationUpdateForm.valid">NEXT</button>
                                <button mat-button class="input-button" (click)="onSkipClick()">SKIP</button>
                                <button mat-button class="input-button" (click)="onDialogButtonCloseClick()">CANCEL</button>
                            </div>
                        </tr> -->
                        <!-- <tr *ngIf="action == commonConstants.ACTUALIZATION_UPLOAD_ACTUALIZATION_UPDATE">
                            <mat-slide-toggle [color]="'primary'" [checked]="false" formControlName="consolidatedAsActual" style="padding-top: 25px;">
                                Set Consolidated as Actual
                            </mat-slide-toggle>
                        </tr> -->
                    </td>
                </table>
            </section>
        </form>
    </div>
    <input #fileInput type="file" name="stl" accept=".zip,.csv" style="display:none;" (change)="onOkClick($event)" (click)="$event.target.value=null">
    <!-- <input #fileInput1 type="file" name="stl" accept=".zip,.csv" style="display:none;" (change)="onSkipClickFile($event)" (click)="$event.target.value=null"> -->
</div>

